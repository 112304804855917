import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogNoti(props) {
    const { modal, functionCallBack } = props;

    return (
        <>
            <Dialog
                open={modal.open}
                keepMounted
                onClose={() => functionCallBack(false)}
                fullWidth
                maxWidth='md'
                sx={{
                    position: 'absolute',
                    top: {xs: '0%', md: '-50%'},
                }}  
            >
                <DialogTitle className='dialog-title-zone fontsz-26 upper'>{modal.title}</DialogTitle>
                <DialogContent sx={{ height: "20%", mt: "10px", mb: "10px" }}>
                    <DialogContentText className="fontsz-25">
                        {modal.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                    <Button color="error" sx={{ width: "120px", height: "36px" }} variant="contained" onClick={() => functionCallBack(false)}>Đóng</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}