import { Children, useState, useEffect } from "react";
import { Paper, Skeleton, } from "@mui/material";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MarkerMap from "./MarkerMap";
import LayerMap from "./LayerMap";
import { Marker, Popup } from "react-leaflet";
import sharedService from "../../../../../Services/SharedService";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { BOXGROUP_ID, ID_GROUPS } from "../../../../../Utils/const";
import PlaceIcon from '@mui/icons-material/Place';

const Map = (props) => {
    const { center, isLoading } = props;
    const [ boxRecords, setBoxRecords ] = useState([])
    const MyMap = () => {
        const map = useMap();
        map.flyTo(map.getCenter(), map.getZoom());
        return null;
    }

    function selectBox(e, boxId) {
        let result = props.group.boxs.find(b => b.id === boxId);
        sharedService.listRecordByBox(boxId).then(res => {
            if(res.data && res.data.count > 0) setBoxRecords(res.data.records)
        })
    }
    useEffect(() => {
        const X = document.getElementsByClassName("leaflet-control-zoom-fullscreen")
        if(X && X.length > 0) {
            X[0].innerHTML = "X"
        }
    })

    const  [toggleArr, setToggleArr] = useState(["RA", "PZ", "ICI", "MEI"])
    function ToggleIcon(e) {
        const value = e.target.value
        if(toggleArr.includes(value)) {
            const arr = toggleArr.filter(t => t !== value)
            setToggleArr([...arr])
        }else {
            toggleArr.push(value)
            setToggleArr([...toggleArr])
        }
    }

    return (
        <>
            {/* <Grid container sx={{ height: "0.4vh" }} /> */}
            <Paper
                elevation={8}
                className="Map-Right"
                sx={{
                    boxShadow: 'none',
                    borderLeft: '1.5px solid',
                    borderTop: '1.5px solid',
                    borderBottom: '3px solid',
                    borderRight: 'none',
                    color: '#0000001f',
                    borderRadius: '5px',
                    marginTop: {xs: '20px', lg: '0px'}
                }}
            >
                <div>
                    {!isLoading ?

                        <MapContainer
                            center={center}
                            zoom={14}
                            maxZoom={18}
                            className="Map-Right-Container"
                        >
                            <LayerMap layers="gg" />
                            <MyMap />
                            {ID_GROUPS.DOT === BOXGROUP_ID &&
                                <div className="toggle-icon">
                                    <div style={{ textAlign: 'center', marginTop: '2px' }}><PlaceIcon sx={{ fontSize: 35 }} /></div>
                                    <div className="toggle-icon-menu">
                                        <div className="toggle-icon-menu-item" >
                                            <input type="checkbox" id="RA" name="RA" value="RA" onChange={ToggleIcon} checked={toggleArr.includes("RA")} />
                                            <p for="RA"> Trạm đo mưa</p>
                                        </div>
                                        <div className="toggle-icon-menu-item">
                                            <input type="checkbox" id="PZ" name="PZ" value="PZ" onChange={ToggleIcon} checked={toggleArr.includes("PZ")} />
                                            <p for="PZ"> Trạm đo mực nước ngầm</p>
                                        </div>
                                        <div className="toggle-icon-menu-item">
                                            <input type="checkbox" id="ICI" name="ICI" value="ICI" onChange={ToggleIcon} checked={toggleArr.includes("ICI")} />
                                            <p for="ICI"> Trạm đo dịch động</p>
                                        </div>
                                        <div className="toggle-icon-menu-item">
                                            <input type="checkbox" id="MEI" name="MEI" value="MEI" onChange={ToggleIcon} checked={toggleArr.includes("MEI")} />
                                            <p for="MEI"> Trạm đo nghiêng</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            <TileLayer
                                url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                            />
                            {props.group?.boxs.map((box, index) => {
                                return <MarkerMap toggleMetric={toggleArr} selectBox={selectBox} index={index} box={box} records={boxRecords} />
                            })}
                            <FullscreenControl />
                        </MapContainer> 
                        :
                        <>
                            {Children.toArray([...Array(3)].map((_, i) => {
                                return (
                                    <Skeleton
                                        animation="wave"
                                        className="mb-8 width-percent-93 ml-20"
                                        style={{ height: "12vh" }}
                                    />
                                )
                            }))}
                        </>}
                </div>
            </Paper>
        </>
    )
}

export default Map;