import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, MenuItem, Tooltip, DialogContentText, FormControl, InputLabel, Select } from "@mui/material";
import { Children, useState } from "react";
import { useEffect } from "react";
import sharedService from "../../../Services/SharedService";
import { BOXGROUP_ID, ERRORS } from "../../../Utils/const";
import CustomLoading from "../../Components/Loading/CustomLoading";
import CustomPagination from "../../Components/Pagination/Pagination";
import { ReactComponent as EditIcon } from '../../../Asset/edit-button.svg';
import { ReactComponent as DeleteIcon } from '../../../Asset/delete.svg';
import { ReactComponent as ChangePassword } from '../../../Asset/password.svg';
import { CHECK_EMPTY_STRING } from "../../../Utils/string";
import { checkNumber } from "../../../Utils/number";
import { ResetForm } from "../../../Utils/helper";
import ModalConfirm from "../../Components/ModalConfirm/ModalConfirm";
import CustomizedSnackbars from "../../Components/AlertNoti/AlertNoti";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import authService from "../../../Services/AuthService";
// import DeleteIcon from '@mui/icons-material/Delete';
const User = () => {
    const [role, setRole] = useState('monitor')
    const [listUser, setListUser] = useState([])
    const headers = ["STT", "Họ tên", "Số điện thoại", "Phân quyền", "Tài khoản", "Hành động"]
    const headersMobile = ["Tài khoản", "SĐT", " "]
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(+0);
    const [rowsPerPage, setRowsPerPage] = useState(+15);
    const listRole = [{ label: "Admin", value: "admin" }, { label: "Monitor", value: "monitor" }]
    const [validate, setValidate] = useState({
        full_name: false,
        phone: false,
        username: false,
        role: false,
        groups: false,
        message: {
            full_name: "",
            phone: "",
            username: "",
            role: "",
            groups: "",
        }
    })
    const [reCallList, setReCallList] = useState(false)

    async function HandleUserApi() {
        try {
            if (!authService.getUserInfo()) {
                const res = await authService.getMe()
                const user = res.data?.user
                authService.setUserInfo(user)
            }
            const userId = authService.getUserInfo()?.id
            const currentUser = (await sharedService.getUser(userId)).data
            setRole(currentUser.role)
            const listUser = (await sharedService.getListUser()).data
            if (listUser.length > 0) {
                let arrList = []
                if (currentUser.role === 'admin') {
                    listUser.map(el => {
                        if (el.groups && el.groups.length !== 0) {
                            if (el?.groups.includes(BOXGROUP_ID)) arrList.push(el)
                        }
                        return arrList
                    })
                } else {
                    arrList.push(currentUser)
                }
                setIsLoading(false)
                setListUser(arrList)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setIsLoading(true)
        HandleUserApi()
    }, [reCallList])
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };
    //for modal create update user
    const [openModal, setOpenModal] = useState({ open: false, title: "" })
    const [infoUser, setInfoUser] = useState({
        full_name: "",
        phone: "",
        username: "",
        role: "",
        groups: [],
    })
    const [checkEdit, setCheckEdit] = useState(false);
    const [newPass, setNewPass] = useState({ pass: "", validate: false, message: "" })

    const handleCloseModal = () => {
        funcResetValidate()
        resetData()
        setOpenModal({ open: false, title: "" })
    }
    const [modalNoti, setModalNoti] = useState({
        open: false,
        title: "",
        message: ""
    })
    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInfoUser({ ...infoUser, [name]: value })
    }

    const funcResetValidate = () => {
        const newData = {
            full_name: false,
            phone: false,
            username: false,
            role: false,
            groups: false,
            message: {
                full_name: "",
                phone: "",
                username: "",
                role: "",
                groups: "",
            }
        }
        setValidate(newData)
    }
    const resetData = () => {
        const resetData = ResetForm(infoUser)
        setInfoUser(resetData)
    }
    const handleSubmitCreate = () => {
        if (CHECK_EMPTY_STRING(infoUser.full_name) || CHECK_EMPTY_STRING(infoUser.role) || CHECK_EMPTY_STRING(infoUser.phone) || CHECK_EMPTY_STRING(infoUser.username)) {
            setValidate({
                ...validate,
                full_name: CHECK_EMPTY_STRING(infoUser.full_name),
                phone: CHECK_EMPTY_STRING(infoUser.phone),
                username: CHECK_EMPTY_STRING(infoUser.username),
                role: CHECK_EMPTY_STRING(infoUser.role),
            })
        }
        else {
            const data = { ...infoUser, groups: [BOXGROUP_ID] }
            sharedService.createUser(data).then(
                res => {
                    setReCallList(!reCallList)
                    funcResetValidate()
                    resetData()
                    setOpenModal({ open: false, title: "" })
                    setModalNoti({ open: true, title: "success", message: `Thêm mới tài khoản người dùng thành công!` });
                }
            ).catch(err => {
                if (err.response) {
                    setOpenModal({ open: false, title: "" })
                    resetData()
                    funcResetValidate()
                    const er = err.response.data.error;
                    const errTranslate = ERRORS.USER.find(err => err.message === er);
                    setModalNoti({ open: true, title: "error", message: `Thêm mới người dùng! Lỗi: ${errTranslate.translate}` });
                }
            })
        }
    }
    const validateCreate = (e, option) => {
        if (e.target.name === "full_name") {
            if (option === "focus") {
                setValidate({
                    ...validate,
                    full_name: false
                })
            }
            else {
                setValidate({
                    ...validate,
                    message: {
                        ...validate.message,
                        full_name: CHECK_EMPTY_STRING(infoUser.full_name) ? "Không được bỏ trống trường họ và tên!" : ""
                    },
                    full_name: CHECK_EMPTY_STRING(infoUser.full_name)
                })
            }
        }
        if (e.target.name === "phone") {
            if (option === "focus") {
                setValidate({ ...validate, phone: false })

            }
            else {
                if (CHECK_EMPTY_STRING(infoUser.phone)) {
                    setValidate({
                        ...validate,
                        phone: true,
                        message: {
                            ...validate.message,
                            phone: "Không được bỏ trống số điện thoại!"
                        },
                    })
                }
                else if (checkNumber(infoUser.phone)) {
                    setValidate({
                        ...validate,
                        phone: true,
                        message: {
                            ...validate.message,
                            phone: "Số điện thoại phải là dạng số!"
                        },
                    })
                }
                else {
                    setValidate({
                        ...validate,
                        phone: false,
                        message: {
                            ...validate.message,
                            phone: ""
                        },
                    })
                }

            }
        }
        if (e.target.name === "username") {
            if (option === "focus") {
                setValidate({
                    ...validate,
                    username: false
                })
            }
            else {
                setValidate({
                    ...validate,
                    message: {
                        ...validate.message,
                        username: CHECK_EMPTY_STRING(infoUser.username) ? "Không được bỏ trống trường username!" : ""
                    },
                    username: CHECK_EMPTY_STRING(infoUser.username)
                })
            }
        }
        if (e.target.name === "role") {
            if (option === "focus") {
                setValidate({ ...validate, role: false })

            }
            else {
                setValidate({
                    ...validate,
                    message: {
                        ...validate.message,
                        role: CHECK_EMPTY_STRING(infoUser.role) ? "Vui lòng lựa chọn quyền!" : ""
                    },
                    role: CHECK_EMPTY_STRING(infoUser.role)
                })

            }
        }
        if (e.target.name === "new_pass") {
            if (option === "focus") {
                setNewPass({
                    ...newPass,
                    validate: false,
                    message: ""
                })
            }
            else {
                if (CHECK_EMPTY_STRING(newPass.pass)) {
                    setNewPass({
                        ...newPass,
                        validate: true,
                        message: "Trường mật khẩu mới không được để trống"
                    })
                }
                else if (e.target.value.length < 6) {
                    setNewPass({ ...newPass, validate: true, message: "Vui lòng nhập mật khẩu dài hơn 6 kí tự" })
                }
            }
        }
    }
    const done = () => {
        const resetModal = ResetForm(modalNoti)
        setModalNoti(resetModal)
    }
    // for Edit and delete
    const [idUser, setIdUser] = useState()
    const [modalConfirm, setModalConfirm] = useState({ open: false, title: "", message: "" })
    const [modalChangePass, setModalChangePass] = useState(false)

    const onChangeNewPass = (e) => {
        const valuePass = e.target.value;
        setNewPass({ ...newPass, pass: valuePass })
    }

    const handleSubmitChangePass = () => {
        const params = { id: idUser, password: newPass.pass }
        sharedService.setPasswordUser(params).then(
            res => {
                setModalChangePass(false)
                setNewPass({ pass: "", validate: false, message: "" })
                setModalNoti({ open: true, title: "success", message: `Thay đổi mật khẩu thành công!` });
                setReCallList(!reCallList)

            }
        ).catch(err => {
            if (err.response) {
                setModalChangePass(false)
                setNewPass({ pass: "", validate: false, message: "" })
                const er = err.response.data.error;
                setModalNoti({ open: true, title: "error", message: `Thay đổi mật khẩu thất bại! Lỗi: ${er}` });
            }
        })
    }

    const closeModalChangePass = () => {
        setNewPass({ pass: "", validate: false, message: "" })
        setModalChangePass(false)
    }

    const handleEdit = (id, option) => {
        setIdUser(id);
        if (option === 1) {
            sharedService.getUser(id).then(
                res => {
                    setInfoUser(res.data)
                    setOpenModal({ open: true, title: "Chỉnh sửa thông tin" })
                }
            ).catch(err => console.log(err))
            setCheckEdit(true)
        }
        if (option === 2) {
            setCheckEdit(false)
            setModalConfirm({ open: true, title: "Xoá tài khoản", message: "Bạn chắc chắn muốn xoá tài khoản ?" })
        }
        if (option === 3) {
            setModalChangePass(true)
        }
    }

    const updateUser = () => {
        sharedService.updateUser(infoUser, idUser)
            .then(
                res => {
                    if (res.status === 200) {
                        setOpenModal({ open: false, title: "" })
                        setModalNoti({ open: true, title: "success", message: "Chỉnh sửa thông tin thành công!" })
                        setReCallList(!reCallList)
                    }
                }
            ).catch(err => setModalNoti({ open: true, title: "error", message: `Chỉnh sửa thông tin thất bại! ${err}` }))
    }
    const deleteUser = (option) => {
        if (option) {
            sharedService.deleteUser(idUser).then(
                res => {
                    if (res.status === 200) {
                        setModalConfirm({ open: false, title: "", message: "" })
                        setModalNoti({ open: true, title: "success", message: "Xoá tài khoản thành công!" })
                        setReCallList(!reCallList)
                    }
                }
            ).catch(err => setModalNoti({ open: true, title: "error", message: `Xoá thất bại! ${err}` }))
        }
        else {
            setModalConfirm({ open: false, title: "", message: "" })
        }
    }
    return (
        <>
            <Dialog
                open={modalChangePass}
                keepMounted
                onClose={closeModalChangePass}
                fullWidth
                maxWidth='md'
                sx={{
                    position: 'absolute',
                    top: { xs: '2%', md: '-60%' },
                }}
            >
                <DialogTitle className='dialog-title-zone fontsz-26 upper'>Đổi mật khẩu</DialogTitle>
                <DialogContent sx={{ height: "20%", mt: "10px", mb: "10px" }}>
                    <Grid>
                        Mật khẩu mới*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField
                            error={newPass.validate}
                            onBlur={(e) => validateCreate(e, "blur")}
                            onFocus={(e) => validateCreate(e, "focus")}
                            type="password"
                            name="new_pass"
                            value={newPass.pass}
                            onChange={onChangeNewPass}
                            fullWidth label="Mật khẩu"
                            placeholder='Nhập mật khẩu mới'
                        />
                        {newPass.validate && <p className="color-red">{newPass.message}</p>}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                    <Button disabled={newPass.pass.length < 6} sx={{ width: "120px", height: "36px" }} variant="contained" onClick={handleSubmitChangePass}>Xác nhận</Button>
                    <Button color="error" sx={{ width: "120px", height: "36px" }} variant="contained" onClick={closeModalChangePass}>Huỷ</Button>
                </DialogActions>
            </Dialog>
            <ModalConfirm modal={modalConfirm} functionCallBack={deleteUser} />
            {/* <ModalNoti modal={modalNoti} done={done} /> */}
            <CustomizedSnackbars open={modalNoti.open} message={modalNoti.message} severity={modalNoti.title} handleClose={done} />
            <Paper sx={{ overflow: 'hidden', marginTop: { xs: '20px', md: "0px" } }} elevation={8}>
                <Grid xs={12} sx={{ margin: { xs: '10px' } }} className="end create-button" >
                    {role === 'admin' && <Button variant="contained" onClick={() => setOpenModal({ open: true, title: "Thêm mới người dùng" })} >Thêm mới</Button>}
                </Grid>
                <Grid sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <TableContainer className="table-zone">
                        <Table stickyHeader>
                            <TableHead className='text-bold upper'>
                                <TableRow>
                                    {Children.toArray(headers.map(h => {
                                        return (
                                            <TableCell align="left">
                                                <Typography
                                                    sx={{
                                                        fontWeight: "bold",
                                                        textTransform: 'uppercase',
                                                        fontSize: 14,
                                                        color: "#606060"
                                                    }}
                                                >
                                                    {h}
                                                </Typography>
                                            </TableCell>
                                        )
                                    }))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoading ? listUser.length > 0 ? listUser.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                                    return (
                                        <TableRow
                                            key={user.id}
                                            hover
                                            role="checkbox"
                                        >
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell align="left" style={{ width: "30%" }}>{user.full_name}</TableCell>
                                            <TableCell align="left">{user.phone}</TableCell>
                                            <TableCell align="left">{user.role}</TableCell>
                                            <TableCell align="left">{user.username}</TableCell>
                                            <TableCell align="left">
                                                <Tooltip title="Chỉnh sửa thông tin" onClick={() => handleEdit(user.id, 1)} className="pointer"><EditIcon /></Tooltip> &nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Xóa tài khoản" onClick={() => handleEdit(user.id, 2)} className="pointer"><DeleteIcon /></Tooltip> &nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Thay đổi mật khẩu" onClick={() => handleEdit(user.id, 3)} className="pointer"><ChangePassword /></Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : <h3 className="mt-20">Không có dữ liệu! Hãy thử thêm mới tài khoản!</h3>
                                    : <CustomLoading numbers={6} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <TableContainer className="table-zone">
                        <Table stickyHeader>
                            <TableHead className='text-bold upper'>
                                <TableRow>
                                    {Children.toArray(headersMobile.map((h, index) => {
                                        return (
                                            <TableCell key={index} align="left">
                                                <Typography
                                                    sx={{
                                                        fontWeight: "bold",
                                                        textTransform: 'uppercase',
                                                        fontSize: 14,
                                                        color: "#606060"
                                                    }}
                                                >
                                                    {h}
                                                </Typography>
                                            </TableCell>
                                        )
                                    }))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoading ? listUser.length > 0 ? listUser.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                                    return (
                                        <TableRow
                                            key={user.id}
                                            hover
                                            role="checkbox"
                                        >
                                            <TableCell align="left" style={{ width: "30%" }}>{user.username}</TableCell>
                                            <TableCell align="left">{user.phone}</TableCell>
                                            <TableCell align="left">
                                                {/* <Tooltip title="Chỉnh sửa thông tin" onClick={() => handleEdit(user.id, 1)} className="pointer"><EditIcon /></Tooltip> &nbsp;&nbsp;&nbsp; 
                                                <Tooltip title="Xóa tài khoản" onClick={() => handleEdit(user.id, 2)}  className="pointer"><DeleteIcon /></Tooltip> &nbsp;&nbsp;&nbsp;
                                                <Tooltip  title="Thay đổi mật khẩu"  onClick={() => handleEdit(user.id, 3)} className="pointer"><ChangePassword /></Tooltip> */}
                                                <p><Tooltip title="Chỉnh sửa thông tin" onClick={() => handleEdit(user.id, 1)} className="pointer"><BorderColorIcon /></Tooltip></p>
                                                <p><Tooltip title="Xóa tài khoản" onClick={() => handleEdit(user.id, 2)} className="pointer"><DeleteIcon /></Tooltip></p>
                                                <p><Tooltip title="Thay đổi mật khẩu" onClick={() => handleEdit(user.id, 3)} className="pointer"><ManageAccountsIcon /></Tooltip></p>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : <h3 className="mt-20">Không có dữ liệu! Hãy thử thêm mới tài khoản!</h3>
                                    : <CustomLoading numbers={6} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container sx={{ p: "1%", ml: "1%" }}>
                    <CustomPagination
                        data={listUser}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        parentCallBack={handleChangePage}
                    />
                </Grid>
            </Paper>
            <Dialog
                open={openModal.open}
                onClose={() => setOpenModal({ open: false, title: "" })}
                fullWidth
                maxWidth='md'
                sx={{ position: "absolute", top: { xs: "2%", md: "-10%" } }}
            >
                <DialogTitle className='dialog-title-zone upper'>{openModal.title}</DialogTitle>
                <DialogContent sx={{ mt: "23px", mb: "10px", pl: { xs: "10px", md: "96px" }, pr: { xs: "10px", md: "102px" } }}>
                    <Grid>
                        Họ và tên*:
                    </Grid>
                    <Grid xs={12} sx={{ pt: 2, pb: 2 }}>
                        <TextField
                            error={validate.full_name}
                            onBlur={(e) => validateCreate(e, "blur")}
                            onFocus={(e) => validateCreate(e, "focus")}
                            value={infoUser.full_name || ""}
                            name="full_name"
                            onChange={onChangeInput}
                            fullWidth label="Họ và tên"
                            placeholder='Nhập họ và tên người dùng'
                        />
                        {validate.full_name && <p className="color-red">{validate.message.full_name}</p>}
                    </Grid>
                    <Grid>
                        Số điện thoại*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField
                            error={validate.phone}
                            onBlur={(e) => validateCreate(e, "blur")}
                            onFocus={(e) => validateCreate(e, "focus")}
                            value={infoUser.phone || ""}
                            name="phone"
                            onChange={onChangeInput}
                            fullWidth label="Số điện thoại"
                            placeholder='Nhập số điện thoại'
                        />
                        {validate.phone && <p className="color-red">{validate.message.phone}</p>}
                    </Grid>
                    <Grid>
                        Tài khoản*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField
                            error={validate.username}
                            onBlur={(e) => validateCreate(e, "blur")}
                            onFocus={(e) => validateCreate(e, "focus")}
                            value={infoUser.username || ""}
                            name="username"
                            onChange={onChangeInput}
                            fullWidth label="Tài khoản"
                            placeholder='Nhập tài khoản'
                        />
                        {validate.username && <p className="color-red">{validate.message.username}</p>}
                    </Grid>
                    <Grid>
                        Phân quyền*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField
                            disabled={checkEdit ? true : false}
                            select
                            error={validate.role}
                            onBlur={(e) => validateCreate(e, "blur")}
                            onFocus={(e) => validateCreate(e, "focus")}
                            value={infoUser.role || ""}
                            name="role"
                            onChange={onChangeInput}
                            fullWidth label="Phân quyền"
                            placeholder='Nhập tên khu vực'
                        >
                            {listRole.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validate.role && <p className="color-red">{validate.message.role}</p>}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mb: "55px", pl: { xs: "0px", md: "96px" }, pr: { xs: "0px", md: "103px" } }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ textAlign: "end" }} >
                            <Button className='button-wh' sx={{ background: "#E5E5E5", color: "black" }} variant="contained" onClick={handleCloseModal}>Huỷ</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "start" }}>
                            <Button type="submit" className='button-wh' variant="contained"
                                disabled={[validate.full_name, validate.phone, validate.username, validate.role].includes(true)}
                                onClick={!checkEdit ? handleSubmitCreate : updateUser}
                            >Lưu</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default User;