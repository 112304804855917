import { LOCAL_STORAGE, ID_GROUPS } from "./const"
import Hypothesis_DD from "../App/Pages/Help/Components/Hypothesis/Hypothesis_DD";
import Hypothesis_SL from "../App/Pages/Help/Components/Hypothesis/Hypothesis_SL";
import Hypothesis_CM from "../App/Pages/Help/Components/Hypothesis/Hyphothesis_CM";
import Hypothesis_KG from "../App/Pages/Help/Components/Hypothesis/Hyphothesis_KG";

const DrainParameter = [ID_GROUPS.SL, ID_GROUPS.KG];

const HelpSpin = [ID_GROUPS.SL, ID_GROUPS.DD, ID_GROUPS.KG]

const OneNumberDrain = [ID_GROUPS.SL, ID_GROUPS.KG];

const TwoNumberDrain = [ID_GROUPS.SL, ID_GROUPS.DD];

const ThreeNumberDrain = [ID_GROUPS.SL];

function getGroupId() {
    const id = localStorage.getItem(LOCAL_STORAGE.GROUP_ID)
    if (!id) return ""
    return id
}

export const hasDrainParameter = DrainParameter.includes(getGroupId())
export const hasHelpSpin = HelpSpin.includes(getGroupId())
export const hasOneNumberDrain = OneNumberDrain.includes(getGroupId())
export const hasTwoNumberDrain = TwoNumberDrain.includes(getGroupId())
export const hasThreeNumberDrain = ThreeNumberDrain.includes(getGroupId())

export function ViewHypothesis(props) {
    const group_id = getGroupId()
    switch (group_id) {
        case ID_GROUPS.SL:
            return <Hypothesis_SL {...props} />
        case ID_GROUPS.DD:
            return <Hypothesis_DD {...props} />
        case ID_GROUPS.CM:
            return <Hypothesis_CM {...props} />
        case ID_GROUPS.KG:
            return <Hypothesis_KG {...props} />
        default:
            return <div>Không có</div>
    }
}