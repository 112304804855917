import Pagination from '@mui/material/Pagination';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

const CustomPagination = (props) => {
    const { page, rowsPerPage, data, parentCallBack } = props;

    const handleChangePage = (e, page) => {
        parentCallBack(page);
    }

    return (
        <Grid container>
            <Grid item xs={12} md={7} sx={{display: {xs: 'flex', md: "block"}, justifyContent: {xs: 'center', md: 'left'}}} >
                <Pagination
                    count={data.length !== +0 ? +Math.ceil(data.length / rowsPerPage) : +1}
                    // variant="outlined"
                    shape="rounded"
                    color="primary"
                    variant="text"
                    onChange={(e, page) => handleChangePage(e, page)}
                />
            </Grid>
            <Grid item xs={12} md={5} className="middle">
                <Typography className="fontsz-14 end pr-17">
                    { data.length &&
                        page + 1 !== +Math.ceil(data.length / rowsPerPage) ? 
                            <>Hiển thị { rowsPerPage * page + 1 }-{ rowsPerPage * (page + 1) } / <b>{data.length}</b> kết quả </> : 
                            <>Hiển thị { rowsPerPage * page + 1 }-{ data.length } / <b>{data.length}</b> kết quả </> 
                    }
                </Typography>
            </Grid>
        </Grid>

    )
}

export default CustomPagination;