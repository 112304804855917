import { format } from "date-fns";
import { CalculateCoordination, getUrlImage } from "../../../../Components/Interpolation/lake_coordination";

const ParametersWithPicture = (props) => {
    const { z } = props;

    return (
        <>
            <div className="left">
                <div className="image">
                    <img alt="" src={require("./../../../../../Asset/lake_coordination/" + getUrlImage())} />
                </div>
                <div className="info ptb-20">
                    <div className="content">
                        <p className="value"><span className="label">Mực nước hồ(m): </span> {z}</p>
                    </div>
                    <div className="content">
                        <p className="value"><span className="label">Ngày: </span> {format(Date.now(), "dd/MM/yyyy")}</p>
                    </div>
                    <div className="content">
                        <p className="value"><span className="label">Thuộc vùng: </span> {CalculateCoordination(z)}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParametersWithPicture;