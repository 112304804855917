import { InterpolationSource } from "./data";

const roundMe = (value) => Math.round((value) * 100) / 100;

export function findPositionBeforeAfter(value, arr) {
    let result;
    let pos1;
    let pos2;
    arr.forEach((a, i) => {
        const minus = value - a;
        if (a <= value && (!result || minus < result)) {
            result = minus;
            pos1 = i;
            pos2 = i + 1;
        }
    })
    return [pos1, pos2]
}

export function OneWayInterpolation(value, arr1, arr2) {
    const [x1, x2] = findPositionBeforeAfter(value, arr1);
    const h1 = value - arr1[x1];
    const h2 = arr1[x2] - value;
    const result = (h1 * arr2[x2] + h2 * arr2[x1]) / (h1 + h2);
    return result
}

export function NewCalculateInterpolation(x, y, arrX, arrY, values) {
    if(x <= 0) return 0;
    if(+y < +arrY[0]) return 0;
    if (arrX.includes(x) && arrY.includes(y)) {
        const indexX = arrX.findIndex(a => a === x)
        const indexY = arrY.findIndex(a => a === y)
        const result = values[indexY][indexX];
        return roundMe(result);
    } else if (arrX.includes(x)) {
        const indexX = arrX.findIndex(a => a === x)
        const arr = values.map(v => v[indexX])
        const result = OneWayInterpolation(y, arrY, arr)
        return roundMe(result);
    } else if (arrY.includes(y)) {
        const indexY = arrY.findIndex(a => a === y)
        const arr = values[indexY]
        const result = OneWayInterpolation(x, arrX, arr);
        return roundMe(result);
    }
    const [x1, x2] = findPositionBeforeAfter(x, arrX);
    const [y1, y2] = findPositionBeforeAfter(y, arrY);

    const y_h1 = y - arrY[y1];
    const y_h2 = arrY[y2] - y;

    const z1 = (y_h1 * values[y2][x1] + y_h2 * values[y1][x1]) / (y_h1 + y_h2)
    const z2 = (y_h1 * values[y2][x2] + y_h2 * values[y1][x2]) / (y_h1 + y_h2)

    const x_h1 = x - arrX[x1];
    const x_h2 = arrX[x2] - x;

    const result = (x_h1 * z2 + x_h2 * z1) / (x_h1 + x_h2);

    return roundMe(result);
}

export function CalculateInterpolationReverseFindY(value, x, arrX, arrY, values) {
    const data = InterpolationSource.find(d => d.group_id === 'EHFJZIUT')
    arrY = data.S.z
    arrX = data.S.dr
    values = data.S.value1

    if (values.some(v => v.includes(value))) {
        let pos;
        values.find((v, i) => {
            if(v.includes(value)) pos = i; 
        });
        return arrY[pos]
    }else if(arrX.includes(x)) {
        const indexX = arrX.findIndex(a => a === x)
        const arr = values.map(v => v[indexX]);
        const result = OneWayInterpolation(value, arr, arrY)
        return roundMe(result);
    }

    const [x1, x2] = findPositionBeforeAfter(x, arrX);
    const arrX1 = values.map(v => v[x1]);
    const arrX2 = values.map(v => v[x2]);

    const r1 = OneWayInterpolation(value, arrX1, arrY)
    const r2 = OneWayInterpolation(value, arrX2, arrY)

    const h1 = x - arrX[x1]
    const h2 = arrX[x2] - x
    const result = (h1 * r2 + h2 * r1) / (h1 + h2);

    return roundMe(result);
}
