import { Icon } from "leaflet";
import place from './icon-svg/Box.svg'; 
import ra from './icon-svg/Vector.svg';
import wa from './icon-svg/MucNuoc.svg';
import wp from './icon-svg/DoTham.svg';
import sa from './icon-svg/DoMan.svg';
import dr from './icon-svg/DoMoCong.svg';
import audio from './icon-svg/audio.svg';
import ici from './icon-svg/DoDichDong.svg';
import mei from './icon-svg/DoNghieng.svg';

export const iconPlace = new Icon({
    iconUrl: place,
    iconSize: [30, 40]
});

export const iconWA = new Icon({
    iconUrl: wa,
    iconSize: [30, 40]
});

export const iconWP = new Icon({
    iconUrl: wp,
    iconSize: [20, 30]
});

export const iconRA = new Icon({
    iconUrl: ra,
    iconSize: [20, 30]
});

export const iconSA = new Icon({
    iconUrl: sa,
    iconSize: [20, 30]
});

export const iconDR = new Icon({
    iconUrl: dr,
    iconSize: [20, 30]
});

export const iconAudio = new Icon({
    iconUrl: audio,
    iconSize: [40, 40]
});

export const iconICI = new Icon({
    iconUrl: ici,
    iconSize: [20, 30]
});

export const iconMEI = new Icon({
    iconUrl: mei,
    iconSize: [20, 30]
});