import React, { Children, useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';

import { format } from 'date-fns';
import zoneService from './Shared/ZoneService';
import { ReactComponent as EditIcon } from '../../../Asset/edit-button.svg';
import { ReactComponent as DeleteIcon } from '../../../Asset/delete.svg';

import { CHECK_EMPTY_STRING } from '../../../Utils/string';
import { checkNumber } from '../../../Utils/number';
import { ERRORS } from '../../../Utils/const';
import { ResetForm } from '../../../Utils/helper';
import ModalNoti from '../../Components/ModalNoti/ModalNoti';
import CustomLoading from '../../Components/Loading/CustomLoading';
import CustomPagination from '../../Components/Pagination/Pagination';

export const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const Zone = () => { 
    const headers = ["STT", "Tên khu vực", "Mã khu vực", "Trung tâm (Kinh độ, Vĩ độ)", "Thời gian cập nhật", "Hành động"]

    const [page, setPage] = useState(+0);
    const [rowsPerPage, setRowsPerPage] = useState(+15);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [reCallList, setReCallList] = useState(false) ;

    const [validate, setValidate] = useState({
        name: false, 
        code: false, 
        lat: false,
        lng: false,
        message: {
            name: "",
            code: "",
            lat: "",
            lng: ""
        }
    });
    const [open, setOpen] = useState(false);
    const [zone, setZone] = useState({name: "", code: "", detail: "",center: {lat: "", lng: ""}});
    const [modalNoti, setModalNoti] = useState({
        open: false,
        title: "",
        message: ""
    });

    useEffect(()=> {
        zoneService.getListZone().then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(err => console.log(err))
    }, [reCallList])

    const funcResetValidate = () => {
        const resetValidate = {
            name: false, 
            code: false, 
            lat: false,
            lng: false,
            message: {
                name: "",
                code: "",
                lat: "",
                lng: ""
            }
        }
        setValidate(resetValidate);
    }
    
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        const resetData = ResetForm(zone);
        setZone(resetData);
        funcResetValidate()
        setOpen(false);
    };


    const onChangeFieldZone = (e) => {
        const {name, value} = e.target;
        setZone({...zone, [name]: value})
    }
    
    const onChangeCenterZone = (e) => {
        const {name, value} = e.target;
        setZone({...zone, 
            center: {
                ...zone.center,
                [name]: value
            }
        })
    }
    const resetData = () => {
        const resetData = ResetForm(zone)
        setZone(resetData)
    }
    const submitCreateZone = () => { 
        if(CHECK_EMPTY_STRING(zone.name) || CHECK_EMPTY_STRING(zone.code) || CHECK_EMPTY_STRING(zone.center.lat) || CHECK_EMPTY_STRING(zone.center.lng)){
            setValidate({
                ...validate, 
                name: CHECK_EMPTY_STRING(zone.name), 
                code: CHECK_EMPTY_STRING(zone.code),
                lat: CHECK_EMPTY_STRING(zone.center.lat),
                lng: CHECK_EMPTY_STRING(zone.center.lng)
            })
        }
        else {
            const data = {
                name: zone.name,
                code: zone.code,
                center: {
                    lat: +zone.center.lat,
                    lng: +zone.center.lng
                }
            }
            
            zoneService.createZone(data).then(res => {
                setReCallList(!reCallList)
                funcResetValidate()
                const resetData = ResetForm(zone)
                setZone(resetData)
                handleClose()
                setModalNoti({...modalNoti, open: true, title: "Thành công!", message: "Thêm mới khu vực thành công!"});
            }).catch(err => {
                if (err.response) {
                    resetData()
                    const er = err.response.data.error;
                    const errTranslate = ERRORS.ZONE.find(err => err.message === er);
                    handleClose();
                    setModalNoti({...modalNoti, open: true, title: "Thất bại!", message: `Thêm mới khu vực thất bại! Lỗi: ${errTranslate.translate}.`});
                 }
            })
        }
        
    }

    

    const validateName = (e, option) => {
        if(e.target.name === "name"){
            if(option === "focus") {
                setValidate({
                    ...validate,
                    name: false
                })
            }
            else {
                setValidate({
                        ...validate,
                        message: {
                            ...validate.message,
                            name: "Không được bỏ trống trường tên khu vực!"
                        }, 
                        name: CHECK_EMPTY_STRING(zone.name)
                })
            }
        } 
        if(e.target.name === "code"){
            if(option === "focus") {
                setValidate({...validate, code: false})

            }
            else {
                setValidate({
                        ...validate,
                        message: {
                            ...validate.message,
                            code: "Không được bỏ trống trường mã khu vực!"
                        }, 
                        code: CHECK_EMPTY_STRING(zone.code)
                })
                
            }
        } 
        if(e.target.name === "lat"){
            if(option === "focus") {
                setValidate({...validate, lat: false})
            }
            else {
                if(CHECK_EMPTY_STRING(zone.center.lat)) {
                    setValidate({
                        ...validate, 
                        lat: true,
                        message: {
                            ...validate.message,
                            lat: "Không được bỏ trống kinh độ!"
                        }, 
                    })
                }
                else if(checkNumber(zone.center.lat)){
                    setValidate({
                        ...validate,
                        lat: true,
                        message: {
                            ...validate.message,
                            lat: "Kinh độ phải là dạng số!"
                        }, 
                    })
                }
                else{
                    setValidate({
                        ...validate,
                        lat: false,
                        message: {
                            ...validate.message,
                            lat: ""
                        }, 
                    })
                }
                
            } 
        } 
        if(e.target.name === "lng"){
            if(option === "focus") {
                setValidate({...validate,
                    lng: false
                })

            }
            else {
                if(CHECK_EMPTY_STRING(zone.center.lng)) {
                    setValidate({
                        ...validate, 
                        lng: true,
                        message: {
                            ...validate.message,
                            lng: "Không được bỏ trống vĩ độ!"
                        }, 
                    })
                }
                else if(checkNumber(zone.center.lng)){
                    setValidate({
                        ...validate,
                        lng: true,
                        message: {
                            ...validate.message,
                            lng: "Vĩ độ phải là dạng số!"
                        }, 
                    })
                }
                else {
                    setValidate({
                        ...validate,
                        lng: false,
                        message: {
                            ...validate.message,
                            lng: ""
                        }, 
                    })
                }
            }
        } 
    }

    const done = () => {
        const resetModal = ResetForm(modalNoti)
        setModalNoti(resetModal)
    }

    return(
        <>
        <ModalNoti modal={modalNoti} done={done} />
            <Paper sx={{ overflow: 'hidden'}} elevation={8}>
                <Grid className="el-button" container>
                    <Grid item xs={9} />
                    <Grid item xs={3} className="end create-button" >
                        <Button variant="contained" onClick={handleClickOpen} >Thêm mới khu vực</Button>
                    </Grid>
                </Grid>
                <TableContainer className="table-zone">
                    <Table stickyHeader>
                        <TableHead className='text-bold upper'>
                            <TableRow>
                                {Children.toArray(headers.map(h => {
                                    return (
                                        <TableCell align="left">
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: 'uppercase',
                                                    fontSize: 14,
                                                    color: "#606060"
                                                }}
                                            >
                                                {h}
                                            </Typography>
                                        </TableCell>
                                    )
                                }))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isLoading ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((zone, index)=> {
                                return (
                                    <TableRow
                                        key={zone.id}
                                        hover
                                        role="checkbox"
                                    >   
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell align="left" style={{ width: "30%" }}>{zone.name}</TableCell>
                                        <TableCell align="left">{zone.code}</TableCell>
                                        <TableCell align="left">{zone.center?.lat}, {zone.center?.lng}</TableCell>
                                        <TableCell align="left">{zone.mtime ? format(zone.mtime * 10e2, "HH:mm:ss, dd/MM/yyyy"): "api loi"}</TableCell>
                                        <TableCell align="left"><EditIcon /> &nbsp;&nbsp;&nbsp; <DeleteIcon /></TableCell>
                                    </TableRow>
                                )
                            }) : <CustomLoading numbers={6}/>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container sx={{ p: "1%", ml: "1%" }}>
                    <CustomPagination
                        data={data}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        parentCallBack={handleChangePage}
                    />
                </Grid>
            </Paper>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="modal-create-zone"
                fullWidth
                maxWidth='md'
                sx={{position: "absolute", top:"-20%"}}
                
            >
                <DialogTitle className='dialog-title-zone'>Thêm mới khu vực</DialogTitle>
                <DialogContent sx={{mt: "23px", mb: "10px", pl: "96px", pr: "102px"}}>
                    <Grid>
                        Tên khu vực*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField 
                            error = {validate.name}
                            onBlur={(e)=> validateName(e, "blur")}
                            onFocus={(e)=> validateName(e, "focus")}
                            name="name" 
                            value={zone.name} 
                            onChange={onChangeFieldZone} 
                            fullWidth label="Tên khu vực" 
                            placeholder='Nhập tên khu vực'
                        />
                        {validate.name &&<p className="color-red">{validate.message.name}</p> }
                    </Grid>
                    <Grid>
                        Mã khu vực*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2  }}>
                        <TextField 
                            error={validate.code}
                            name="code" 
                            value={zone.code} 
                            onChange={onChangeFieldZone} 
                            fullWidth 
                            label="Mã khu vực" 
                            placeholder='Nhập mã khu vực'
                            onBlur={(e)=> validateName(e, "blur")}
                            onFocus={(e)=> validateName(e, "focus")}
                       />
                        {validate.code && <p className="color-red">{validate.message.code}</p>}
                    </Grid>
                    <Grid>
                        Vị trí trung tâm*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2  }} container>
                        <Grid item xs={5} >
                            <TextField 
                                error = {validate.lat}
                                onBlur={(e)=> validateName(e, "blur")}
                                onFocus={(e)=> validateName(e, "focus")}
                                onChange={onChangeCenterZone} 
                                name="lat" 
                                fullWidth 
                                label='Kinh độ'
                                value={zone.center.lat}
                                />
                            {validate.lat && <p className="color-red"> {validate.message.lat}</p>}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                            <TextField 
                                error = {validate.lng}
                                value={zone.center.lng}
                                onBlur={(e)=> validateName(e, "blur")}
                                onFocus={(e)=> validateName(e, "focus")}
                                fullWidth  
                                label='Vĩ độ'
                                onChange={onChangeCenterZone} 
                                name="lng" 
                            />
                            {validate.lng && <p className="color-red"> {validate.message.lng}</p>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{mb: "55px", pl: "96px", pr: "103px"}}>
                    <Grid container>
                        <Grid item xs={5} sx={{textAlign : "end"}} >
                            <Button className='button-wh' sx={{background: "#E5E5E5", color: "black"}} variant="contained" onClick={handleClose}>Huỷ</Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5} sx={{textAlign : "start"}}>
                            <Button type="submit" className='button-wh' variant="contained"
                                disabled={ [validate.name, validate.code, validate.lat, validate.lng].includes(true)}
                                onClick={submitCreateZone}
                            >Lưu</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Zone;

