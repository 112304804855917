import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ConvertCSVToJSON } from '../../../../../Utils/helper';

const ModalInput = (props) => {
    const { isOpen, parentCallBack } = props;

    const [ file, setFile ] = useState([]);

    const handleClose = () => {
        setFile([]);
        parentCallBack(false);
    }

    const handleConfirm = () => {
        parentCallBack(false, file);
    }

    const handleFileCapture = (e) => {
        const files = e.target.files
        const reader = new FileReader();
        reader.readAsText(files[0]);
        reader.onload = (e) => {
            const data = ConvertCSVToJSON(e.target.result);
            setFile(data);
        }
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
            // maxWidth={240}
            >
                <DialogTitle
                    color="primary"
                    sx={{ pl: "38%" }}
                >Nhập dữ liệu</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
                    <TextField
                        focused={true}
                        hiddenLabel
                        margin="dense"
                        id="input"
                        label="Nhấn chuột trái để chọn file từ máy tính. Hệ thống chỉ chấp nhận file CSV"
                        type="file"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            accept: ".csv"
                        }}
                        onChange={handleFileCapture}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Hủy</Button>
                    <Button onClick={handleConfirm}>Lưu</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalInput;