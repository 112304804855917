import { Http } from "../../../../Services/http";

const API_ENDPOINT = {
    GET_LIST_ZONE: "zone/list",
    CREATE_ZONE: "zone/create"
}

class ZoneService {
    constructor() {
      if (ZoneService._instance) {
        return ZoneService._instance;
      }
      ZoneService._instance = this;
    }
    
    getListZone() {
        return Http.get(API_ENDPOINT.GET_LIST_ZONE)
    }
    createZone(data) {
      return Http.post(API_ENDPOINT.CREATE_ZONE, data)
  }

  }
  
  const zoneService = new ZoneService();
  
  export default zoneService;
  