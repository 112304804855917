import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Compute } from '../../../../Utils/interpolation';
import { BOXGROUP_ID } from '../../../../Utils/const';
import { usePrevious } from '../../../Hooks/usePrevious';

const ComputeInterpolation = () => {
    const [input, setInput] = useState({
        Z: "",
        a: "",
        Q: ""
    })

    const prevInput = usePrevious(input);

    let [computeFor, setComputeFor] = useState("Q");

    const handleInput = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (!isNaN(+value)) {
            setInput({
                ...input,
                [name]: +value
            })
        }
    }

    const computeInterpolation = (computeFor) => {
        // get data and create instance to class compute
        const { Q, Z, a } = input;
        const interpolation = new Compute(BOXGROUP_ID);
        if (!isNaN(Q) && !isNaN(a)) {
            if (computeFor === "Q") {
                const Q_compute = `${interpolation.CalculateWaterFlow(+Z, +a)}`;
                setInput({ ...input, Q: Q_compute });
            }
            if (computeFor === "a") {
                const a_compute = `${interpolation.CalculateDrain(+Q, +Z)}`;
                setInput({ ...input, a: a_compute });
            }
            setComputeFor(computeFor);
        } else {
            if (isNaN(Q)) {
                const Q_compute = `${interpolation.CalculateWaterFlow(+Z, +a)}`;
                setInput({ ...input, Q: Q_compute });
            } else {
                const a_compute = `${interpolation.CalculateDrain(+Q, +Z)}`;
                setInput({ ...input, a: a_compute });
            }
        }
    }

    const handleCompute = () => {
        computeFor = Object.keys(input).filter(k => k !== "Z").find(k => input[k] === prevInput[k]);
        computeInterpolation(computeFor);
    }

    return (
        <>
            <Paper className="paper-listData" sx={{ overflow: 'hidden' }} elevation={8}>
                <Grid container className="pt-20">
                    <Grid item xs={1} />
                    <Grid item xs={1.5} className="pt-15">
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                textTransform: 'uppercase',
                                fontSize: 16,
                                color: "#606060"
                            }}
                        >
                            Nhập mực nước hồ (m):
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Mực nước hồ"
                            type="tel"
                            fullWidth
                            variant="filled"
                            name="Z"
                            value={input.Z}
                            onChange={handleInput}
                        />
                    </Grid>
                </Grid>

                <Grid container className="pt-10">
                    <Grid item xs={1} />
                    <Grid item xs={1.5} className="pt-15">
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                textTransform: 'uppercase',
                                fontSize: 16,
                                color: "#606060"
                            }}
                        >
                            Độ mở cống (cm):
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Độ mở cống"
                            type="tel"
                            fullWidth
                            variant="filled"
                            name="a"
                            value={input.a}
                            onChange={handleInput}
                        />
                    </Grid>
                </Grid>

                <Grid container className="pt-10">
                    <Grid item xs={1} />
                    <Grid item xs={1.5} className="pt-15">
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                textTransform: 'uppercase',
                                fontSize: 16,
                                color: "#606060"
                            }}
                        >
                            Lưu lượng xả (m3/s):
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Lưu lượng xả"
                            type="tel"
                            fullWidth
                            variant="filled"
                            name="Q"
                            value={input.Q}
                            onChange={handleInput}
                        />
                    </Grid>
                </Grid>

                <Grid container className="pt-15">
                    <Grid item xs={9} />
                    <Grid item xs={2} className="middle">
                        <Button
                            variant="contained"
                            onClick={handleCompute}
                        >Tính toán</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default ComputeInterpolation;