import { useState } from "react";
import CustomizedSnackbars from './../../../../Components/AlertNoti/AlertNoti';
import { CalculateCM, CalculateFloodCM } from "./hypothesis_data";
import { CalculateSpinFree } from "../../../../Components/Interpolation/spin";

const Hypothesis_CM = (props) => {
    const [rain, setRain] = useState()
    const [Ztltt, setZtltt] = useState()
    // const [dr, setDr] = useState()
    // const [qSpin, setQSpin] = useState()
    const [qFree, setQFree] = useState()
    const [flood, setFlood] = useState()

    const [noti, setNoti] = useState({
        open: false,
        message: "",
        severity: ""
    })

    function onChangeRain(e) {
        const { value } = e.target
        setRain(value)

        const zCalculate = CalculateCM(value, props.z)
        setZtltt(zCalculate)
        props.setZtl3(zCalculate);

        const qCalculate = CalculateSpinFree(zCalculate)
        setQFree(Math.round(qCalculate * 100) / 100)
        
        const floodCalculate = CalculateFloodCM(zCalculate);
        setFlood(floodCalculate)
    }

    const handleCloseNoti = () => {
        setNoti({
            open: false,
            message: "",
            severity: ""
        });
    }

    return (
        <>
        <CustomizedSnackbars open={noti.open} message={noti.message} severity={noti.severity} handleClose={handleCloseNoti} />
            <div className="hypothesis">
                <div className="title pt-20">
                    THÔNG SỐ GIẢ ĐỊNH
                </div>
                <div className="note">
                    <p>Thông số nhập</p>
                    <p>Thông số tính toán</p>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Lượng mưa giả định (mm)</p>
                        <input name="rain" className="value" onChange={onChangeRain} value={rain} />
                    </div>
                    <div className="item">
                        <p className="label">ZTL tính toán</p>
                        <input className="value" name="Ztl" value={Ztltt} />
                    </div>
                </div>
                {/* <div className="content">
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Độ mở cửa van (mở 2 cửa) (m)</p>
                        <input name="z3" className="value" value={dr} />
                    </div>
                </div> */}
                <div className="content">
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Qtrtd (tràn tự do) (m3/s)</p>
                        <input name="z3" className="value" value={qFree} />
                    </div>
                </div>
                {/* <div className="content" style={{ marginBottom: '10px', borderBottom: '2px dashed' }}>
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Qtrcc (tràn có cửa) (m3/s)</p>
                        <input name="z3" className="value" value={qSpin} />
                    </div>
                </div> */}
                 <div className="note">
                    <p></p>
                    <p className="sflood">Diện tích ngập tính toán</p>
                </div>
                <div className="content">
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Diện Tích Ngập (ha)</p>
                        <input name="qx" className="value" value={flood|| 0} />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Hypothesis_CM;