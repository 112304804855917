import { useState, useEffect } from "react";
import HelpService from "./Shared/HelpService";
import HelpDrain from "./Components/Interpolation/HelpDrain";
import HelpSpin from "./Components/Interpolation/HelpSpin";
import { format } from "date-fns";
import { CalculateCoordination, getUrlImage } from "../../Components/Interpolation/lake_coordination";
import ParametersWithPicture from "./Components/Parameters/ParametersWithPicture";
import { hasHelpSpin, hasDrainParameter, hasOneNumberDrain, hasTwoNumberDrain, hasThreeNumberDrain, ViewHypothesis } from "../../../Utils/display";
import { calculateQS } from "../../Components/Chart/Component/chart_data";
import { CalculateFlow as calculateQD } from "../../Components/Interpolation/drain";
import { InterpolationSource } from './../../Components/Interpolation/data';
import CustomizedSnackbars from './../../Components/AlertNoti/AlertNoti';
import useAudio from '../../Hooks/useAudio'
import { Button, Modal } from "@mui/material";
import AlertConfirm from "../../Components/AlertNoti/AlertConfirm";
import sharedService from "../../../Services/SharedService";
import authService from "../../../Services/AuthService";
import { goTo } from "../../../Utils/helper";
import { BOXGROUP_ID } from "../../../Utils/const";
import { ID_GROUPS } from "../../../Utils/const";
import HelpDOT from "./Components/DOT/HelpDOT";

const Help = () => {
    const [Ztl, setZtl] = useState()
    const [Ztl3, setZtl3] = useState()
    const [RA, setRA] = useState()
    const [Q, setQ] = useState()
    const [Zhl, setZhl] = useState()
    const [currentZs, setCurrentZs] = useState()
    const [currentZd, setCurrentZd] = useState()
    const [vol, setVol] = useState(false)
    const [noti, setNoti] = useState({
        open: false,
        message: "",
        severity: ""
    })
    const group_id = localStorage.getItem("group_id")

    useEffect(() => {
        HelpService.getRecordGroup()
            .then(res => {
                const { data } = res;
                if (ID_GROUPS.KG === BOXGROUP_ID) {
                    let currentZ = 0;
                    let recordQS, recordQD, idQS, idQD
                    data.map(d => {
                        if (d.count === 0) return;
                        const record = d.records[0]
                        const metrics = Object.keys(record)
                        
                        if (metrics.includes("WAU")) {
                            currentZ = record.WAU
                            setZtl(currentZ)
                            setCurrentZs(currentZ)
                            setCurrentZd(currentZ)
                        } else if (metrics.includes("TaperValve")) {
                            const DR1 = record.TaperValve * 0.85 / 100;
                            recordQD = { ...record, DR1 }
                            idQD = d.box.id
                        } else if (metrics.includes("DR1") || metrics.includes("DR2") || metrics.includes("DR3")) {
                            recordQS = { ...record }
                            idQS = d.box.id
                        }
                    })
                    recordQD.WAU = currentZ;
                    recordQS.WAU = currentZ;

                    const QS = calculateQS(recordQS, 0, idQS) || 0
                    const QD = calculateQS(recordQD, 0, idQD) || 0
                    const QT = +QS + +QD;
                    setQ(+QT)
                } else {
                    let countRA = 0;
                    let QS = 0;
                    let currentZ = 0;
                    let dr_value = 0;
                    data.map((d) => {
                        if (d.count === 0) return;
                        const record = d.records[0]
                        const metrics = Object.keys(record)

                        if (metrics.includes("WAU")) {
                            currentZ = record.WAU
                            setZtl(currentZ)
                            setCurrentZs(currentZ)
                            setCurrentZd(currentZ)
                            QS = calculateQS(record, currentZ);
                        } else if (metrics.includes("DR1")) {
                            dr_value = record.DR1 / 1000;
                        }
                        if (metrics.includes("WAD")) setZhl(record.WAD)
                        // if (metrics.includes("Q_of")) countQ += +record.Q_of
                        // if (metrics.includes("Q")) countQ += +record.Q
                        if (metrics.includes("RA")) countRA += record.RA
                    })
                    const QD = calculateQD(dr_value, currentZ)
                    setRA(countRA)
                    setQ(QS + QD)
                }
            })
    }, [])

    const onChangeZs = (e, option) => {
        const { value } = e.target;
        const numberEachGroup = InterpolationSource.find(i => i.group_id === group_id)
        if (option === 0) {
            setCurrentZs(value)
            const arrOfWater = numberEachGroup.S.z.filter(n => n !== 0)
            const max = Math.max.apply(null, arrOfWater);
            const min = Math.min.apply(null, arrOfWater);
            if (+value < min || +value > max) {
                setNoti({
                    open: true,
                    message: `Giá trị lớn nhất có thể nhập là: ${max}, thấp nhất là: ${min}.`,
                    severity: 'warning'
                })
            } else {
                setNoti({
                    open: false,
                    message: "",
                    severity: ""
                })
            }

        }
        else {
            setCurrentZd(e.target.value)
            const arrOfWater = numberEachGroup.D.z.filter(n => n !== 0)
            const max = Math.max.apply(null, arrOfWater);
            const min = Math.min.apply(null, arrOfWater);
            if (+value < min || +value > max) {
                setNoti({
                    open: true,
                    message: `Giá trị lớn nhất có thể nhập là: ${max}, thấp nhất là: ${min}.`,
                    severity: 'warning'
                })
            } else {
                setNoti({
                    open: false,
                    message: "",
                    severity: ""
                })
            }
        }
    }



    const handleCloseNoti = () => {
        setNoti({
            open: false,
            message: "",
            severity: ""
        });
    }

    // for warining Ztl
    const [valueWarning, setValueWarning] = useState("")
    const [ztlWarning, setZtlWarning] = useState(-1)
    const urlAudio = require("../../../Asset/warningAudio/warning.mp3")
    const audio = useAudio(urlAudio, { volume: 0.8, playbackRate: 2.5 })
    const [modalConfirm, setModalConfirm] = useState({ open: false, message: "", severity: "" })
    const [wau, setWau] = useState(null)
    const [delay, setDelay] = useState(false)
    const [intervalId, setIntervalId] = useState(0)

    useEffect(() => {
        if (+ztlWarning > 0 && !delay) {
            setDelay(true)
            const newId = setInterval(() => {
                sharedService.listRecordBoxGroup("WAU").then(
                    res => {
                        setWau(res.data[0].records[0])
                        setDelay(false)
                        if (res.data[0].records[0].WAU > +ztlWarning) {
                            audio.play()
                            setModalConfirm({
                                open: true,
                                message: `Mực nước thượng lưu vượt ngưỡng cảnh báo!`,
                                severity: 'error'
                            })
                        }
                    }
                ).catch(err => console.log(err, 'err call list record'))
            }, 120000)
            setIntervalId(newId)
        }
    }, [ztlWarning])

    const handleCloseConfirm = () => {
        setModalConfirm({ open: false, severity: "", message: "" })
        audio.pause()
        setZtlWarning(-1)
        clearInterval(intervalId)
        setIntervalId(0)
        setValueWarning("")
    }

    const saveZtlWarning = () => {
        setZtlWarning(+valueWarning)
        setNoti({
            open: true,
            message: `Thiết lập giá trị cảnh báo thành công!`,
            severity: 'success'
        })
    }
    const logOut = () => {
        authService.setToken({ id: "" });
        authService.setGroupId("");
        sessionStorage.setItem('check', false);
        goTo("app/login");
    }
    useEffect(() => {
        if (group_id == ID_GROUPS.DOT) {
            let checkStorage = sessionStorage.getItem('check')
            if (checkStorage === null) {
                logOut()
            }
            else if (checkStorage !== null && !checkStorage) {
                logOut()
            }
        }
    }, [group_id])
    return (
        <>
            <AlertConfirm open={modalConfirm.open} message={modalConfirm.message} severity={modalConfirm.severity} handleClose={handleCloseConfirm} />
            <CustomizedSnackbars open={noti.open} message={noti.message} severity={noti.severity} handleClose={handleCloseNoti} />
            <div className="Help">
                {group_id !== ID_GROUPS.DOT ? <>
                    <div className="top">
                        <div className="left">
                            <div className="image">
                                <img alt="" src={require("../../../Asset/lake_coordination/" + getUrlImage())} />
                            </div>
                            <div className="info ptb-20">
                                <div className="content">
                                    <p className="value"><span className="label">Mực nước hồ(m): </span> {Ztl}</p>
                                </div>
                                <div className="content">
                                    <p className="value"><span className="label">Ngày: </span> {format(Date.now(), "dd/MM/yyyy")}</p>
                                </div>
                                <div className="content">
                                    <p className="value"><span className="label">Thuộc vùng: </span> {CalculateCoordination(Ztl)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="current">
                                <div className="title pt-20">
                                    THÔNG SỐ THỜI ĐIỂM
                                </div>
                                <div className="content">
                                    <div className="item">
                                        <p className="value">{Math.round(RA * 100) / 100 || 0}</p>
                                        <p className="label">Lượng mưa (mm)</p>
                                    </div>
                                    <div className="item">
                                        <p className="value">{Ztl || 0}</p>
                                        <p className="label">ZTL (m)</p>
                                    </div>
                                    {hasDrainParameter &&
                                        <div className="item">
                                            <p className="value">{Math.round(Q * 100) / 100}</p>
                                            <p className="label">Q xả (m3/s)</p>
                                        </div>
                                    }
                                    {ID_GROUPS.KG !== BOXGROUP_ID &&
                                        <div className="item">
                                            <p className="value">{Zhl || 0}</p>
                                            <p className="label">ZHL (m)</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="top">
                        <ParametersWithPicture z={Ztl3} />
                        <div className="right">
                            {ViewHypothesis({ z: Ztl, setZtl3 })}
                        </div>
                    </div>
                    <div className="bot">
                        <div className="left pt-10">
                            {hasHelpSpin && <>

                                <div className="title">
                                    Hỗ trợ vận hành tràn
                                </div>
                                <div className="pt-10 pl-20 pb-20 water-level-input">
                                    <p>Mực nước hồ</p>
                                    <input style={{ padding: '0px 5px', width: '150px' }} type="text" value={currentZs} onChange={(e) => onChangeZs(e, 0)} />
                                    <span>m</span>
                                </div>
                                <div className="content">
                                    {hasOneNumberDrain && <HelpSpin number={1} z={currentZs} />}
                                    {hasTwoNumberDrain && <HelpSpin number={2} z={currentZs} />}
                                    {hasThreeNumberDrain && <HelpSpin number={3} z={currentZs} />}
                                </div>

                            </>}
                        </div>
                        <div className="right pt-10">

                            {BOXGROUP_ID !== 'IXC8QY8W' &&
                                <>
                                    <div className="title">
                                        Hỗ trợ vận hành cống
                                    </div>
                                    <div className="pt-10 pb-20 water-level-input">
                                        <p>Mực nước hồ</p>
                                        <input style={{ padding: '0px 5px', width: '150px' }} value={currentZd} onChange={(e) => onChangeZs(e, 1)} type="text" />
                                        <span>m</span>
                                    </div>
                                    <HelpDrain z={currentZd} />
                                </>
                            }

                            <div className="title mb-20 warningZtl_title">
                                Mực nước thượng lưu cảnh báo
                            </div>
                            <div className="pt-10 pb-20 warningZtl">
                                <div className="label_warningZtl">Nhập Ztl cảnh báo </div>
                                <input style={{ padding: '0px 15px', width: '150px' }} value={valueWarning} onChange={(e) => setValueWarning(e.target.value)} type="text" />
                                <span>m</span>
                                <Button className="ml-40" variant="contained" onClick={saveZtlWarning}>Xác nhận</Button>
                            </div>
                        </div>
                    </div >
                </>
                    : <HelpDOT />
                }
            </div >
        </>
    )
}

export default Help;