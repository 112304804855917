import { useState, useEffect, Children } from "react";
import { Grid } from "@mui/material";
import Statistical from "./Statistical/Statistical";
import sharedService from "../../../../Services/SharedService";
import Map from "./Map/Map";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../../Components/Chart/Component/chart_data";
import LineChart from "../../../Components/Chart/Component/LineChart";
import BarChart from "../../../Components/Chart/Component/BarChart";
import { GetUnit } from "../../../../Utils/helper";
import MultiLineChart from "../../../Components/Chart/Component/MultiLineChart";

const DashBoardAC = ({ group, center, isLoading }) => {
    const [charts, setCharts] = useState();
    const [recordsRA, setRecordsRA] = useState([]);

    useEffect(async () => {
        const groupRecord = await (await sharedService.listRecordBoxGroup()).data;
        const boxRA = groupRecord.find(g => g.box.type === 'RA')
        groupRecord.map(g => {
            g.box.metrics.map(m => {
                if (m.code === 'waterup') m.code = 'WAU'
                return m
            })
            g.metrics.map(m => {
                if (m === 'waterup') m = 'WAU'
                return m
            })
            g.records.map(r => {
                if (r.waterup) r.WAU = r.waterup
            })
            return g
        })

        const dataRA = [
            {
                box: { id: boxRA.box.metrics[0].code , name: boxRA.box.metrics[0].name },
                records: boxRA.records.map(r => { return { ...r, RA: r[boxRA.box.metrics[0].code]} })
            },
            {
                box: { id: boxRA.box.metrics[1].code, name: boxRA.box.metrics[1].name },
                records: boxRA.records.map(r => { return { ...r, RA: r[boxRA.box.metrics[1].code]} })
            }
        ]
        setRecordsRA(dataRA)
        const result = groupRecord.map(g => {
            const chart = BuildChartData(g.records, g.box)
            return chart
        })
        const docs = adjustRAData(result.filter(r => r))
        setCharts(docs)
    }, [])


    function GetBoxName(box_id) {
        const doc = group.boxs.find(b => b.id === box_id)
        const name = doc.name
        //if (name.length > 12) return name.slice(-11)
        return doc.name
    }

    return (
        <>
            <Grid className="DashBoardAC" container columns={12}>
                <Grid item xs={12} lg={6} sx={{ marginTop: { xs: '20px', lg: '0px' } }} className="list-chart">
                    {charts && Children.toArray(charts.map(t => {
                        if (t.type === 'line') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                            <div className="chart-item">
                                <LineChart height='100%' min={400} data={t} max={500} />
                            </div>
                        </div>
                        if (t.type === 'bar') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                            <div className="chart-item">
                                <BarChart height='100%' data={t} min={0} max={10} />
                            </div>
                        </div>
                        if (t.type === "multiLine") return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {t.box_id === "RIENVHK4" ? "đo mở tràn" : METRIC_GROUPS.find(m => m.metric === t.metric).label}  </p>
                            <div className="chart-item">
                                <MultiLineChart height='100%' data={t} max={10} />
                            </div>
                        </div>
                    }))}
                </Grid>
                <Grid item xs={0} lg={0.2}>
                </Grid>
                <Grid item xs={12} lg={5.5} sx={{ maxWidth: '1000px', minWidth: '260px', width: '200px', marginTop: { xs: '20px', lg: '0px' } }}>
                    <Statistical data={recordsRA} />
                    <Map group={group} center={center} isLoading={isLoading} />
                </Grid>
            </Grid>
        </>
    )
}

export default DashBoardAC;