import { useState } from "react";
import { CalculateDrain, CalculateFlow, CalculateDrainKG } from "../../../../Components/Interpolation/drain";
import { BOXGROUP_ID, ID_GROUPS } from "../../../../../Utils/const";

const HelpDrain = (props) => {
    const [q, setQ] = useState();
    const [dr, setDr] = useState();

    function onChangeDr(e) {
        const dr_value = e.target.value;
        setDr(dr_value);
        const q_value = CalculateFlow(dr_value, props.z)
        setQ(q_value)
    }

    function onChangeQ(e) {
        const q_value = e.target.value;
        setQ(q_value);
        if (BOXGROUP_ID === ID_GROUPS.KG) {
            const dr_value = CalculateDrainKG(q_value, props.z)
            setDr(dr_value)
        } else {
            const dr_value = CalculateDrain(q_value, props.z)
            setDr(dr_value)
        }
    }

    return (
        <>

            <div className="content">
                <div className="item">
                    <div className="label">
                        Trường hợp vận hành cống
                    </div>
                    <div className="input">
                        <div className="luu-luong">
                            Nhập lưu lượng
                            <input value={q} onChange={(e) => onChangeQ(e)} type="text" />
                            m3/s
                        </div>
                        <div className="do-mo-van">
                            Độ mở van tính toán
                            <input value={dr} onChange={(e) => onChangeDr(e)} type="text" />
                            m
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpDrain;