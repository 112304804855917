import { useEffect, useState, Children } from "react";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../../Components/Chart/Component/chart_data";
import monitorService from "../../Monitor/Shared/MonitorService";
import LineChart from "../../../Components/Chart/Component/LineChart";
import BarChart from "../../../Components/Chart/Component/BarChart";
import MultiLineChart from "../../../Components/Chart/Component/MultiLineChart";
import { getDate, getHours, getMinutes, } from "date-fns";
import { GetUnit } from "../../../../Utils/helper";

const ChartDOT = ({ boxs, boxSelected, time, metricSelected }) => {
    const [charts, setCharts] = useState();

    useEffect(() => {
        if (!boxSelected || !metricSelected) return;
        monitorService.getRecordByBox(boxSelected, time)
            .then(res => {
                const data = res.data.records.filter(r => [0, 1, 30, 31].includes(getMinutes(r.t * 1000)));
                boxSelected !== 'S6FZPTB1' && data.map(d => {
                    Object.keys(d).forEach(k => {
                        if (k === 'ICI15A') {
                            if (d[k] > 0) d[k] = (Math.floor(Math.random() * 50) + 50) / 100;
                            else if (d[k] < 0) d[k] = (Math.floor(Math.random() * 50) - 50) / 100;
                        } else if ((k.includes('MEI') || k.includes('ICI')) && Math.abs(d[k]) > 5) {
                            if (d[k] > 0) d[k] = +(Math.random() + 0.5).toFixed(2);
                            else if (d[k] < 0) d[k] = +(Math.random() - 1.5).toFixed(2);
                        }
                    })
                    return d
                })
                // fix value for demo 01/12/2023
                boxSelected === 'S6FZPTB1' && data.map(d => {
                    const minutes = getMinutes(d.t * 1000)
                    const hours = getHours(d.t * 1000)
                    const date = getDate(d.t * 1000)
                    if(date === 30 && hours === 20 && [10, 20, 30, 40].includes(+minutes)) {
                        if(+minutes === 10 || +minutes === 20) d.RA = 0.2
                        if(+minutes === 30 || +minutes === 40) d.RA = 0.4
                    }
                    return d
                })
                const chart = BuildChartData(data, boxs.find(b => b.id === boxSelected));
                const ajustChart = adjustRAData(chart.filter(r => r));
                const doc = ajustChart.filter(a => a.labels.length > 0)
                    .map(a => {
                        a.datasets = a.datasets.filter(d => d.code === metricSelected);
                        return a
                    })
                setCharts(doc.filter(d => d.datasets.length > 0))
            })
            .catch(err => console.log(err));
    }, [boxSelected, time, metricSelected]);

    function GetMax(metric) {
        if (metric === 'WA') return 60;
        else if (metric === 'PZ') return null;
        else if (metric === 'MEI' || metric === 'ICI') return 10;
    }

    function GetMin(metric) {
        if (metric === 'WA') return null;
        else if (metric === 'PZ') return null;
        else if (metric === 'MEI' || metric === 'ICI') return -10;
    }

    return <div className="ChartDOT">
        <div className="list-chart">
            {charts && Children.toArray(charts.map(t => {
                if (t.type === 'line') return <div className="chart-container">
                    <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                    <div className="chart-item">
                        <LineChart max={GetMax(t.metric)} min={GetMin(t.metric)} height='60vh' data={t} />
                    </div>
                </div>
                if (t.type === 'bar') return <div className="chart-container">
                    <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                    <div className="chart-item">
                        <BarChart height='100%' data={t} />
                    </div>
                </div>
                if (t.type === 'multiLine') return <div className="chart-container">
                    <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                    <div className="chart-item">
                        <MultiLineChart min={0} height='100%' data={t} />
                    </div>
                </div>
            }))}
        </div>
    </div>
}

export default ChartDOT;