import { CalculateFlow as CalculateFlowDD, roundMe } from "../../../../Components/Interpolation/spin";
import { LOCAL_STORAGE } from "../../../../../Utils/const";
import { CalculateSpinFree } from "../../../../Components/Interpolation/spin";

const getGroupId = () => {
    const id = localStorage.getItem(LOCAL_STORAGE.GROUP_ID)
    if (!id) return ""
    return id
}


function findPositionBeforeAfter(value, arr) {
    let result;
    let pos1;
    let pos2;
    arr.forEach((a, i) => {
        const minus = value - a;
        if (a <= value && (!result || minus < result)) {
            result = minus;
            pos1 = i;
            pos2 = i + 1;
        }
    })
    return [pos1, pos2]
}

const FLOW = [
    {
        group_id: 'KKKXW2TW',
        z: [110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 129.5, 130, 131, 132, 133, 134, 135, 136, 137, 138, 145, 150],
        v: [0, 0.08, 0.16, 0.23, 0.31, 0.56, 1.25, 2.42, 4.1, 6.34, 9.68, 14.24, 19.74, 26.23, 33.87, 42.93, 53.47, 65.3, 78.3, 92.4, 99.93, 107.45, 123.55, 140.78, 159.22, 178.86, 199.75, 221.82, 245.56, 270.48, 300, 500],
        s: 302,
        a0: 0.75
    },
    {
        group_id: 'IXC8QY8W',
        z: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
        v: [0, 0.05, 0.38, 1.4, 4.2, 10.22, 20.02, 33.36, 49.86, 69.23, 91.14, 115.42, 142.08, 171.07, 202.49, 236.35, 272.58, 311.2, 352.27, 395.79, 441.8, 490.29, 451.18, 594.47],
        s: 0.634,
        a0: 0.09
    }
]



export function CalculateCM(X, Ztl) {
    const s = 0.634;
    const a0 = 0.09;
    var Q3h = a0 * X * s * 1000 / (3 * 3600);  // Lưu lượng sau 3h
    var W1 = a0 * X * s;    //1000 Tổng lượng dòng chảy trong 3 giờ tới (10^6m3)

    const v = [0, 0.05, 0.38, 1.4, 4.2, 10.22, 20.02, 33.36, 49.86, 69.23, 91.14, 115.42, 142.08, 171.07, 202.49, 236.35, 272.58, 311.2, 352.27, 395.79, 441.8, 490.29, 451.18, 594.47]
    const z = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

    // Noi suy v
    let V1 = 0;
    for (let i = 0; i < z.length; i++) {
        if (Ztl > z[i] && Ztl <= z[i + 1]) {
            V1 = (Ztl - z[i]) / (z[i + 1] - z[i]) * (v[i + 1] - v[i]) + v[i];
        }
    }

    // Noi suy z
    let W2 = V1 + W1
    let Z3h = 0;
    for (let i = 0; i < z.length; i++) {
        if (W2 > v[i] && W2 <= v[i + 1]) {
            Z3h = (W2 - v[i]) / (v[i + 1] - v[i]) * (z[i + 1] - z[i]) + z[i];
        }
    }

    return Math.round(Z3h * 100) / 100
}

export function CalculateFloodCM(z_value) {
    const flood = [0, 0, 0.18, 0.24, 0.3, 0.87, 1.07, 1.21, 1.81]
    const z = [21.18, 21.25, 21.35, 21.5, 21.57, 21.75, 21.89, 22.06, 22.28]
    const result = OneWayInterpolation(z_value, z, flood)
    return Math.round(result * 100) / 100
}

export function CalculateFlowByRainfall(X, Ztl) {
    const F = FLOW.find(f => f.group_id === getGroupId())
    const { z, v, s, a0 } = F
    const Q3h = a0 * X * s * 1000 / (3 * 3600);
    const W1 = a0 * X * s / 1000;

    // Noi suy v
    let V1 = 0;
    for (let i = 0; i < z.length; i++) {
        if (Ztl > z[i] && Ztl <= z[i + 1]) {
            V1 = (Ztl - z[i]) / (z[i + 1] - z[i]) * (v[i + 1] - v[i]) + v[i];
        }
    }

    // Noi suy z
    const W2 = V1 + W1;
    let Z3h = 0;
    for (let i = 0; i < v.length; i++) {
        if (W2 > v[i] && W2 <= v[i + 1]) {
            Z3h = (W2 - v[i]) / (v[i + 1] - v[i]) * (z[i + 1] - z[i]) + z[i];
        }
    }

    return [
        Math.round(Q3h), // Lưu lượng sau 3h
        Math.round(Z3h * 100) / 100, //  Mục nước hồ sau 3h
        // W1,  //  Tổng lượng dòng chảy trong 3 giờ tới (10^6m3)
    ]
}

const H = [110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 129.5, 130, 131, 132, 133, 134, 135, 136, 137, 138, 145, 150]
const VSL = [0, 0.08, 0.16, 0.23, 0.31, 0.56, 1.25, 2.42, 4.1, 6.34, 9.68, 14.24, 19.74, 26.23, 33.87, 42.93, 53.47, 65.3, 78.3, 92.4, 99.93, 107.45, 123.55, 140.78, 159.22, 178.86, 199.75, 221.82, 245.56, 270.48, 300, 500]

// Sông lũy
export function CalculateSL(X, Ztl, S = 302,) {
    const a0 = 0.75;
    const Q3h = a0 * X * S * 1000 / (3 * 3600);
    const W1 = a0 * X * S / 1000;

    let V1;
    // Noi suy VSL
    for (let i = 0; i < H.length; i++) {
        if (Ztl > H[i] && Ztl <= H[i + 1]) {
            V1 = (Ztl - H[i]) / (H[i + 1] - H[i]) * (VSL[i + 1] - VSL[i]) + VSL[i];
        }
    }

    // Noi suy H
    const W2 = V1 + W1;
    let Z3h;
    for (let i = 0; i < VSL.length; i++) {
        if (W2 > VSL[i] && W2 <= VSL[i + 1]) {
            Z3h = (W2 - VSL[i]) / (VSL[i + 1] - VSL[i]) * (H[i + 1] - H[i]) + H[i];
        }
    }
    return [
        Math.round(Q3h), // Lưu lượng sau 3h
        Math.round(Z3h * 100) / 100, //  Mục nước hồ sau 3h
        // W1,  //  Tổng lượng dòng chảy trong 3 giờ tới (10^6m3)
    ]
}

//Hồ Dương Đông
const RA = [0, 50, 100, 150, 200, 250, 294, 416, 544, 904];
const ZRA = [28.8, 28.86, 28.88, 28.91, 28.95, 29, 29.09, 29.51, 29.93, 31.43]
const ZV = [0, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
const VDD = [0, 0, 0, 0.002, 0.008, 0.025, 0.063, 0.131, 0.238, 0.388, 0.577, 0.802, 1.062, 1.355, 1.679, 2.040, 2.436, 2.859, 3.313, 3.796, 4.306, 4.841, 5.397, 5.973, 6.567, 7.178, 7.807, 8.453, 9.116, 9.796, 10.493, 11.208, 11.942, 12.695, 13.468, 14.261]
const ZFree = [0, 24.30, 24.50, 24.70, 24.90, 25.10, 25.30, 25.50, 25.70, 25.90, 26.10, 26.30, 26.50, 26.70, 26.90, 27.10, 27.30, 27.50, 27.70, 27.90, 28.10, 28.30, 28.77, 28.90, 29.15, 29.35, 29.55, 29.75, 29.95, 30.15, 30.35, 30.55, 30.75, 30.95, 31.10]
const QFree = [0, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 21.89, 109.41, 187.20, 272.71, 364.42, 461.36, 562.85, 668.41, 777.64, 890.25, 828.13, 891.61]

const SFloodedCD = [0, 14.733, 19.433, 24.133, 28.834, 33.534, 37.67, 64.57, 131.87, 383.33] // Ngập xã Cửa Dương
const SFloodedDT = [0, 7.709, 8.894, 10.078, 11.263, 12.448, 13.49, 23.35, 43.64, 64.1] // Ngập xã Dương Tử
const SFloodedDD = [0, 21.390, 35.144, 48.898, 62.652, 76.406, 88.51, 131.41, 147.72, 198.76]   // Ngập xã Dương Đông
const SFlooded = [SFloodedCD, SFloodedDT, SFloodedDD]

export function OneWayInterpolation(value, arr1, arr2) {
    const [x1, x2] = findPositionBeforeAfter(value, arr1);

    const h1 = value - arr1[x1];
    const h2 = arr1[x2] - value;

    const result = (h1 * arr2[x2] + h2 * arr2[x1]) / (h1 + h2);
    return roundMe(result)
}

export function CalculateOperationSupport(z_value, rain_value) {
    if (+rain_value === 904) rain_value = 903.99
    if (rain_value > 904) return ['', '', '', '']
    if (isNaN(z_value) || isNaN(rain_value)) return [0, 0, 0, 0];
    const z_normal = 28.77; //Mực nước dâng bình thường
    const v_normal = OneWayInterpolation(z_normal, ZV, VDD) //Dung tính dâng bthuong = 6.43
    const z_rain = OneWayInterpolation(rain_value, RA, ZRA) // MN tính toán theo lượng mưa
    const v_rain = OneWayInterpolation(z_rain, ZV, VDD) // Dung tích tính toán theo lượng mưa
    const v_current = OneWayInterpolation(z_value, ZV, VDD) // Dung tích hiện tại

    const v_delta = roundMe(v_rain - v_normal) // Delta V
    const v_calculate = v_current + v_delta // V tính toán
    const z_calculate = OneWayInterpolation(v_calculate, VDD, ZV) // Z tính toán

    let spin; // Độ mở tràn tính toán
    if (z_calculate < 28.86) spin = 0;
    else if (z_calculate >= 28.86 && z_calculate < 28.88 && rain_value < 150) spin = 0.25
    else if (z_calculate >= 28.88 && z_calculate < 28.91 && rain_value < 150) spin = 0.75
    else if (z_calculate >= 28.82 && z_calculate < 28.87 && rain_value < 250 && rain_value >= 150) spin = 2.25
    else spin = 5.5

    const q_spin = CalculateFlowDD(2, z_calculate, spin) // lưu lượng tràn qua cửa
    let q_free   // lưu lượng tràn tự do
    if (z_calculate > 31.1) q_free = 891.61;
    else q_free = OneWayInterpolation(z_calculate, ZFree, QFree)
    // [z_rain, v_rain, v_current, v_delta, v_calculate, z_calculate, spin, q_spin, q_free]
    return [z_calculate, spin, q_spin, q_free]
}

export function CalculateFlood(z_value) {
    if (isNaN(z_value)) return [];
    const result = [0, 1, 2].map(r => OneWayInterpolation(z_value, ZRA, SFlooded[r]))
    return result
}

export function CalculateKG(X0, Hho0, F0 = 23.4) {
    const a0 = 0.05;
    const ketqua = a0 * X0 * F0 * 1000 / (3 * 3600);
    const W1 = a0 * X0 * F0 / 1000;

    const str3 = "20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55"
    const mH3 = str3.split(",")
    const str4 = "0,0.0009,0.0051,0.0159,0.0373,0.0709,0.1175,0.1777,0.2539,0.3487,0.4689,0.6178,0.7916,0.9901,1.2137,1.4661,1.7501,2.0664,2.4191,2.8098,3.2412,3.7187,4.2425,4.8098,5.422,6.0822,6.7917,7.5527,8.3664,9.2325,10.1604,11.155,12.2078,13.3169,14.485,15.7184";
    const mH4 = str4.split(",")
    let V1;
    let N_ZV = mH3.length;
    let MHho0 = new Array;
    let MVho0 = new Array;
    for (let ii = 0; ii < N_ZV; ii++) {
        MHho0[ii] = Number(mH3[ii]);
        MVho0[ii] = Number(mH4[ii]);
    }

    //===============
    // Noi suy V
    for (let i = 0; i < N_ZV; i++) {
        if (Hho0 > MHho0[i] && Hho0 <= MHho0[i + 1]) {

            V1 = (Hho0 - MHho0[i]) / (MHho0[i + 1] - MHho0[i]) * (MVho0[i + 1] - MVho0[i]) + MVho0[i];

        } //if
    } // for
    //============ 
    let W2 = V1 + W1
    //===============
    // Noi suy H
    let Hhott;
    for (let i = 0; i < N_ZV; i++) {
        if (W2 > MVho0[i] && W2 <= MVho0[i + 1]) {
            Hhott = (W2 - MVho0[i]) / (MVho0[i + 1] - MVho0[i]) * (MHho0[i + 1] - MHho0[i]) + MHho0[i];
        } //if
    } // for
    //===================

    const result = {
        Q3h: Math.round(ketqua),
        W3h: Math.round(W1 * 1000) / 1000,
        Z3h: Math.round(Hhott * 100) / 100
    }
    return result;
}