import ChartDOT from "./Components/ChartDOT";
import ChartKG from "./Components/ChartKG";
import { ID_GROUPS } from "../../../Utils/const";

const ChartComponent = ({ boxs, boxSelected, time, metricSelected }) => {

    return <div className="ChartComponent">
        {window.localStorage.getItem('group_id') === ID_GROUPS.KG && <ChartKG boxs={boxs} boxSelected={boxSelected} time={time} />}
        {window.localStorage.getItem('group_id') === ID_GROUPS.DOT && <ChartDOT boxs={boxs} boxSelected={boxSelected} metricSelected={metricSelected} time={time} />}
    </div>
}

export default ChartComponent;