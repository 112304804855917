export const BOX_DOT2 = {
    "id": "S6FZPTB1",
    "name": "Trạm Data 3",
    "desc": null,
    "group_id": "UUAF4RA4",
    "zone_id": "GI3K8ROF",
    "location": {
      "lat": 20.7872948,
      "lng": 105.3378582
    },
    "device_id": "CR300_20930",
    "metrics": [
      {
        "code": "RA",
        "metric": "RA",
        "name": "Đo mưa",
        "lat": 20.7872948,
        "lng": 105.3378582,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "PZ1",
        "metric": "PZ",
        "name": "Mực nước ngầm 1",
        "lat": 20.7873089,
        "lng": 105.3378827,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "PZ2",
        "metric": "PZ",
        "name": "Mực nước ngầm 2",
        "lat": 20.7874215,
        "lng": 105.3378156,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "PZ3",
        "metric": "PZ",
        "name": "Mực nước ngầm 3",
        "lat": 20.7878587,
        "lng": 105.3375511,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "MEI",
        "metric": "MEI",
        "name": "Đo nghiêng",
        "lat": 20.7879565,
        "lng": 105.3378056,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "ICI11A",
        "metric": "ICI",
        "name": "Đo dịch động 11A",
        "lat": 20.7873089,
        "lng": 105.3378827,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "ICI11B",
        "metric": "ICI",
        "name": "Đo dịch động 11B",
        "lat": 20.7878587,
        "lng": 105.3375511,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "code": "ICI12A",
        "metric": "ICI",
        "name": "Đo dịch động 12A",
        "lat": 20.7878587,
        "lng": 105.3375511,
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "name": "Đo dịch động 12B",
        "code": "ICI12B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 13A",
        "code": "ICI13A",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 13B",
        "code": "ICI13B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 21A",
        "code": "ICI21A",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 21B",
        "code": "ICI21B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 22A",
        "code": "ICI22A",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 22B",
        "code": "ICI22B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 23A",
        "code": "ICI23A",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 23B",
        "code": "ICI23B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 24A",
        "code": "ICI24A",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 24B",
        "code": "ICI24B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 25A",
        "code": "ICI25A",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động 25B",
        "code": "ICI25B",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": "",
        "lat": null,
        "lng": null
      },
      {
        "name": "Đo dịch động INL1",
        "code": "INL1",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "name": "Đo dịch động INL2",
        "code": "INL2",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": ""
      },
      {
        "name": "Đo dịch động INL3",
        "code": "INL3",
        "metric": "ICI",
        "warning1": "",
        "warning2": "",
        "warning3": ""
      }
    ],
    "type": "",
    "sort_order": null,
    "ctime": 1697048462986,
    "mtime": 1697086148178
  }