import ExcelJS from 'exceljs';
import { format } from 'date-fns';
import { GetUnit } from './helper'
import { BOXGROUP_ID, ID_GROUPS } from './const';

const DateTime = (t) => format(t, "dd/MM/yyyy - HH:mm")

export function GetDataExcel(records, metrics) {
    const docs = records.map((record, i) => {
        const time = DateTime(record.t * 10e2)

        const row = [i + 1, time]
        metrics.forEach(metric => row.push(record[metric.code]));
        return row
    });
    const header = metrics.map(m => {
        if(ID_GROUPS.DOT === BOXGROUP_ID) return m.name + GetUnit(m.metric)
        else return m.name
    });
    header.unshift("Thời gian");
    header.unshift("Stt");
    docs.unshift(header);
    return docs
}

export async function NewExcel(records, metrics) {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Bao cao');
    const data = GetDataExcel(records.reverse(), metrics);
    AddTableExcel(sheet, data)
    workbook.xlsx.writeBuffer().then(function (dataExcel) {
        const blob = new Blob([dataExcel], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        const name = "BaoCao.xlsx"
        anchor.download = name;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
}

export function AddTableExcel(sheet, data) {
    let info = [];
    for (let i = 0; i < data[0].length; i++) {
        let obj = {
            key: 'c' + i,
            width: 18,
            alignment: 'center'
        }
        if (i === 0) obj.width = 5;
        else if (i === 1) obj.width = 25;
        info.push(obj)
    }
    sheet.columns = info;
    data.map((row) => {
        let obj = {}
        info.forEach((f, i) => {
            obj[f.key] = row[i]
        })
        return sheet.addRow(obj)
    })
}