const g = 0.981;
const m = 0.35;

export const TYPE_FLOW = {
    SEWER: "sewer",
    OVER: "over"
}

const InterpolationSource = [
    // Sông Mây
    {
        group_id: "09398145",
        Z: [17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27], // mực nước hồ (m)
        a: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120], // độ mở cửa cống cấp nước (cm)
        Q: [
            [0.38, 0.42, 0.46, 0.49, 0.52, 0.55, 0.58, 0.61, 0.64, 0.66, 0.69, 0.71, 0.73, 0.76, 0.78, 0.80, 0.82, 0.84, 0.86, 0.88, 0.90],
            [0.75, 0.83, 0.91, 0.98, 1.04, 1.10, 1.16, 1.22, 1.27, 1.32, 1.37, 1.42, 1.46, 1.51, 1.55, 1.59, 1.63, 1.67, 1.71, 1.75, 1.79],
            [1.11, 1.23, 1.35, 1.45, 1.55, 1.65, 1.73, 1.82, 1.90, 1.97, 2.05, 2.12, 2.19, 2.26, 2.32, 2.38, 2.45, 2.51, 2.56, 2.62, 2.68],
            [1.46, 1.63, 1.78, 1.93, 2.06, 2.18, 2.30, 2.41, 2.52, 2.62, 2.72, 2.82, 2.91, 3.00, 3.09, 3.17, 3.25, 3.33, 3.41, 3.49, 3.56],
            [1.80, 2.01, 2.21, 2.39, 2.56, 2.71, 2.86, 3.00, 3.14, 3.27, 3.39, 3.51, 3.63, 3.74, 3.85, 3.95, 4.06, 4.16, 4.26, 4.35, 4.45],
            [2.13, 2.39, 2.63, 2.85, 3.05, 3.24, 3.42, 3.59, 3.75, 3.90, 4.05, 4.20, 4.34, 4.47, 4.60, 4.73, 4.86, 4.98, 5.09, 5.21, 5.32],
            [2.45, 2.76, 3.04, 3.30, 3.54, 3.76, 3.97, 4.17, 4.36, 4.54, 4.71, 4.88, 5.04, 5.20, 5.36, 5.51, 5.65, 5.79, 5.93, 6.07, 6.20],
            [2.76, 3.12, 3.44, 3.74, 4.01, 4.27, 4.51, 4.74, 4.96, 5.17, 5.37, 5.56, 5.75, 5.93, 6.10, 6.28, 6.44, 6.60, 6.76, 6.92, 7.07],
            [3.05, 3.47, 3.84, 4.17, 4.48, 4.78, 5.05, 5.31, 5.56, 5.79, 6.02, 6.24, 6.45, 6.65, 6.85, 7.04, 7.23, 7.41, 7.59, 7.76, 7.94],
            [3.36, 3.81, 4.22, 4.60, 4.95, 5.27, 5.58, 5.87, 6.15, 6.41, 6.66, 6.91, 7.14, 7.37, 7.59, 7.80, 8.01, 8.21, 8.41, 8.61, 8.80],
            [3.64, 4.14, 4.60, 5.02, 5.40, 5.76, 6.10, 6.43, 6.73, 7.02, 7.31, 7.57, 7.83, 8.08, 8.33, 8.56, 8.79, 9.01, 9.23, 9.45, 9.66],
            [3.91, 4.48, 4.97, 5.43, 5.85, 6.25, 6.62, 6.97, 7.31, 7.63, 7.94, 8.23, 8.52, 8.79, 9.06, 9.32, 9.57, 9.81, 10.05, 10.28, 10.51]
        ],// lưu lượng xả
        V: [0, 33503, 67006, 228802.5, 390599, 737246.5, 1083894, 1624266, 2164638, 2875722.5, 3586807, 4461713, 5336619, 6380337.5, 7424056,
            8601052.5, 9778049, 11058164.5, 12338280, 13749616.5, 15160953, 16722037.5, 18283122, 19983829, 21684536, 23524866.5, 25365197], // thể tích hồ chứa (m3)
        ratio: 1, // tỉ lệ cho thể tích
        B: 39, // bề rộng tràn (m)
        type: TYPE_FLOW.SEWER
    },
    // Đa Tôn
    {
        group_id: "28087779",
        Z: [125.5, 126, 126.5, 127, 127.5, 128, 128.5, 129, 129.5, 130, 130.5, 131, 131.5, 132, 132.5, 133, 133.5, 134, 134.5, 135],
        a: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120], // độ mở cửa cống cấp nước (cm) (DR)
        Q: [
            [0.39, 0.43, 0.47, 0.50, 0.53, 0.56, 0.59, 0.62, 0.65, 0.67, 0.70, 0.72, 0.74, 0.76, 0.78, 0.81, 0.83, 0.85, 0.86, 0.88],
            [0.77, 0.85, 0.93, 1.00, 1.06, 1.12, 1.18, 1.23, 1.29, 1.34, 1.39, 1.43, 1.48, 1.52, 1.57, 1.61, 1.65, 1.69, 1.73, 1.76],
            [1.15, 1.27, 1.38, 1.49, 1.58, 1.67, 1.76, 1.84, 1.92, 2.00, 2.07, 2.14, 2.21, 2.28, 2.34, 2.40, 2.47, 2.52, 2.58, 2.64],
            [1.51, 1.68, 1.83, 1.97, 2.10, 2.22, 2.34, 2.45, 2.55, 2.66, 2.75, 2.85, 2.94, 3.03, 3.11, 3.20, 3.28, 3.36, 3.44, 3.51],
            [1.87, 2.08, 2.27, 2.45, 2.61, 2.76, 2.91, 3.05, 3.18, 3.31, 3.43, 3.55, 3.66, 3.77, 3.88, 3.99, 4.09, 4.19, 4.29, 4.38],
            [2.21, 2.47, 2.70, 2.92, 3.11, 3.30, 3.47, 3.64, 3.80, 3.95, 4.10, 4.24, 4.38, 4.52, 4.65, 4.77, 4.89, 5.01, 5.13, 5.25],
            [2.55, 2.85, 3.13, 3.38, 3.61, 3.83, 4.03, 4.23, 4.42, 4.59, 4.77, 4.93, 5.10, 5.25, 5.40, 5.55, 5.70, 5.84, 5.97, 6.11],
            [2.88, 3.23, 3.54, 3.83, 4.10, 4.35, 4.59, 4.81, 5.03, 5.23, 5.43, 5.62, 5.81, 5.99, 6.16, 6.33, 6.49, 6.66, 6.81, 6.97],
            [3.19, 3.59, 3.95, 4.28, 4.58, 4.87, 5.14, 5.39, 5.63, 5.87, 6.09, 6.30, 6.51, 6.71, 6.91, 7.10, 7.29, 7.47, 7.65, 7.82],
            [3.50, 3.95, 4.35, 4.71, 5.05, 5.37, 5.68, 5.96, 6.23, 6.49, 6.74, 6.98, 7.22, 7.44, 7.66, 7.87, 8.08, 8.28, 8.48, 8.67],
            [3.81, 4.29, 4.74, 5.15, 5.52, 5.88, 6.21, 6.53, 6.83, 7.12, 7.39, 7.66, 7.91, 8.16, 8.40, 8.64, 8.86, 9.09, 9.30, 9.52],
            [4.11, 4.63, 5.12, 5.57, 5.98, 6.37, 6.74, 7.08, 7.41, 7.73, 8.03, 8.33, 8.61, 8.88, 9.14, 9.40, 9.65, 9.89, 10.13, 10.36]
        ],// lưu lượng xả
        V: [0, 0, 0.002, 0.068, 0.34, 0.918, 1.383, 1.848, 2.498, 3.148, 3.985, 4.822, 5.861, 6.899, 8.11, 9.32, 10.69, 12.06, 13.557, 15.054, 16.658, 18.261, 20.025, 21.789, 23.747, 25.704, 30.056],
        ratio: 10e6, // V : 10e6
        B: 22, // bề rộng tràn (m)
        type: TYPE_FLOW.SEWER
    },
    // Sông Lũy
    {
        group_id: "KKKXW2TW",
        Z: [121, 121.5, 122, 122.5, 123, 123.5, 124, 124.5, 125, 125.5, 126, 126.5, 127, 127.5, 128, 128.5, 129, 129.5, 130, 130.5, 131, 131.5],
        a: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5],
        Q_of: {
            one_valve: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [5, 9, 12, 14, 16, 18, 19, 21, 22, 23, 24, 25, 27, 28, 29, 30, 31, 31, 32, 33, 34, 35],
                [0, 15, 21, 26, 30, 34, 37, 40, 43, 45, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 67, 69],
                [0, 16, 28, 36, 42, 48, 53, 58, 62, 66, 70, 73, 76, 80, 83, 86, 89, 92, 94, 97, 100, 102],
                [0, 0, 30, 43, 52, 60, 67, 74, 80, 85, 90, 95, 100, 104, 108, 113, 116, 120, 124, 128, 131, 134],
                [0, 0, 27, 46, 59, 70, 80, 88, 96, 103, 110, 116, 122, 127, 133, 138, 143, 148, 153, 157, 162, 166],
                [0, 0, 0, 46, 64, 78, 90, 101, 110,	119, 128, 135, 143, 150, 156, 163, 169,	175, 180, 186, 191,	197],
                [0, 0, 0, 46, 64, 83, 98, 112, 123,	134, 144, 153, 162,	171, 178, 186, 193,	200, 207, 214, 220,	226],
                [0, 0, 0, 0, 64, 85, 104, 120, 134,	147, 159, 170, 180,	190, 199, 208, 217,	225, 233, 241, 248,	255],
                [0, 0, 0, 0, 64, 85, 107, 127, 144,	159, 173, 185, 197,	209, 219, 229, 239,	249, 258, 266, 275,	283],
                [0, 0, 0, 0, 0, 85,	107, 130, 150, 168,	184, 199, 213, 226,	238, 249, 260, 271,	281, 291, 301, 310],
                [0, 0, 0, 0, 0, 85,	107, 131, 155, 175,	194, 211, 226, 241,	255, 268, 280, 292,	304, 315, 326, 336],
                [0, 0, 0, 0, 0, 0, 107, 131, 156, 180, 202,	221, 239, 255, 271,	285, 299, 313, 325,	338, 349, 361],
                [0, 0, 0, 0, 0, 0, 107, 131, 139, 180, 202,	221, 241, 269, 298,	329, 360, 392, 425,	459, 494, 530]
            ],
            two_valve: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [11, 18, 24, 28, 32, 35, 38, 41, 44, 46, 49, 51, 53, 55, 57, 59, 61, 63, 65, 66, 68, 70],
                [0, 30, 43, 52, 60, 67, 74,	80,	85,	90,	95,	100, 104, 108, 113,	116, 120, 124, 128,	131, 134, 138],
                [0, 32, 55,	71,	84,	96,	106, 106, 124, 132,	139, 146, 153, 159,	166, 172, 178, 183,	189, 194, 199, 204],
                [0, 0, 60, 85, 104,	120, 134, 147, 159,	170, 180, 190, 199,	208, 217, 225, 233,	241, 248, 255, 262, 269],
                [0, 0, 60, 92, 119,	141, 159, 176, 192,	206, 219, 232, 244,	255, 266, 276, 286,	296, 305, 314, 323,	332],
                [0, 0, 0, 92, 128, 156,	180, 202, 221, 239,	255, 271, 285, 299,	313, 325, 338, 349,	361, 372, 383, 393],
                [0, 0, 0, 92, 129, 166,	197, 223, 247, 268,	288, 307, 324, 341,	357, 372, 387, 401,	414, 428, 440, 453],
                [0, 0, 0, 0, 129, 170, 208,	241, 269, 295, 318,	340, 361, 380, 399,	417, 434, 450, 466,	481, 496, 510],
                [0, 0, 0, 0, 129, 166, 214,	253, 287, 317, 345,	371, 395, 417, 438,	459, 478, 497, 515,	533, 550, 566],
                [0, 0, 0, 0, 0, 166, 214, 260, 301,	336, 368, 398, 425,	451, 475, 499, 521,	542, 563, 582, 601, 620],
                [0, 0, 0, 0, 0, 166, 214, 262, 309, 351, 388, 422, 453,	482, 510, 536, 561,	585, 608, 630, 651,	672],
                [0, 0, 0, 0, 0, 0, 214, 262, 313, 361, 403, 442, 477, 510, 541, 571, 598, 625, 651, 675, 699, 722],
                [0, 0, 0 ,0 ,0, 0, 213, 262, 277, 325, 375,	427, 482, 538, 597,	658, 720, 785, 851, 919, 989, 1060]
            ],
            three_valve: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [16, 28, 36, 42, 48, 53, 58, 62, 66, 70, 73, 76, 80, 83, 86, 89, 92, 94, 97, 100, 102, 105],
                [0, 45, 64,	78,	90,	101, 110, 119, 128,	135, 143, 150, 156,	163, 169, 175, 180,	186, 191, 197, 202,	207],
                [0, 48,	83,	107, 127, 144, 159,	173, 185, 197, 209,	219, 229, 239, 249,	258, 266, 275, 283,	291, 299, 306],
                [0, 0, 90, 128, 156, 180, 202, 221, 239, 255, 271, 285, 299, 313, 325, 338, 349, 361, 372, 383, 393, 403],
                [0, 0, 90, 138, 178, 211, 239, 264, 288, 309, 329, 348, 365, 382, 399, 414, 429, 444, 458, 472, 485, 498],
                [0, 0, 0, 138, 191,	234, 271, 303, 331,	358, 383, 406, 428,	449, 469, 488, 506,	524, 541, 558, 574, 590],
                [0, 0, 0, 138, 193,	250, 295, 335, 370,	403, 432, 460, 487,	512, 535, 558, 580,	601, 622, 641, 660,	679],
                [0, 0, 0, 0, 193, 255, 313,	361, 403, 442, 477,	510, 541, 571, 598,	625, 651, 675, 699,	722, 744, 766],
                [0, 0, 0, 0, 193, 255, 321,	380, 431, 476, 518,	556, 592, 626, 658,	688, 718, 746, 773,	799, 825, 849],
                [0, 0, 0, 0, 193, 255, 321, 391, 451, 504, 552,	597, 638, 677, 713,	748, 781, 813, 844,	874, 902, 930],
                [0, 0, 0, 0, 193, 255, 321, 392, 464, 526, 582,	633, 679, 723, 765,	804, 841, 877, 912,	945, 977, 1008],
                [0, 0, 0, 0, 0, 0, 321, 392, 469, 541, 605, 663, 716, 766, 812,	856, 898, 938, 976, 1013, 1048, 1083],
                [0, 0, 0, 0, 0, 0, 321, 392, 469, 541, 605, 663, 722, 807, 895, 986, 1080, 1177, 1276, 1378, 1483, 1590]
            ]
        },
        type: TYPE_FLOW.OVER
    },
    {
        group_id: "KKKXW2TW",
        Z: [118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132],
        a: [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 2.6, 2.8, 3.0, 3.2, 3.4, 3.6, 3.8, 4.0, 4.2, 4.4],
        Q : [
            [0, 0, 0, 0, 0,	0, 0, 0, 0,	0, 0, 0, 0,	0, 0],
            [5.3, 5.8, 6.2, 6.7, 7.1, 7.5, 7.8, 8.2, 8.5, 8.9, 9.2, 9.5, 9.8, 10.0, 10.3],
            [10.4, 11.4, 12.4, 13.3, 14.1, 14.9, 15.6, 16.3, 17.0, 17.6, 18.3, 18.9, 19.5, 20.0, 20.6],
            [15.4, 17.0, 18.4, 19.8, 21.0, 22.2, 23.3, 24.4, 25.4, 26.4, 27.3, 28.2, 29.1, 30.0, 30.8],
            [20.4, 22.5, 24.4, 26.2, 27.9, 29.4, 30.9, 32.3, 33.7, 35.0, 36.3, 37.5, 38.7, 39.8, 41.0],
            [25.2, 27.8, 30.3, 32.5, 34.6, 36.6, 38.5, 40.3, 42.0, 43.6, 45.2, 46.7, 48.2, 49.7, 51.1],
            [29.9, 33.1, 36.0, 38.8, 41.3, 43.7, 45.9, 48.1, 50.2, 52.2, 54.1, 55.9, 57.7, 59.4, 61.1],
            [34.5, 38.3, 41.7, 44.9, 47.9, 50.7, 53.3, 55.9, 58.3, 60.6, 62.9, 65.0, 67.1, 69.1, 71.1],
            [38.9, 43.3, 47.3, 51.0, 54.4, 57.6, 60.7, 63.6, 66.3, 69.0, 71.6, 74.1, 76.5, 78.8, 81.0],
            [43.3, 48.3, 52.8, 56.9, 60.8, 64.5, 67.9, 71.2, 74.3, 77.3, 80.2, 83.0, 85.7, 88.4, 90.9],
            [47.5, 53.1, 58.2, 62.8, 67.2, 71.2, 75.1, 78.8, 82.3, 85.6, 88.8, 92.0, 95.0, 97.9, 100.7],
            [51.6, 57.8, 63.4, 68.6, 73.4, 77.9, 82.2, 86.2, 90.1, 93.8, 97.4, 100.8, 104.2, 107.4, 110.5],
            [55.5, 62.4, 68.6, 74.3, 79.6, 84.5, 89.2, 93.6, 97.9, 101.9, 105.8, 109.6, 113.3, 116.8, 120.2],
            [59.4, 66.9, 73.7, 79.9, 85.7, 91.0, 96.1, 101.0, 105.6, 110.0, 114.3, 118.4, 122.3, 126.1, 129.9],
            [63.1, 71.3, 78.7, 85.4, 91.6, 97.5, 103.0, 108.2, 113.2, 118.0, 122.6, 127.0, 131.3, 135.4, 139.5],
            [66.6, 75.6, 83.5, 90.8, 97.5, 103.8, 109.8, 115.4, 120.8, 125.9, 130.9, 135.6, 140.2, 144.7, 149.0],
            [70.1, 79.7, 88.3, 96.1, 103.3, 110.1, 116.5, 122.5, 128.3, 133.8, 139.1, 144.2, 149.1, 153.9, 158.5],
            [73.3, 83.7, 92.9, 101.3, 109.1, 116.3, 123.1, 129.5, 135.7, 141.6, 147.2, 152.6, 157.9, 163.0, 167.9],
            [76.5, 87.6, 97.5, 106.4, 114.7, 122.4, 129.6, 136.5, 143.0, 149.3, 155.3, 161.1, 166.6, 172.0, 177.3],
            [79.4, 91.4, 101.9, 111.4, 120.2, 128.4, 136.1, 143.4, 150.3, 156.9, 163.3, 169.4, 175.3, 181.0, 186.6],
            [82.3, 95.0, 106.2, 116.3, 125.6, 134.3, 142.5, 150.2, 157.5, 164.5, 171.2, 177.7, 183.9, 190.0, 195.8],
            [84.9, 98.5, 110.4, 121.1, 131.0, 140.2, 148.8, 156.9, 164.6, 172.0, 179.1, 185.9, 192.5, 198.8, 205.0],
            [87.4, 101.8, 114.4, 125.8, 136.2, 145.9, 155.0, 163.5, 171.7, 179.4, 186.9, 194.1, 201.0, 207.6, 214.1]
        ],
        type: TYPE_FLOW.SEWER
    }
]

export class Compute {
    constructor(group_id, type=TYPE_FLOW.SEWER) {
        this.group_id = group_id;
        this.type = type;
        this.source = InterpolationSource.find(s => s.group_id === this.group_id && s.type === this.type);
    }

    /**
     * @param {number} n 
     * @param {number} xmin 
     * @param {number} xmax 
     * @param {number} ymin 
     * @param {number} ymax 
     * @param {number} [round] // optional round
     */
    #OneWay_Interpolation(n, xmin, xmax, ymin, ymax, round) {
        const r = (n - xmin) / (xmax - n);
        const f = (r * ymax + ymin) / (r + 1);
        const result = Math.round(f * round) / round;
        return round ? result : f;
    }

    /** get first and last element in array
     * @param {Array} array 
     */
    #Get_Elements(array) {
        if (Array.isArray(array) && array.length !== +0) {
            const start = array[0];
            const end = array.slice(-1)[0];
            return [start, end];
        }
    }

    #Find_Elements(array, value) {
        let index = -1;
        if (Array.isArray(array) && array.length !== +0) {
            for (let i = +0; i < array.length; i++) {
                if (value <= array[i]) {
                    index = i;
                    break;
                }
            }
        }
        return index;
    }
    /**
     * @param {number} WAU 
     */
    CalculateWaterIndex(WAU) {
        const source = this.source;
        if (!source) {
            return +0;
        }
        const Z_Min = Math.floor(WAU) + 0.5;
        const Z_Max = Z_Min + 0.5;
        if (source.Z.indexOf(Z_Min) !== -1) {
            if (source.Z.indexOf(Z_Min) === source.Z.length - 1) {
                return [...source.V].pop();
            }
            const V_Min = source.V[source.Z.indexOf(Z_Min)];
            const V_Max = source.V[source.Z.indexOf(Z_Max)];
            const f = this.#OneWay_Interpolation(WAU, Z_Min, Z_Max, V_Min, V_Max, 1000);
            return (f * source.ratio) / 10e6;
        }
        return +0;
    }

    /** lưu lượng qua cống ( lưu lượng xả )
     * @param {number} WAU 
     * @param {number} DR 
     * @param {string} option // quantity valve: one_valve, two_valve, three_valve
     */
    CalculateWaterFlow(WAU, DR, option) {
        const source = this.source;
        if (!source) {
            return +0;
        }

        let { Z, a } = source;
        const [a_start, a_end] = this.#Get_Elements(a);
        const [Z_start, Z_end] = this.#Get_Elements(Z);
        
        let Q = [];
        if (option && source.Q_of) {
            Q = source.Q_of[option]
        } 
        if (source.type === TYPE_FLOW.SEWER) {
            Q = source.Q;
        }
        if (a.includes(DR)) {
            const indexA = a.indexOf(DR);
            const Q_compute = Q[indexA];
            if (Z.includes(WAU)) {
                const indexZ = Z.indexOf(WAU);
                return Q_compute[indexZ];
            } else {
                if (WAU >= Z_start && WAU <= Z_end) {
                    const indexZ = this.#Find_Elements(Z, WAU);
                    const Z_Min = Z[indexZ - 1];
                    const Z_Max = Z[indexZ];
                    return this.#OneWay_Interpolation(WAU, Z_Min, Z_Max, Q_compute[Z.indexOf(Z_Min)], Q_compute[Z.indexOf(Z_Max)], 100);
                }
                return `Mực nước hồ nội suy trong khoảng ${Z_start}m-${Z_end}m`;
            }
        } else {
            if (DR >= a_start && DR <= a_end) { 
                const indexA = this.#Find_Elements(a, DR);
                const a_min = a[indexA - 1];
                const a_max = a[indexA];
                if (Z.includes(WAU)) {
                    const indexZ = Z.indexOf(WAU);
                    return this.#OneWay_Interpolation(DR, a_min, a_max, Q[indexA - 1][indexZ], Q[indexA][indexZ], 100);
                } else {
                    if (WAU >= Z_start && WAU <= Z_end) {
                        const indexZ = this.#Find_Elements(Z, WAU);
                        const Z_Min = Z[indexZ - 1];
                        const Z_Max = Z[indexZ];
                        const arrQ1 = Q[indexA - 1];
                        const arrQ2 = Q[indexA];
                        if (arrQ1 && arrQ2) {
                            const Q_min = [
                                arrQ1.find((_, i) => i === indexZ - 1),
                                arrQ2.find((_, i) => i === indexZ - 1)
                            ];
                            const Q_max = [
                                arrQ1.find((_, i) => i === indexZ),
                                arrQ2.find((_, i) => i === indexZ)
                            ];
                            const Q_avg = [
                                this.#OneWay_Interpolation(DR, a_min, a_max, Q_min[0], Q_min[1], 100),
                                this.#OneWay_Interpolation(DR, a_min, a_max, Q_max[0], Q_max[1], 100)
                            ]
                            const result = this.#OneWay_Interpolation(WAU, Z_Min, Z_Max, Q_avg[0], Q_avg[1], 100);
                            return result;
                        } 
                    }
                    return `Mực nước hồ nội suy trong khoảng ${Z_start}m-${Z_end}m`;
                }    
            }
        }
    }

    /** lưu lượng qua tràn theo công thức
     * @param {number} WAU 
     */
    CalculateWaterOverFlow(WAU) {
        const source = this.source;
        if (source) {
            return m * this.source.B * Math.sqrt(2 * g) * (WAU ** 1.5);
        }
        return +0;
    }

    /**  độ mở cống
     * @param {number} Q 
     * @param {number} WAU
     */
    CalculateDrain(Q, WAU, option) {
        const source = this.source;
        if (!source) {
            return +0;
        }

        const { Z, a } = source;

        let Z_compute = +0;
        if ((WAU * 10) % 10 === +5) {
            Z_compute = WAU;
        } else {
            Z_compute = Math.round(WAU);
        }
        const Z_index = Z.indexOf(Z_compute);
        if (Z_index !== -1) {
            let arrQ = [];
            if (source.type === TYPE_FLOW.SEWER) {
                arrQ = source.Q.map(el => el[Z_index]);
            }
            if (option && source.Q_of) {
                arrQ = source.Q_of[option].map(el => el[Z_index]);
            } 
            let indexQ = +0;
            const [first, last] = [arrQ[0], [...arrQ].pop()];

            if (Q >= first && Q <= last) {
                if (Q === first) {
                    return a[0];
                } else if (Q === last) {
                    return a[a.length - 1];
                } else {
                    for (let i = +0; i < arrQ.length; i++) {
                        if (Q < arrQ[i]) {
                            indexQ = i;
                            break;
                        }
                    }
                }
            }
            return this.#OneWay_Interpolation(Q, arrQ[indexQ - 1], arrQ[indexQ], a[indexQ - 1], a[indexQ], 100);
        }
        return +0;
    }
}


