import { useState, useEffect } from "react";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Button } from "@mui/material";
import ModalConfirm from "../../../../Components/ModalConfirm/ModalConfirm";

const group_dot1 = '79R4HBQI';
const group_dot2 = 'UUAF4RA4';

const getUrl = (group_id, action) => {
    if (!group_id || !action) return;
    const doc = {
        check: `https://tp-support.deno.dev/v1/check/${group_id}?access_token=thuanphong:box:9MwEeIceSrA3CHCNVtU8tQA`,
        on: `https://tp-support.deno.dev/v1/setting/${group_id}?access_token=thuanphong:box:9MwEeIceSrA3CHCNVtU8tQA&&mode=manual&&action=on`,
        off: `https://tp-support.deno.dev/v1/setting/${group_id}?access_token=thuanphong:box:9MwEeIceSrA3CHCNVtU8tQA&&mode=manual&&action=off`
    }
    return doc[action]
}

const HelpDOT = () => {
    const [modal, setModal] = useState({
        open: false,
        title: 'Thông báo',
        message: ''
    })
    const [dot1, setDot1] = useState(0)
    const [dot2, setDot2] = useState(0)
    const [current, setCurrent] = useState({
        group_id: '',
        action: ''
    })

    async function answer(isYes) {
        if (isYes) {
            const url = getUrl(current.group_id, current.action);
            const response = await fetch(url);
            const value = await response.json();
            if (current.group_id === group_dot1) setDot1(+value);
            else if (current.group_id === group_dot2) setDot2(+value);

            setModal({...modal, open: false, message: ''});
            setCurrent({...current, group_id: '', action: ''})
        }
        else {
            setModal({...modal, open: false, message: ''});
            setCurrent({...current, group_id: '', action: ''})
        }
    }

    function handleVolume(group_id, action) {
        const mAction = action === 'on' ? 'bật' : 'tắt';
        const mGroup = group_id === group_dot1 ? '1' : '2'
        setModal({
            ...modal,
            open: true,
            message: 'Xác nhận ' + mAction + ' loa Đồi Ông Tượng ' + mGroup
        });
        setCurrent({ ...current, group_id, action })
    }

    useEffect(async () => {
        const url = getUrl(group_dot1, 'check');
        const response = await fetch(url);
        const value = await response.json();
        setDot1(value)
    }, [])

    useEffect(async () => {
        const url = getUrl(group_dot2, 'check');
        const response = await fetch(url);
        const value = await response.json();
        setDot2(value)
    }, [])

    return (
        <>
            <ModalConfirm modal={modal} functionCallBack={answer} />
            <div className="HelpDOT flex pt-30">
                <div className="pr-150">
                    <h2 className="pl-30" > Đồi Ông Tượng 1</h2>
                    <h3 className="pl-30 pt-20">Trạng thái: {dot1 === 1 ? <span style={{ color: 'green', fontWeight: 'bold' }}>Bật <VolumeUpIcon /></span> : <span >Tắt <VolumeOffIcon /></span>}</h3>
                    <h3 className="pl-50 pt-50" >
                        <Button variant="contained" onClick={e => handleVolume(group_dot1, 'on')}>Bật Loa Cảnh Báo </Button>
                    </h3>
                    <h3 className="pl-50">
                        <Button variant="contained" onClick={e => handleVolume(group_dot1, 'off')}>Tắt Loa Cảnh Báo</Button>
                    </h3>
                </div>
                <div className="">
                    <h2 className="pl-30" > Đồi Ông Tượng 2</h2>
                    <h3 className="pl-30 pt-20">Trạng thái: {dot2 === 1 ? <span style={{ color: 'green', fontWeight: 'bold' }}>Bật <VolumeUpIcon /></span> : <span >Tắt <VolumeOffIcon /></span>}</h3>
                    <h3 className="pl-50 pt-50" >
                        <Button variant="contained" onClick={e => handleVolume(group_dot2, 'on')}>Bật Loa Cảnh Báo </Button>
                    </h3>
                    <h3 className="pl-50">
                        <Button variant="contained" onClick={e => handleVolume(group_dot2, 'off')}>Tắt Loa Cảnh Báo</Button>
                    </h3>
                </div>
            </div>
        </>
    )
}

export default HelpDOT;