import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertConfirm({ open, message, severity, handleClose}) {

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar 
            open={open} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center'}} 
            key={'top' + 'center'}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', alignItems: 'center'}}>
                {message}
                <Button className='ml-20' variant='contained' color="warning" onClick={handleClose}>Tắt</Button>
            </Alert>
        </Snackbar>
    </Stack>
  );
}