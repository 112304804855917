import {
  format,
  startOfDay,
  endOfDay
} from "date-fns";
import {
  Http
} from "../../../../Services/http";
import {
  BASE_URL,
  BOXGROUP_ID
} from "../../../../Utils/const";
import {
  CHECK_EMPTY_STRING
} from "../../../../Utils/string";

const API_ENDPOINT = {
  GET_RECORD_BY_BOX: "sensor/box?id=",
  EXPORT_CSV: "sensor/box/export_excel?id=",
  GET_RECORD_BY_GROUP: "sensor/group?id="
}
// return `${api_base_url}/sensor/box/export_csv?id=`;
class MonitorService {
  constructor() {
    if (MonitorService._instance) {
      return MonitorService._instance;
    }
    MonitorService._instance = this;
  }
  getRecordByGroup(id) {
    const boxGroupID = BOXGROUP_ID;
    return Http.get(`${API_ENDPOINT.GET_RECORD_BY_GROUP}${boxGroupID}`)
  }
  getRecordByBox(id, time) {
    const [min_ts, max_ts] = [
      startOfDay(new Date(time.start).getTime()).getTime(),
      endOfDay(new Date(time.end).getTime()).getTime()
    ]
    if (!CHECK_EMPTY_STRING(id)) {
      return Http.get(`${API_ENDPOINT.GET_RECORD_BY_BOX}${id}&min_ts=${min_ts}&max_ts=${max_ts}`);
    }
  }

  exportCSV(id, time) {
    const [start_date, end_date] = [
      format(new Date(time.start).getTime(), "yyyy/MM/dd"),
      format(new Date(time.end).getTime(), "yyyy/MM/dd")
    ]
    return `${BASE_URL}${API_ENDPOINT.EXPORT_CSV}${id}&start_date=${start_date}&end_date=${end_date}`
  }

  
}

const monitorService = new MonitorService();

export default monitorService;