import { InterpolationSource } from "./data";
/**
 * @param {number} z 
 */

function findPositionBeforeAfter(value, arr) {
    let result;
    let pos1;
    let pos2;
    arr.forEach((a, i) => {
        const minus = value - a;
        if (a <= value && (!result || minus < result)) {
            result = minus;
            pos1 = i;
            pos2 = i + 1;
        }
    })
    return [pos1, pos2]
}

function OneWayInterpolation(value, arr1, arr2) {
    const [x1, x2] = findPositionBeforeAfter(value, arr1);
    const h1 = value - arr1[x1];
    const h2 = arr1[x2] - value;
    const result = (h1 * arr2[x2] + h2 * arr2[x1]) / (h1 + h2);
    return result
}

export function CalculateVolume(z, group_id = "KKKXW2TW") {
    if(isNaN(z)) return 0;
    const x0 = Math.round(z * 100) / 100;
    const integer = Math.floor(x0);
    const mod = Math.floor((x0 % 1) * 10);
    const currentInterpol = InterpolationSource.find(p => p.group_id === group_id)
    if(+z === 0 || isNaN(z) || !currentInterpol) return;
    if (group_id === 'KKKXW2TW') {
        const x1 = currentInterpol.V[integer][mod]
        const x2 = currentInterpol.V[integer][mod + 1]
        const h0 = Math.round((z - integer) * 100) / 100;
        const h1 = Math.round((h0 * 10 - mod) * 10) / 100;
        const h2 = Math.round((mod + 1 - h0 * 10) * 10) / 100;
        const x = (h1 * x2 + h2 * x1) / (h1 + h2)
        return Math.round(x * 100) / 100;
    } else {
        const x = OneWayInterpolation(z, currentInterpol.V.z, currentInterpol.V.value);
        return Math.round(x * 100) / 100;
    }
}
