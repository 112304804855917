import { useEffect, useState } from "react";
import { CalculateKG } from "./hypothesis_data";

const Hypothesis_KG = (props) => {
    const [rain, setRain] = useState()
    const [Zhl, setZhl] = useState()
    const [doc, setDoc] = useState({})

    function onChangeRain(e) {
        const { value } = e.target
        setRain(value)
    }

    useEffect(() => {
        if (!props.z || Zhl) return;
        setZhl(props.z);
    }, [props])

    useEffect(() => {
        if(!rain || !Zhl) return;
        const result = CalculateKG(rain, Zhl)
        if(result.Z3h) props.setZtl3(result.Z3h)
        setDoc({...result})
    }, [rain, Zhl])

    return (
        <>
            <div className="hypothesis">
                <div className="title pt-20">
                    THÔNG SỐ GIẢ ĐỊNH
                </div>
                <div className="note">
                    <p>Thông số nhập</p>
                    <p>Thông số tính toán</p>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Lượng mưa 3h (mm)</p>
                        <input name="rain" className="value" onChange={onChangeRain} value={rain} />
                    </div>
                    <div className="item">
                        <p className="label">Lưu lượng 3h tới (m3/s)</p>
                        <input className="value" name="Ztl" value={doc.Q3h} />
                    </div>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Mực nước hồ (m)</p>
                        <input name="rain" className="value" onChange={e => setZhl(e.target.value)} value={Zhl} />
                    </div>
                    <div className="item">
                        <p className="label">Mực nước hồ 3h tới (m)</p>
                        <input name="z3" className="value" value={doc.Z3h} />
                    </div>

                </div>
                <div className="content">
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Tổng lượng dòng chảy 3h tới (10^6m3)</p>
                        <input name="z3" className="value" value={doc.W3h} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hypothesis_KG;