import { useState } from "react";
import { CalculateDrain, CalculateFlow } from "../../../../Components/Interpolation/spin";

const HelpSpin = (props) => {
    const [q, setQ] = useState();
    const [dr, setDr] = useState();

    function onChangeDr(e) {
        const { number, z } = props;

        const dr_value = e.target.value;
        setDr(dr_value);

        if (dr_value) {
            const q_value = CalculateFlow(number, z, dr_value)
            if (Number.isFinite(q_value)) {
                setQ(q_value)
            }
            else {
                setQ("")
            }
        } else {
            setQ("")
        }
    }

    function onChangeQ(e) {
        const { number, z } = props;

        const q_value = e.target.value;
        setQ(q_value);

        if (q_value) {
            const dr_value = CalculateDrain(number, z, q_value)
            if (Number.isFinite(dr_value)) {
                setDr(dr_value)
            }
            else {
                setDr("")
            }
        } else {
            setDr("")
        }
    }

    return (
        <>
            <div className="item">
                <div className="label">
                    Trường hợp vận hành {props.number} cửa van
                </div>
                <div className="input">
                    <div className="luu-luong">
                        Nhập lưu lượng
                        <input value={q} onChange={(e) => onChangeQ(e)} type="text" />
                        m3/s
                    </div>
                    <div className="do-mo-van">
                        Độ mở van tính toán
                        <input value={dr} onChange={(e) => onChangeDr(e)} type="text" />
                        m
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpSpin;