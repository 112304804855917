import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import CustomLoading from './../../../Components/Loading/CustomLoading';
import CustomPagination from '../../../Components/Pagination/Pagination';
import { useState, Children } from 'react';

function ConvertBoxName(string) {
    if (string.includes("-")) {
        const array = string.split("-");
        return array.length >= 2 ? array[1] : array[0];
    }
    return string;
}

const Zhl = ({dataZhl, isLoading}) => {
    const [page, setPage] = useState(+0);
    const [rowsPerPage, setRowsPerPage] = useState(+15);

    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
    };


    let records = [];
    let headers = [];

    if (dataZhl && dataZhl.length !== +0) {
        const newData = JSON.parse(JSON.stringify(dataZhl));
        newData.sort((a, b) => b.count - a.count);
        const date = newData[0].records.map(r => r.t);
        records = date.map(t => {
            let obj = dataZhl.reduce((result, dt) => {
                const r = dt.records.find(r => r.t === t);
                return r ? { ...result, [ConvertBoxName(dt.box.name)]: r.WAD} : { ...result, [dt.box.name]: +0 }
            }, { "Thời gian": format(t * 10e2, "dd/MM/yyyy - HH:mm") });
            return obj;
        })
        if(records.length > 0) headers = Object.keys(records[0]);
    }

    return(
        <>
            {/* <Grid container sx={{ pt: "0.5%", height: "42px" }} /> */}
            <TableContainer className="table-listdata" >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            { !isLoading ? headers.length !== +0 && Children.toArray(headers.map((h, i) => {
                                return i === +0 ?
                                    <>
                                        <TableCell align='left' style={{ width: 100 }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: 'uppercase',
                                                    fontSize: 13,
                                                    color: "#606060",
                                                }}
                                            >
                                                {h}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    : <>
                                        <TableCell align='right' style={{ width: Math.ceil(100 / headers.length) }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: 'uppercase',
                                                    fontSize: 13,
                                                    color: "#606060",
                                                }}
                                            >
                                                {h}
                                            </Typography>
                                        </TableCell>
                                    </>
                            })) : <CustomLoading numbers={2} />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading ? Children.toArray(records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(dt => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                >
                                    {Object.values(dt).map((v, i) => {
                                        return i === +0 ?
                                            <>
                                                <TableCell align='left' component="th" scope="row" style={{ width: "35%" }}>
                                                    <Typography
                                                        sx={{
                                                            textTransform: 'uppercase',
                                                            fontWeight: 600,
                                                            fontSize: 16,
                                                            color: "#444444",
                                                        }}
                                                    >
                                                        {v}
                                                    </Typography>
                                                </TableCell>
                                            </> : <>
                                                <TableCell align="right">
                                                    <Typography
                                                        sx={{
                                                            textTransform: 'uppercase',
                                                            fontWeight: 600,
                                                            fontSize: 16,
                                                            color: "#444444"
                                                        }}
                                                    >
                                                        {v}
                                                    </Typography>
                                                </TableCell>
                                            </>
                                    })}
                                </TableRow>
                            )
                        })) : <CustomLoading numbers={2} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container sx={{ p: "1%", ml: "1%" }}>
                <CustomPagination
                    data={records}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    parentCallBack={handleChangePage}
                />
            </Grid>
        </>
    )
}

export default Zhl;