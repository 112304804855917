import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { Tabs, Tab  } from "@mui/material";


import zoneService from'../../Zone/Shared/ZoneService';
import groupService from '../Shared/GroupService';


import { CHECK_EMPTY_STRING } from '../../../../Utils/string';
import { checkNumber } from '../../../../Utils/number';
import { ResetForm } from '../../../../Utils/helper';
import ModalNoti from '../../../Components/ModalNoti/ModalNoti';

const DetailGroup = () => {
    let str = '/app/group/';
    let url = window.location.pathname;
    let id = url.slice(str.length)
    const [tabID, setTabID] = useState(+0);
    const [boxGroup, setBoxGroup] = useState({});
    const [listZone, setListZone] = useState([]);
    const [modalNoti, setModalNoti] = useState({
        open: false,
        title: "",
        message: ""
    });
    const [validate, setValidate] = useState({
        name: false, 
        zone_id: false, 
        lat: false,
        lng: false,
        zoom: false,
        message: {
            name: "",
            zone_id: "",
            lat: "",
            lng: "",
            zoom: ""
        }
    });
    useEffect(() => {
        groupService.getOneBoxGroup(id).then(res => setBoxGroup(res.data))
            .catch(err => console.log(err))
        zoneService.getListZone().then(res => {
            const listZoneOption = res.data.map(option => {
                return {
                    label: option.name,
                    value: option.id
                }
            })
            setListZone(listZoneOption)
        })
            .catch(err => console.log(err))
    }, [id])

    const handleChange = (event, newValue) => {
        setTabID(newValue);
    };
    const funcResetValidate = () => {
        const resetValidate = {
            name: false, 
            zone_id: false, 
            lat: false,
            lng: false,
            zoom: false,
            message: {
                name: "",
                zone_id: "",
                lat: "",
                lng: "",
                zoom: ""
            }
        }
        setValidate(resetValidate);
    }
    const validateCreate = (e, option) => {
        if(e.target.name === "name"){
            if(option === "focus") {
                setValidate({
                    ...validate,
                    name: false
                })
            }
            else {
                setValidate({
                        ...validate,
                        message: {
                            ...validate.message,
                            name: CHECK_EMPTY_STRING(boxGroup.name) ? "Không được bỏ trống trường tên công trình!": ""
                        }, 
                        name: CHECK_EMPTY_STRING(boxGroup.name)
                })
            }
        } 
        if(e.target.name === "zone_id"){
            if(option === "focus") {
                setValidate({...validate, zone_id: false})

            }
            else {
                setValidate({
                        ...validate,
                        message: {
                            ...validate.message,
                            zone_id:  CHECK_EMPTY_STRING(boxGroup.zone_id) ? "Vui lòng lựa chọn khu vực!": ""
                        }, 
                        zone_id: CHECK_EMPTY_STRING(boxGroup.zone_id)
                })
                
            }
        } 
        if(e.target.name === "zoom"){
            if(option === "focus") {
                setValidate({...validate, zoom: false})

            }
            else {
                if(CHECK_EMPTY_STRING(boxGroup.zoom)) {
                    setValidate({
                        ...validate, 
                        zoom: true,
                        message: {
                            ...validate.message,
                            zoom: "Không được bỏ trống độ phóng to!"
                        }, 
                    })
                }
                else if(checkNumber(boxGroup.zoom)){
                    setValidate({
                        ...validate,
                        zoom: true,
                        message: {
                            ...validate.message,
                            zoom: "Mức độ phóng to phải là dạng số!"
                        }, 
                    })
                }
                else{
                    setValidate({
                        ...validate,
                        zoom: false,
                        message: {
                            ...validate.message,
                            zoom: ""
                        }, 
                    })
                }
                
            }
        } 
        if(e.target.name === "lat"){
            if(option === "focus") {
                setValidate({...validate, lat: false})
            }
            else {
                if(CHECK_EMPTY_STRING(boxGroup.center.lat)) {
                    setValidate({
                        ...validate, 
                        lat: true,
                        message: {
                            ...validate.message,
                            lat: "Không được bỏ trống kinh độ!"
                        }, 
                    })
                }
                else if(checkNumber(boxGroup.center.lat)){
                    setValidate({
                        ...validate,
                        lat: true,
                        message: {
                            ...validate.message,
                            lat: "Kinh độ phải là dạng số!"
                        }, 
                    })
                }
                else{
                    setValidate({
                        ...validate,
                        lat: false,
                        message: {
                            ...validate.message,
                            lat: ""
                        }, 
                    })
                }
                
            } 
        } 
        if(e.target.name === "lng"){
            if(option === "focus") {
                setValidate({...validate,
                    lng: false
                })

            }
            else {
                if(CHECK_EMPTY_STRING(boxGroup.center.lng)) {
                    setValidate({
                        ...validate, 
                        lng: true,
                        message: {
                            ...validate.message,
                            lng: "Không được bỏ trống vĩ độ!"
                        }, 
                    })
                }
                else if(checkNumber(boxGroup.center.lng)){
                    setValidate({
                        ...validate,
                        lng: true,
                        message: {
                            ...validate.message,
                            lng: "Vĩ độ phải là dạng số!"
                        }, 
                    })
                }
                else {
                    setValidate({
                        ...validate,
                        lng: false,
                        message: {
                            ...validate.message,
                            lng: ""
                        }, 
                    })
                }
            }
        } 
    }
    const onChangeFieldGroup = (e) => {
        const {name, value} = e.target;
        setBoxGroup({...boxGroup, [name]: value})
        if(e.target.name === "lat" || e.target.name === "lng") {
            setBoxGroup({
                ...boxGroup,
                center: { 
                    ...boxGroup.center,
                    [name]: value
                }
            })
        }
    }
    const done = () => {
        const resetModal = ResetForm(modalNoti)
        setModalNoti(resetModal)
    }
    return (
        <div>
            {Object.keys(boxGroup).length !== +0 ?
            <Paper sx={{ overflow: 'hidden'}} className="detail-group" elevation={8}>
                <Grid  sx={{ height: "5vh", mt: "29px", pr: 5, ml: "60px" }} container>
                    <Tabs value={tabID} onChange={handleChange} className="mb-50">
                        <Tab label="Tổng quan" />
                        <Tab label="Thông tin chi tiết" />
                    </Tabs>
                </Grid>
                <Box sx={{ height: "70.4vh", mt: "57px", ml: "60px", width: "40%" }}>
                    <Grid >
                        Tên công trình*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField 
                            error = {validate.name}
                            onBlur={(e)=> validateCreate(e, "blur")}
                            onFocus={(e)=> validateCreate(e, "focus")}
                            name="name" 
                            value={boxGroup.name}
                            onChange={onChangeFieldGroup} 
                            fullWidth 
                            label="Tên công trình" 
                            placeholder='Nhập tên công trình'
                        />
                        {validate.name && <p className="color-red">{validate.message.name}</p>}
                    </Grid>
                    <Grid>
                        Thuộc khu vực*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2 }}>
                        <TextField 
                            select
                            error = {validate.zone_id}
                            onBlur={(e)=> validateCreate(e, "blur")}
                            onFocus={(e)=> validateCreate(e, "focus")}
                            name="zone_id" 
                            value={boxGroup.zone_id} 
                            onChange={(e)=>onChangeFieldGroup(e)} 
                            fullWidth 
                            label="Khu vực" 
                        >
                            {listZone.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validate.zone_id && <p className="color-red">{validate.message.zone_id}</p>}
                    </Grid>
                    <Grid>
                        Vị trí trung tâm*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2  }} container>
                        <Grid item xs={5} >
                            <TextField 
                                error = {validate.lat}
                                onBlur={(e)=> validateCreate(e, "blur")}
                                onFocus={(e)=> validateCreate(e, "focus")}
                                onChange={onChangeFieldGroup} 
                                name="lat" 
                                fullWidth 
                                label='Kinh độ'
                                value={boxGroup.center.lat}
                            />
                            {validate.lat && <p className="color-red">{validate.message.lat}</p>}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                            <TextField 
                                error = {validate.lng}
                                value={boxGroup.center.lng}
                                onBlur={(e)=> validateCreate(e, "blur")}
                                onFocus={(e)=> validateCreate(e, "focus")}
                                fullWidth  
                                label='Vĩ độ'
                                onChange={onChangeFieldGroup} 
                                name="lng" 
                            />
                            {validate.lng && <p className="color-red">{validate.message.lng}</p>}
                        </Grid>
                    </Grid>
                    <Grid>
                        Mức phóng to*:
                    </Grid>
                    <Grid sx={{ pt: 2, pb: 2  }}>
                        <TextField 
                            error={validate.zoom}
                            name="zoom" 
                            value={boxGroup.zoom} 
                            onChange={onChangeFieldGroup} 
                            fullWidth 
                            label="Độ phóng" 
                            placeholder='Nhập mức độ phóng to'
                            onBlur={(e)=> validateCreate(e, "blur")}
                            onFocus={(e)=> validateCreate(e, "focus")}
                        />
                        {validate.zoom && <p className="color-red">{validate.message.zoom}</p>}
                    </Grid>
                    <Grid item xs={5} sx={{textAlign : "end"}}>
                        <Button sx={{background: "#E5E5E5", color: "black"}} className='button-wh' variant="contained">Huỷ</Button>
                        <Button type="submit" className='button-wh ml-50' 
                                disabled={[validate.name, validate.zone_id, validate.zoom, validate.lat, validate.lng].includes(true)} variant="contained"
                            >Lưu</Button>
                    </Grid>
                </Box>     
            </Paper>
            : <div></div>}
        </div>
    )
}


export default DetailGroup;