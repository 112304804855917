import * as React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export default function ModalNoti(props) {
    const { modal, done } = props;

    return (
        <>
            <Popover
                open={modal.open}
                onClose={done}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
            >
                <div style={{ display: "flex", justifyContent: "end"}}>
                    <HighlightOffOutlinedIcon style={{color: '#ff4d4f', cursor: 'pointer',}} />
                </div>
                <Typography variant="h6" sx={{ pb: 3, pr: 2, pl: 2 }}><ErrorIcon style={{color: '#faad14'}}/> {modal.title} {modal.message}</Typography>
                {modal?.message ?
                    <Typography variant="string" sx={{ p: 2 }}>{modal.message}</Typography>
                    : null
                }
                
            </Popover>
        </>
    );
}