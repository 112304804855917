import { Http } from "../../../../Services/http";
import { BOXGROUP_ID } from "../../../../Utils/const";

const API_ENDPOINT = {
  GET_RECORD_GROUP: "sensor/group?id=",

}

class HelpService {
  constructor() {
    if (HelpService._instance) {
      return HelpService._instance;
    }
    HelpService._instance = this;
  }

  getRecordGroup(id = BOXGROUP_ID) {
    return Http.get(API_ENDPOINT.GET_RECORD_GROUP + id);
  }


}

const instance = new HelpService();

export default instance;