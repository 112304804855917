import { Paper, Typography, Grid } from "@mui/material";
import { endOfHour, startOfDay, startOfHour, endOfDay } from "date-fns";
import { Children } from "react";
import { RoundValue } from "../../../../../Utils/number";
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar";
import { BOXGROUP_ID, ID_GROUPS } from "../../../../../Utils/const";

const TIMERANGE = {
    HOUR: "hour",
    DAY: "day"
}

const Statistical = (props) => {
    const { data } = props;
    const generatorDataTimeRange = (box_id, timeRange) => {
        const now = Date.now();
        let time = [+0, +0];
        const box = data.find(d => d.box.id === box_id);
        if (!box) {
            return;
        }
        const { records } = box;
        if (timeRange === TIMERANGE.DAY) {
            const start = startOfDay(now).getTime();
            const end = endOfDay(now).getTime() - 1;
            time = [start, end];
        }
        if (timeRange === TIMERANGE.HOUR) {
            const start = startOfHour(now).getTime();
            const end = endOfHour(now).getTime();
            time = [start, end];
        }
        const r = records.filter(r => r.t * 10e2 >= time[0] && r.t * 10e2 <= time[1]);
        const total = RoundValue(r.reduce((total, r) => total += r["RA"], +0));
        return {
            total: total,
            data: r
        }
    }

    const computeTotalRainValue = (data, timeRange) => {
        const total = data.reduce((total, obj) => {
            const box_id = obj.box.id;
            return total += generatorDataTimeRange(box_id, timeRange).total;
        }, +0)
        return + RoundValue(total);
    }

    return (
        <>
            <Paper
                className="Statistical-hour"
                elevation={8}
                sx={{
                    borderRadius: '5px',
                    border: "1.5px solid ",
                    color: '#0000001f',
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    height: {lg: '46vh'},
                    paddingBottom: {xs: '20px', lg: '0px'}
                }}
            >
                <div className="middle div-title">
                    <Typography
                        className="title"
                        sx={{
                            fontSize: {xs: '16px', md: '18px'},
                            marginBottom: { xs: '5px', md: '15px' }
                        }}
                    >
                        Dữ liệu lượng mưa trong giờ (mm)
                    </Typography>
                </div>
                <div className="pl-33">
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography
                                className="total"
                                sx={{
                                    fontSize: {xs: '16px', md: '18px'}
                                }}
                            >
                                TỔNG:
                            </Typography>
                        </Grid>
                        <Grid
                            item xs={9}
                            className="end pr-35"
                        >
                            <Typography
                                className="total"
                                sx={{
                                    fontSize: {xs: '22px', md: '36px'}
                                }}
                            >
                                {computeTotalRainValue(data, TIMERANGE.HOUR)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                {Children.toArray(data.map((d) => {
                    return (
                        <div className={ID_GROUPS.DOT === BOXGROUP_ID ? "pl-33 flex item" : "pl-33 item"}>
                            <Typography
                                sx={{
                                    color: "#EA4335",
                                }}
                                className="pb-8 fontsz-14 label"
                            >
                                {d.box.name.includes("-") ? d.box.name.split("-")[1] : d.box.name}:
                            </Typography>
                            <ProgressBar bgColor="#4285F4" completed={generatorDataTimeRange(d.box.id, TIMERANGE.HOUR).total} value={computeTotalRainValue(data, TIMERANGE.HOUR)} />
                        </div>
                    )
                }))}

            </Paper>
            {/* <Grid container sx={{ height: "0.4vh" }} /> */}
            <Paper
                className="Statistical-day"
                elevation={8}
                sx={{
                    borderRadius: '5px',
                    borderLeft: "1.5px solid",
                    borderRight: "1.5px solid",
                    borderTop: "1.5px solid",
                    borderBottom: "3px solid ",
                    color: '#0000001f',
                    boxShadow: "none",
                    width: "100%%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    // marginTop: { xs: '20px', md: '0px' },
                    height: {lg: '46vh'},
                    paddingBottom: {xs: '20px', lg: '0px'},
                    marginTop: { xs: '20px', lg: '0px' },
                }}
            >
                <div className="middle div-title">
                    <Typography
                        className="title"
                        sx={{
                            fontSize: {xs: '16px', md: '18px'},
                            marginBottom: { xs: '5px', md: '15px' }
                        }}
                    >
                        Dữ liệu lượng mưa trong ngày (mm)
                    </Typography>
                </div>
                <div className="pl-33">
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography
                                className="total"
                                // color="#4285F4"
                                sx={{
                                    fontSize: {xs: '16px', md: '18px'}
                                }}
                            >
                                TỔNG:
                            </Typography>
                        </Grid>
                        <Grid
                            item xs={9}
                            className="end pr-35"
                        >
                            <Typography
                                className="total"
                                sx={{
                                    fontSize: {xs: '22px', md: '36px'}
                                }}
                            >
                                { computeTotalRainValue(data, TIMERANGE.DAY) }
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                {Children.toArray(data.map((d) => {
                    return (
                        <div className={ID_GROUPS.DOT === BOXGROUP_ID ? "pl-33 flex item" : "pl-33 item"}>
                            <Typography
                                sx={{
                                    color: "#EA4335",
                                }}
                                className="pb-8 fontsz-14 label"
                            >
                                {d.box.name.includes("-") ? d.box.name.split("-")[1] : d.box.name}:
                            </Typography>
                            <ProgressBar bgColor="#34A853" completed={generatorDataTimeRange(d.box.id, TIMERANGE.DAY).total} value={computeTotalRainValue(data, TIMERANGE.DAY)} />
                        </div>
                    )
                }))}
            </Paper>
        </>
    )
}

export default Statistical;