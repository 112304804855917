import { Http } from "../../../../Services/http";

const API_ENDPOINT = {
    CREATE_BOX_GROUP: "zone/box_group/create",
    GET_ONE_BOX_GROUP: "zone/box_group/get"
}

class GroupService {
    constructor() {
      if (GroupService._instance) {
        return GroupService._instance;
      }
      GroupService._instance = this;
    }
    
    
    createBoxGroup(data) {
      return Http.post(API_ENDPOINT.CREATE_BOX_GROUP, data)
    }
    getOneBoxGroup(id) {
        return Http.get(`${API_ENDPOINT.GET_ONE_BOX_GROUP}?id=${id}`)
    }

  }
  
  const groupService = new GroupService();
  
  export default groupService;
  