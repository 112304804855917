import { useEffect, useState, Children } from "react";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../../Components/Chart/Component/chart_data";
import monitorService from "../../Monitor/Shared/MonitorService";
import LineChart from "../../../Components/Chart/Component/LineChart";
import BarChart from "../../../Components/Chart/Component/BarChart";
import MultiLineChart from "../../../Components/Chart/Component/MultiLineChart";
import { getDate, getHours, getMinutes, getMonth } from "date-fns";
import SectionView from "./SectionView";

const ChartKG = ({ boxs, boxSelected, time }) => {
    const [charts, setCharts] = useState();
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        if (["P8", "D5", "4M"].includes(boxSelected)) return;
        if (!boxs || !boxSelected) return;
        const currentBox = boxs.find(b => b.id === boxSelected);
        setMetrics([...currentBox.metrics])
    }, [boxs, boxSelected])

    useEffect(() => {
        if (["P8", "D5", "4M"].includes(boxSelected)) return;
        if (!boxSelected || !metrics || metrics.length === 0) return;

        // API lấy dữ liệu mực nước để nội suy lưu lượng
        if (boxSelected === '3KVAXM37' || boxSelected === 'Z00PSBWY') {
            let dataWAU = []
            monitorService.getRecordByBox('B5R6ZA0U', time)
                .then(res => {
                    dataWAU = res.data.records.filter(r => getMinutes(r.t * 1000) === 0 || getMinutes(r.t * 1000) === 30);
                    monitorService.getRecordByBox(boxSelected, time)
                        .then(res => {
                            const data = res.data.records.filter(r => getMinutes(r.t * 1000) === 0 || getMinutes(r.t * 1000) === 30);
                            data.map(d => {
                                dataWAU.find(w => {
                                    if (getDate(w.t * 1000) === getDate(d.t * 1000) && getMonth(w.t * 1000) === getMonth(d.t * 1000)) {
                                        if (getHours(w.t * 1000) === getHours(d.t * 1000)) d.WAU = w.WAU
                                        else if (getHours(d.t * 1000) === getHours(w.t * 1000) - 1) d.WAU = w.WAU
                                        else if (getHours(d.t * 1000) === getHours(w.t * 1000) + 1) d.WAU = w.WAU
                                    }
                                })
                            })
                            boxs.map(b => {
                                if (b.id === '3KVAXM37' || b.id === 'Z00PSBWY') b.metrics.push({ name: 'Mực nước', code: 'WAU', metric: 'WAU' })
                            })
                            const chart = BuildChartData(data, boxs.find(b => b.id === boxSelected));
                            const ajustChart = adjustRAData(chart.filter(r => r));
                            setCharts(ajustChart)
                        })
                        .catch(err => console.log(err));
                })
                .catch(err => console.log(err));
        } else {
            monitorService.getRecordByBox(boxSelected, time)
                .then(res => {
                    const data = res.data.records.filter(r => getMinutes(r.t * 1000) === 0 || getMinutes(r.t * 1000) === 30);
                    const chart = BuildChartData(data, boxs.find(b => b.id === boxSelected));
                    const ajustChart = adjustRAData(chart.filter(r => r));
                    setCharts(ajustChart)
                })
                .catch(err => console.log(err));
        }
    }, [boxSelected, time, metrics]);

    return <div className="ChartKG">
        {["P8", "D5", "4M"].includes(boxSelected) ?
            <SectionView boxSelected={boxSelected} time={time} />
            :
            <div className="list-chart">
                {charts && Children.toArray(charts.map(t => {
                    if (t.type === 'line') return <div className="chart-container">
                        <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                        <div className="chart-item">
                            <LineChart max={t.metric === 'WA' ? 60 : null} height='100%' data={t} />
                        </div>
                    </div>
                    if (t.type === 'bar') return <div className="chart-container">
                        <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                        <div className="chart-item">
                            <BarChart height='100%' data={t} />
                        </div>
                    </div>
                    if (t.type === 'multiLine') return <div className="chart-container">
                        <p>Biểu đồ dữ liệu {boxSelected === '3KVAXM37' ? 'đo mở tràn' : METRIC_GROUPS.find(m => m.metric === t.metric).label}</p>
                        <div className="chart-item">
                            <MultiLineChart height='100%' data={t} />
                        </div>
                    </div>
                }))}
            </div>
        }
    </div>
}

export default ChartKG;