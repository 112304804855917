import { ID_GROUPS } from "../../../../Utils/const";

export const DOMAIN = {
    DASHBOARD: "/app/dashboard",
    MAP: "/app/map",
    MONITOR: "/app/monitor",
    ZONE: "/app/zone",
    GROUP: "/app/group",
    SENSOR: "/app/sensor",
    USER: "/app/user",
    CAMERA: "/app/camera",
    HELP: "/app/help",
    MONITOR_DOT: "/app/monitor/list",
    CHART: "/app/chart"
}

export function CUSTOM_SIDEBAR(index) {
    switch (index) {
        case +0:
            return DOMAIN.DASHBOARD
                
        case +1:
            if(window.localStorage.getItem('group_id') === ID_GROUPS.DOT) {
                return DOMAIN.MONITOR_DOT
            }
            return DOMAIN.HELP
                
        case +2:
            if(window.localStorage.getItem('group_id') === ID_GROUPS.KG) {
                return DOMAIN.MONITOR_DOT
            }else if(window.localStorage.getItem('group_id') === ID_GROUPS.DOT) {
                return DOMAIN.CHART
            }else if(window.localStorage.getItem('group_id') === ID_GROUPS.AC) {
                return DOMAIN.MONITOR_DOT
            }
            // return DOMAIN.MONITOR
            return;

        case +3:
            if(window.localStorage.getItem('group_id') === ID_GROUPS.KG) {
                return DOMAIN.CHART
            }else if(window.localStorage.getItem('group_id') === ID_GROUPS.DOT) {
                return DOMAIN.HELP
            }
            return DOMAIN.USER

        case +4:
            if(window.localStorage.getItem('group_id') === ID_GROUPS.KG || window.localStorage.getItem('group_id') === ID_GROUPS.DOT) {
                return DOMAIN.USER
            }
            // return DOMAIN.GROUP
           
        case +5:
            return DOMAIN.SENSOR

        case +6:
            return DOMAIN.USER

        case +7:
            return DOMAIN.CAMERA

        default: 
            return DOMAIN.DASHBOARD
    }
}

export function APP_TITLE(domain) {
    switch (domain) {
        case DOMAIN.ZONE:
            return "Danh sách khu vực";
        case DOMAIN.GROUP:
            return "Danh sách công trình";
        case DOMAIN.SENSOR:
            return "Danh sách trạm đo";
        case DOMAIN.USER:
            return "Quản lý người dùng";
        case DOMAIN.CAMERA:
            return "Quản lý camera";
        case DOMAIN.HELP:
            return "Hỗ trợ vận hành"
        default: 
            return "";
    }
}

export const drawerWidth = 250;
export const Menu = () => {
    if(window.localStorage.getItem('group_id') === ID_GROUPS.KG) {
        return ["Bảng tin", "Hỗ trợ vận hành", "Dữ liệu lịch sử", "Biểu đồ", "Quản lý tài khoản"]
    }
    else if(window.localStorage.getItem('group_id') === ID_GROUPS.DOT) {
        return ["Bảng tin", "Dữ liệu lịch sử", "Biểu đồ", "Hỗ trợ vận hành", "Quản lý tài khoản"]
    }
    else return ["Bảng tin", "Hỗ trợ vận hành", "Dữ liệu lịch sử", "Quản lý tài khoản"]
} 
