export const RECT = [
    {
        box_id: "KDD0SN4G",
        image: "mc1.png",
        ratio: 13,
        value: {
            WP1: { x: 437, y: 470, w: 6, h: 1, default: 20, name: 'TNP8-1' },
            WP2: { x: 431, y: 216, w: 6, h: 1, default: 38, name: 'BHP8-1' },
            WP3: { x: 641, y: 302, w: 6, h: 1, default: 33, name: 'BHP8-2' },
            WP4: { x: 646, y: 545, w: 6, h: 1, default: 14, name: 'TNP8-2' },
        }
    },
    {
        box_id: "D6QKHC5Y",
        image: "mc1.png",
        ratio: 13,
        value: {
            WP1: { x: 948, y: 392.5, w: 6, h: 1, default: 26, name: 'BHP8-3', tx: -110, ty: -20 },
            WP2: { x: 954, y: 430, w: 6, h: 1, default: 23, name: 'TNP8-3', tx: 10, ty: 10 },
            WP3: { x: 1410, y: 399, w: 6, h: 1, default: 25.5, name: 'BHP8-4', tx: -80, ty: -45 },
            WP4: { x: 1415, y: 423, w: 6, h: 1, default: 22.5, name: 'TNP8-4', tx: 10, ty: 10 },
        }
    },
    {
        box_id: "WU589RSL",
        image: "mc2.png",
        ratio: 13,
        value: {
            WP1: { x: 440.5, y: 492.5, w: 6, h: 1, default: 19, name: 'TND5-1' },
            WP2: { x: 433.3, y: 246.5, w: 6, h: 1, default: 38, name: 'BHD5-1' },
            WP3: { x: 654.5, y: 277, w: 6, h: 1, default: 36, name: 'BHD5-2' },
            WP4: { x: 659.4, y: 527.5, w: 6, h: 1, default: 16, name: 'TND5-2', tx: -34, ty: 25 },
        }
    },
    {
        box_id: "QYOV7RB9",
        image: "mc2.png",
        ratio: 13,
        value: {
            WP1: { x: 959.5, y: 406, w: 6, h: 1, default: 25.5, name: 'BHD5-3', tx: 20, ty: -45 },
            WP2: { x: 968, y: 451.5, w: 6, h: 1, default: 22, name: 'TND5-3', tx: -30, ty: 10 },
            WP3: { x: 1425, y: 413, w: 6, h: 1, default: 25, name: 'BHD5-4', tx: 20, ty: -45 },
            WP4: { x: 1430.4, y: 439, w: 6, h: 1, default: 23, name: 'TND5-4', tx: -60, ty: 25 },
        }
    },
    {
        box_id: "SPMXV9A0",
        image: "mc3.png",
        ratio: 17,
        value: {
            WP1: { x: 590.5, y: 551.5, w: 6, h: 1, default: 25, name: 'TN4M-1', tx: 20, ty: -45 },
            WP2: { x: 584.5, y: 265, w: 6, h: 1, default: 42, name: 'BH4M-1', tx: -60, ty: 10 },
            WP3: { x: 878.5, y: 316.5, w: 6, h: 1, default: 39, name: 'BH4M-2', tx: 20, ty: -45 },
            WP4: { x: 884.5, y: 615, w: 6, h: 1, default: 21.3, name: 'TN4M-2', tx: -60, ty: 25 },
        }
    },
    {
        box_id: "YKEZCC6N",
        image: "mc3.png",
        ratio: 16.5,
        value: {
            WP1: { x: 1263, y: 451, w: 6, h: 1, default: 31, name: 'BH4M-3', tx: 20, ty: -45 },
            WP2: { x: 1269.5, y: 510, w: 6, h: 1, default: 27.5, name: 'TN4M-3', tx: -60, ty: 10 },
            WP3: { x: 1698.5, y: 451, w: 6, h: 1, default: 31, name: 'BH4M-4', tx: 13, ty: -45 },
            WP4: { x: 1704.5, y: 493.5, w: 6, h: 1, default: 28.5, name: 'TN4M-4', tx: -60, ty: 10 },
        }
    }
]