import { Switch, Route } from "react-router";
import ListGroups from "./Components/ListGroups";
import DetailGroup from "./Components/DetailGroup";
const Group = () => {
    return (
        <div>
            <Switch>
                <Route path="/app/group/:id"
                    render={() => <DetailGroup />} 
                /> 
                <Route path="/app/group"
                    render={() => <ListGroups />}
                />
                
            </Switch>
        </div>
    )
}

export default Group;