import React from "react";
import { Popup } from "react-leaflet";

const BoxPopup = (props) => {

    return <>
        <Popup>
            <div className="title" >{props.box.name}</div>
            <div className="content">
                {props.box?.metrics?.map((m, i) => {
                    if (m.code !== 'VO' & m.code !== 'TE') {
                        return <div key={i} className="item">
                            <span className="item-title">{m.name}: </span>
                            {props.record && Object.keys(props.record).some(key => key === m.code) ?
                                props.record[m.code] : "0"
                            }
                        </div>
                    }else return null
                })}
            </div>
        </Popup>
    </>
};

export default BoxPopup;
