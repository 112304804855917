import React, { Children, useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { format } from 'date-fns';

import zoneService from'../../Zone/Shared/ZoneService';
import sharedService from '../../../../Services/SharedService'; 
import groupService from '../Shared/GroupService';

import { ReactComponent as EditIcon } from '../../../../Asset/edit-button.svg';
import { ReactComponent as DeleteIcon } from '../../../../Asset/delete.svg';

import { CHECK_EMPTY_STRING } from '../../../../Utils/string';
import { checkNumber } from '../../../../Utils/number';
import { ERRORS } from '../../../../Utils/const';
import { ResetForm, goTo } from '../../../../Utils/helper';
import ModalNoti from '../../../Components/ModalNoti/ModalNoti';
import CustomLoading from '../../../Components/Loading/CustomLoading';
import CustomPagination from '../../../Components/Pagination/Pagination';
export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ListGroups = () => { 

    const headers = ["STT", "Tên công trình", "Độ phóng to", "Trung tâm (Kinh độ, Vĩ độ)", "Trạm đo", "Thời gian cập nhật", "Hành động"];

    const [page, setPage] = useState(+0);
    const [rowsPerPage, setRowsPerPage] = useState(+15);

    const [validate, setValidate] = useState({
        name: false, 
        zone_id: false, 
        lat: false,
        lng: false,
        zoom: false,
        message: {
            name: "",
            zone_id: "",
            lat: "",
            lng: "",
            zoom: ""
        }
    });
    const [open, setOpen] = useState(false);
    const [group, setGroup] = useState({
                                        name: "", 
                                        zoom: "", 
                                        zone_id: "",
                                        center: {
                                                lat: "", 
                                                lng: ""
                                        }, 
                                        cameras: []
                            });
    const [modalNoti, setModalNoti] = useState({
        open: false,
        title: "",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(true);
    const [listBoxGroup, setListBoxGroup] = useState([]);
    const [listZone, setListZone] = useState([]);
    const [recallList, setRecallList] = useState(false)

    useEffect(() => {
        sharedService.listBoxGroup().then(
            res => {
                setListBoxGroup(res.data)
                setIsLoading(false);
            }
        ).catch(err => console.log(err))
        zoneService.getListZone().then(res => {
            const listZoneOption = res.data.map(option => {
                return {
                    label: option.name,
                    value: option.id
                }
            })
            setListZone(listZoneOption)
        })
        .catch(err => console.log(err))
    }, [recallList])
    

    const funcResetValidate = () => {
        const resetValidate = {
            name: false, 
            zone_id: false, 
            lat: false,
            lng: false,
            zoom: false,
            message: {
                name: "",
                zone_id: "",
                lat: "",
                lng: "",
                zoom: ""
            }
        }
        setValidate(resetValidate);
    }
    
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        const resetData = ResetForm(group);
        console.log(resetData);
        setGroup(resetData);
        funcResetValidate()
        setOpen(false);
    };


    const onChangeFieldGroup = (e) => {
        const {name, value} = e.target;
        setGroup({...group, [name]: value})
        if(e.target.name === "lat" || e.target.name === "lng") {
            setGroup({
                ...group,
                center: { 
                    ...group.center,
                    [name]: value
                }
            })
        }
    }
    const submitCreateGroup = () => { 
        if(CHECK_EMPTY_STRING(group.name) || CHECK_EMPTY_STRING(group.zone_id) || CHECK_EMPTY_STRING(group.zoom) || CHECK_EMPTY_STRING(group.center.lat) || CHECK_EMPTY_STRING(group.center.lng)){
            setValidate({
                ...validate, 
                name: CHECK_EMPTY_STRING(group.name), 
                zone_id: CHECK_EMPTY_STRING(group.code),
                zoom: CHECK_EMPTY_STRING(group.code),
                lat: CHECK_EMPTY_STRING(group.center.lat),
                lng: CHECK_EMPTY_STRING(group.center.lng)
            })
        }
        else {
            const data = {
                name: group.name,
                zone_id: group.zone_id,
                center: {
                    lat: +group.center.lat,
                    lng: +group.center.lng
                },
                zoom: group.zoom
            }
            groupService.createBoxGroup(data).then(res => {
                setRecallList(!recallList);
                funcResetValidate();
                const resetData = ResetForm(group);
                setGroup(resetData);
                handleClose();
                setModalNoti({...modalNoti, open: true, title: "Thành công!", message: "Thêm mới công trình thành công!"});

            })
            .catch(err => {
                if (err.response) {
                        const errorMessage = err.response.data.error;
                        const errTranslate = ERRORS.BOX.find(err => err.message === errorMessage);
                        handleClose();
                        setModalNoti({...modalNoti, open: true, title: "Thất bại!", message: `Thêm mới công trình thất bại! Lỗi: ${errTranslate.translate}.`});
                }
            })
        }
    }

    

    const validateCreate = (e, option) => {
        if(e.target.name === "name"){
            if(option === "focus") {
                setValidate({
                    ...validate,
                    name: false
                })
            }
            else {
                setValidate({
                        ...validate,
                        message: {
                            ...validate.message,
                            name: CHECK_EMPTY_STRING(group.name) ? "Không được bỏ trống trường tên công trình!": ""
                        }, 
                        name: CHECK_EMPTY_STRING(group.name)
                })
            }
        } 
        if(e.target.name === "zone_id"){
            if(option === "focus") {
                setValidate({...validate, zone_id: false})

            }
            else {
                setValidate({
                        ...validate,
                        message: {
                            ...validate.message,
                            zone_id:  CHECK_EMPTY_STRING(group.zone_id) ? "Vui lòng lựa chọn khu vực!": ""
                        }, 
                        zone_id: CHECK_EMPTY_STRING(group.zone_id)
                })
                
            }
        } 
        if(e.target.name === "zoom"){
            if(option === "focus") {
                setValidate({...validate, zoom: false})

            }
            else {
                if(CHECK_EMPTY_STRING(group.zoom)) {
                    setValidate({
                        ...validate, 
                        zoom: true,
                        message: {
                            ...validate.message,
                            zoom: "Không được bỏ trống độ phóng to!"
                        }, 
                    })
                }
                else if(checkNumber(group.zoom)){
                    setValidate({
                        ...validate,
                        zoom: true,
                        message: {
                            ...validate.message,
                            zoom: "Mức độ phóng to phải là dạng số!"
                        }, 
                    })
                }
                else{
                    setValidate({
                        ...validate,
                        zoom: false,
                        message: {
                            ...validate.message,
                            zoom: ""
                        }, 
                    })
                }
                
            }
        } 
        if(e.target.name === "lat"){
            if(option === "focus") {
                setValidate({...validate, lat: false})
            }
            else {
                if(CHECK_EMPTY_STRING(group.center.lat)) {
                    setValidate({
                        ...validate, 
                        lat: true,
                        message: {
                            ...validate.message,
                            lat: "Không được bỏ trống kinh độ!"
                        }, 
                    })
                }
                else if(checkNumber(group.center.lat)){
                    setValidate({
                        ...validate,
                        lat: true,
                        message: {
                            ...validate.message,
                            lat: "Kinh độ phải là dạng số!"
                        }, 
                    })
                }
                else{
                    setValidate({
                        ...validate,
                        lat: false,
                        message: {
                            ...validate.message,
                            lat: ""
                        }, 
                    })
                }
                
            } 
        } 
        if(e.target.name === "lng"){
            if(option === "focus") {
                setValidate({...validate,
                    lng: false
                })

            }
            else {
                if(CHECK_EMPTY_STRING(group.center.lng)) {
                    setValidate({
                        ...validate, 
                        lng: true,
                        message: {
                            ...validate.message,
                            lng: "Không được bỏ trống vĩ độ!"
                        }, 
                    })
                }
                else if(checkNumber(group.center.lng)){
                    setValidate({
                        ...validate,
                        lng: true,
                        message: {
                            ...validate.message,
                            lng: "Vĩ độ phải là dạng số!"
                        }, 
                    })
                }
                else {
                    setValidate({
                        ...validate,
                        lng: false,
                        message: {
                            ...validate.message,
                            lng: ""
                        }, 
                    })
                }
            }
        } 
    }
    const done = () => {
        const resetModal = ResetForm(modalNoti)
        setModalNoti(resetModal)
    }
    
    return(
        <>
        <ModalNoti modal={modalNoti} done={done} />
            <Paper sx={{ overflow: 'hidden'}} elevation={8}>
                <Grid className='el-button' container>
                    <Grid item xs={9} />
                    <Grid item xs={3} className="end create-button" >
                        <Button variant="contained" onClick={handleClickOpen} >Thêm mới công trình</Button>
                    </Grid>
                </Grid>
                <TableContainer  className="table-zone">
                    <Table stickyHeader>
                        <TableHead className='text-bold upper'>
                            <TableRow>
                                {Children.toArray(headers.map(h => {
                                    return (
                                        <TableCell align="left">{h}</TableCell>
                                    )
                                }))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isLoading ? listBoxGroup.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((boxGroup, index)=> {
                                return (
                                    <TableRow
                                        key={boxGroup.id}
                                        hover
                                        role="checkbox"
                                        onClick={() => goTo("app/group/"+ boxGroup.id)}
                                    >   
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell align="left" style={{ width: "30%" }}>{boxGroup.name}</TableCell>
                                        <TableCell align="left">{boxGroup.zoom}</TableCell>
                                        <TableCell align="left">{boxGroup.center?.lat}, {boxGroup.center?.lng}</TableCell>
                                        <TableCell align="left">{boxGroup.boxs?.length}</TableCell>
                                        <TableCell align="left">{boxGroup.mtime ? format(boxGroup.mtime * 10e2, "HH:mm:ss, dd/MM/yyyy"): "api loi"}</TableCell>
                                        <TableCell align="left"><EditIcon /> &nbsp;&nbsp;&nbsp; <DeleteIcon /></TableCell>
                                    </TableRow>
                                )
                            }) : <CustomLoading numbers={7}/>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container sx={{ p: "1%", ml: "1%" }}>
                    <CustomPagination
                        data={listBoxGroup}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        parentCallBack={handleChangePage}
                    />
                </Grid>
            </Paper>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="modal-create-zone"
                fullWidth
                maxWidth='md'
                sx={{position: "absolute", top:"-20%"}}
                
            >
                <DialogTitle className='dialog-title-zone'>Thêm mới công trình</DialogTitle>
                <DialogContent sx={{mt: "23px", mb: "10px", pl: "96px", pr: "102px"}}>
                        <Grid>
                            Tên công trình*:
                        </Grid>
                        <Grid sx={{ pt: 2, pb: 2 }}>
                            <TextField 
                                error = {validate.name}
                                onBlur={(e)=> validateCreate(e, "blur")}
                                onFocus={(e)=> validateCreate(e, "focus")}
                                name="name" 
                                value={group.name} 
                                onChange={onChangeFieldGroup} 
                                fullWidth 
                                label="Công trình" 
                                placeholder='Nhập tên công trình'
                            />
                            {validate.name && <p className="color-red">{validate.message.name}</p>}
                        </Grid>
                        <Grid>
                            Thuộc khu vực*:
                        </Grid>
                        <Grid sx={{ pt: 2, pb: 2 }}>
                            <TextField 
                                select
                                error = {validate.zone_id}
                                onBlur={(e)=> validateCreate(e, "blur")}
                                onFocus={(e)=> validateCreate(e, "focus")}
                                name="zone_id" 
                                value={group.zone_id} 
                                onChange={(e)=>onChangeFieldGroup(e)} 
                                fullWidth 
                                label="Khu vực" 
                            >
                            {listZone.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                            </TextField>
                            {validate.zone_id && <p className="color-red">{validate.message.zone_id}</p>}
                        </Grid>
                        <Grid>
                            Vị trí trung tâm*:
                        </Grid>
                        <Grid sx={{ pt: 2, pb: 2  }} container>
                            <Grid item xs={5} >
                                <TextField 
                                    error = {validate.lat}
                                    onBlur={(e)=> validateCreate(e, "blur")}
                                    onFocus={(e)=> validateCreate(e, "focus")}
                                    onChange={onChangeFieldGroup} 
                                    name="lat" 
                                    fullWidth 
                                    label='Kinh độ'
                                    value={group.center.lat}

                                />
                                {validate.lat && <p className="color-red">{validate.message.lat}</p>}
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                                <TextField 
                                    error = {validate.lng}
                                    value={group.center.lng}
                                    onBlur={(e)=> validateCreate(e, "blur")}
                                    onFocus={(e)=> validateCreate(e, "focus")}
                                    fullWidth  
                                    label='Vĩ độ'
                                    onChange={onChangeFieldGroup} 
                                    name="lng" 
                                />
                                {validate.lng && <p className="color-red">{validate.message.lng}</p>}
                            </Grid>
                        </Grid>
                        <Grid>
                            Mức phóng to*:
                        </Grid>
                        <Grid sx={{ pt: 2, pb: 2  }}>
                            <TextField 
                                error={validate.zoom}
                                name="zoom" 
                                value={group.zoom} 
                                onChange={onChangeFieldGroup} 
                                fullWidth 
                                label="Độ phóng" 
                                placeholder='Nhập mức độ phóng to'
                                onBlur={(e)=> validateCreate(e, "blur")}
                                onFocus={(e)=> validateCreate(e, "focus")}
                            />
                            {validate.zoom && <p className="color-red">{validate.message.zoom}</p>}
                        </Grid>
                </DialogContent>
                <DialogActions sx={{mb: "55px", pl: "96px", pr: "103px"}}>
                    <Grid container>
                        <Grid item xs={5} sx={{textAlign : "end"}} >
                            <Button sx={{background: "#E5E5E5", color: "black"}} variant="contained" onClick={handleClose} className='button-wh'>Huỷ</Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5} sx={{textAlign : "start"}}>
                            <Button type="submit" className='button-wh' variant="contained"
                                disabled={[validate.name, validate.zone_id, validate.zoom, validate.lat, validate.lng].includes(true)}
                                onClick={submitCreateGroup}
                            >Lưu</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ListGroups;

