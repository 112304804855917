import { Children } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// type Loading: table, non-table
const CustomLoading = (props) => {
    let { numbers, type } = props;

    if (!type) {
        type = "table"
    }
    
    return (
        <>
            { type === "table" && <TableRow>
                {Children.toArray([...Array(numbers)].map((_, i) => {
                    return (
                        <TableCell>
                            <LinearProgress/>
                        </TableCell>
                    )
                }))}
            </TableRow> }
        </>

    )
}

export default CustomLoading;