import { useState } from "react";
import { CalculateVolume } from "../../../../Components/Interpolation/volume";
import { CalculateSL } from "./hypothesis_data";
import { CalculateDown } from "../../../../Components/Interpolation/spin";

const Hypothesis_SL = (props) => {
    const [rain, setRain] = useState()
    const [zhl, setZhl] = useState()
    const [data, setData] = useState({
        z3: null,
        zx: null,
        qx: null,
        t: null,
    })

    function onChangeData(e) {
        const { value, name} = e.target
        data[name] = value;
        setData({...data})
        if(data.zx && data.qx && data.z3) {
            const v1 = CalculateVolume(data.z3);
            const v2 = CalculateVolume(data.zx);
            const t = (v1-v2) * 1e6 / (data.qx * 3600);
            data.t = Math.round(t *100) / 100;
            setData({...data});
            setZhl(CalculateDown(data.qx))
        }
    }

    function onChangeX(e) {
        const { value } = e.target;
        setRain(value);
        const [q3, z3] = CalculateSL(value, props.z)
        setData({...data, q3, z3})
        props.setZtl3(z3);
    }

    return (
        <>
            <div className="hypothesis">
                <div className="title pt-20">
                    THÔNG SỐ GIẢ ĐỊNH
                </div>
                <div className="note">
                    <p>Thông số nhập</p>
                    <p>Thông số tính toán</p>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Lượng mưa giả định (mm)</p>
                        <input name className="value" onChange={onChangeX} value={rain}/>
                    </div>
                    <div className="item">
                        <p className="label">Lưu lượng đến sau 3h (m3/s)</p>
                        <input className="value" name="q3" value={data.q3}/>
                    </div>
                </div>
                <div className="content" style={{ marginBottom: '10px', borderBottom: '2px dashed' }}>
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">ZTL sau 3h (m)</p>
                        <input name="z3" className="value" value={data.z3}/>
                    </div>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Q xả giả định (m3/s)</p>
                        <input name="qx" onChange={(e) => onChangeData(e)} className="value" value={data.qx} />
                    </div>
                    <div className="item">
                        <p className="label">Thời gian xả giả định (h)</p>
                        <input name="t" className="value" value={data.t} />
                    </div>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">ZTL sau khi xả (m)</p>
                        <input name="zx" onChange={(e) => onChangeData(e)} className="value" value={data.zx} />
                    </div>
                    <div className="item">
                    <p className="label">ZHL sau xả giả định (m)</p>
                        <input name="zx" className="value" value={zhl} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Hypothesis_SL;