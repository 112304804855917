import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, defaults } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },   
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    }
};

defaults.font.size = 14;
defaults.font.weight = 800;

const CustomChart = (props) => {
    const { labels, data } = props;

    const dataset = {
        labels,
        datasets: [
            {
                label: 'Lượng mưa',
                data: data,
                // borderColor: 'rgb(255, 99, 132)',
                backgroundColor: '#34A853',
            },
        ],
    };
    return (
        <div className="custom-chart">
            <Bar options={options} data={dataset} className="fontsz-18" />
        </div>
    )
}

export default CustomChart;