import { Http } from "../Services/http";
import { LOCAL_STORAGE } from "../Utils/const";

const API_ENDPOINT = {
  LOGIN: "auth/login",
  ME: "auth/me"
}
class AuthService {
  constructor() {
    if (AuthService._instance) {
      return AuthService._instance;
    }
    AuthService._instance = this;
  }
  setGroupId(id) {
    localStorage[LOCAL_STORAGE.GROUP_ID] = id
  }

  getGroupId() {
    return localStorage.getItem(LOCAL_STORAGE.GROUP_ID) || '';
  }

  setToken(data) {
    localStorage[LOCAL_STORAGE.TOKEN] = data.id;
  }

  getToken() {
    return localStorage.getItem(LOCAL_STORAGE.TOKEN) || '';
  }

  setUserInfo(user) {
    localStorage[LOCAL_STORAGE.USER] = JSON.stringify(user);
  }

  getUserInfo() {
    if (localStorage.getItem(LOCAL_STORAGE.USER)) {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER));
  }
    return null;
  }

  login(payload) {
    return Http.post(API_ENDPOINT.LOGIN, payload);
  }

  getMe() {
    return Http.get(API_ENDPOINT.ME)
  }
}
const authService = new AuthService();

export default authService;
