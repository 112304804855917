import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, FormControl, InputLabel, Select, MenuItem, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import monitorService from '../../Pages/Monitor/Shared/MonitorService';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BOXGROUP_ID, ID_GROUPS } from '../../../Utils/const';
import sharedService from '../../../Services/SharedService';

const FunctionButtons = (props) => {
    const [open, setOpen] = useState(false);
    const [box, setBox] = useState();
    const { parentCallBack, listBox, time } = props;

    const [open2, setOpen2] = useState(false);
    const [modal, setModal] = useState({

    });
    const [data, setData] = useState({
        t: Math.floor(Date.now() / 1000)
    });

    const handleExportReport = () => {
        if (!box) return;
        const url = monitorService.exportCSV(box, time)
        window.location.assign(url);
        setBox("")
        setOpen(false)
    }

    const handleInputData = () => {
        parentCallBack(true);
    }

    function changeTime(e) {
        const date = new Date(e)
        const result = Math.floor(date.getTime() / 1000)
        setData({...data, t: result})
    }

    function handleImport() {
        setOpen2(false)
        setModal({
            open: true,
            message: "Xác nhận thêm mới bản ghi dữ liệu",
            title: "Thông báo"
        })
    }

    function answer(isTrue) {
        setModal({...modal, open: false})
        if(isTrue) {
            props.importHandle(props.importBox.id, data)
        }
    }

    function checkRecord() {
       const keysRecord = Object.keys(data).filter(k => k !== 't')
       const check = keysRecord.some(k => data[k])
       return check
    }

    return (
        <>
            <Grid container>
                <Grid xs={6.5} lg={10}>
                    {BOXGROUP_ID === ID_GROUPS.DD && props.importData && <Button variant="outlined" onClick={e => setOpen2(true)}>Nhập số liệu</Button>}
                </Grid>
                <Grid xs={5.5} lg={2} className='end'>
                    <Button variant="contained" sx={{ mr: 3 }} onClick={() => setOpen(true)}>Xuất báo cáo</Button>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Xuất file</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vui lòng chọn trạm mà bạn muốn xuất file dữ liệu!
                    </DialogContentText>
                    <FormControl style={{ width: '80%' }}>
                        <InputLabel id="select-label">Trạm</InputLabel>
                        <Select labelId="select-label" value={box} label="Trạm" onChange={(e) => setBox(e.target.value)} >
                            {listBox && listBox.map(l => {
                                return <MenuItem selected={l.id === box ? true : false} key={l.id} value={l.id}>{l.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Hủy</Button>
                    <Button disabled={box ? false : true} onClick={handleExportReport}>Đồng ý</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open2} onClose={() => setOpen2(false)} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Nhập bản ghi dữ liệu</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: '20px' }}>
                        {props?.importBox?.name}
                    </DialogContentText>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker label="Thời gian"  value={new Date(data?.t * 1000)} onChange={e => changeTime(e)} />
                    </LocalizationProvider>
                    {props.importBox?.metrics?.filter(m => !['TE', 'VO'].includes(m.code)).map(m => {
                        return <TextField index={m.code} label={m.name} value={data?.[m.code]} style={{ marginLeft: '20px' }} onChange={(e) => setData({...data, [m.code]: +e.target.value})} />
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen2(false)}>Hủy</Button>
                    <Button  disabled={checkRecord() ? false : true} onClick={handleImport}>Đồng ý</Button>
                </DialogActions>
            </Dialog>

            <ModalConfirm modal={modal} functionCallBack={answer} />
        </>
    )
}

export default FunctionButtons;