import React from "react";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import { format } from "date-fns";
import zoomPlugin from 'chartjs-plugin-zoom';
import { BOXGROUP_ID, ID_GROUPS } from "../../../../Utils/const";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  zoomPlugin
);

const time_second = (d) => format(d, "HH:mm:ss");

// BarChart
class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    if(!this.props.data) return;
    this.myChart.data.labels = this.props.data.labels.map(l => time_second(l));
    this.myChart.data.datasets = this.props.data.datasets;
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                weight: 'bold',
                size: '11px',
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              }
            },
          },
          tooltip : {
            titleFont: {
              weight: 'bold',
              size: '13px'
            },
            bodyFont: {
              weight: 'bold'
            }
          }
          //   zoom: {
          //     pan: {
          //       enabled: true,
          //       mode: 'xy',
          //     },
          //     zoom: {
          //       wheel: {
          //         enabled: true,
          //       },
          //       pinch: {
          //         enabled: true
          //       },
          //       mode: 'xy',
          //       onZoomComplete({ chart }) {
          //         chart.update('none');
          //       }
          //     }
          //   }
        },
        scales: {
          y: {
            // max: BOXGROUP_ID === ID_GROUPS.KG ? 20 : null,
            ticks: {
              font: {
                size: '11px !important',
                weight: null,
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              },
            }
          },
          x: {
            ticks: {
              callback: function (val, index, values) {
                const value = this.getLabelForValue(val)
                const change = value.slice(0, 2)
                return change + 'h'
              },
              font: {
                size: '11px !important',
                family: "Roboto, Helvetica, Arial, sans-serif !important",
                weight: null
              }
            }
          }
        }
      },
      data: {
        labels: this.props.data.labels.map(l => time_second(l)),
        datasets: this.props.data.datasets
      }
    });

  }

  render() {
    return <div style={{ width: '100%', height: this.props.height || '30vh' }}>
      <canvas style={{ width: '100%', height: '100%', maxHeight: '600px' }} ref={this.canvasRef} id="myCanvas-Bar"/>
    </div>
  }
}
export default BarChart