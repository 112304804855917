import { useEffect, useState } from "react";
import { CalculateOperationSupport, CalculateFlood } from "./hypothesis_data";
import CustomizedSnackbars from './../../../../Components/AlertNoti/AlertNoti';

const Hypothesis_DD = (props) => {
    const [rain, setRain] = useState()
    const [Ztltt, setZtltt] = useState()
    const [dr, setDr] = useState()
    const [qSpin, setQSpin] = useState()
    const [qFree, setQFree] = useState()
    const [flood, setFlood] = useState([])
    const [noti, setNoti] = useState({
        open: false,
        message: "",
        severity: ""
    })
    const rainMax = [50, 100, 150, 200, 250, 294, 416, 544, 904]
    function onChangeRain(e) {
        const { value } = e.target
        setRain(value)

        if(+value > 904){
            setNoti({
                open: true,
                message: "Giá trị lớn nhất có thể nhập là 904.",
                severity: "warning"
            })
        }
        else{
            setNoti({
                open: false,
                message: "",
                severity: ""
            })
            let zValue = props.z;
            if(SubdomainIsDemo()) zValue = 28.75
            const [zRain, dr, qSpin, qFree] = CalculateOperationSupport(zValue, value)
            setZtltt(zRain);
            setQFree(qFree)
            setQSpin(qSpin)
            setDr(dr)
            props.setZtl3(zRain);
            const currentFlood = CalculateFlood(zRain)
            setFlood(currentFlood)
        }
    }

    function getTotalFlood(a, b) {
        return Math.round((a + b) * 100) / 100;
    }

    function SubdomainIsDemo() {
        // const url = 'https://demo.dulieuthuyloivietnam.vn/app/help'
        const url = window.location.href;
        const start = 8
        const end = url.indexOf('.');
        const subdomain = url.slice(start, end);
        if(subdomain && subdomain === 'demo') return true;
        else return false
    }

    //for alert noti
    

    const handleCloseNoti = () => {
        setNoti({
            open: false,
            message: "",
            severity: ""
        });
    }

    return (
        <>
        <CustomizedSnackbars open={noti.open} message={noti.message} severity={noti.severity} handleClose={handleCloseNoti} />
            <div className="hypothesis">
                <div className="title pt-20">
                    THÔNG SỐ GIẢ ĐỊNH {SubdomainIsDemo() ? 'Ztl = 28,75m' : ''}
                </div>
                <div className="note">
                    <p>Thông số nhập</p>
                    <p>Thông số tính toán</p>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Lượng mưa giả định (mm)</p>
                        <input name="rain" className="value" onChange={onChangeRain} value={rain} />
                    </div>
                    <div className="item">
                        <p className="label">ZTL tính toán</p>
                        <input className="value" name="Ztl" value={Ztltt} />
                    </div>
                </div>
                <div className="content">
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Độ mở cửa van (mở 2 cửa) (m)</p>
                        <input name="z3" className="value" value={dr} />
                    </div>
                </div>
                <div className="content">
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Qtrtd (tràn tự do) (m3/s)</p>
                        <input name="z3" className="value" value={qFree} />
                    </div>
                </div>
                <div className="content" style={{ marginBottom: '10px', borderBottom: '2px dashed' }}>
                    <div className="item">
                    </div>
                    <div className="item">
                        <p className="label">Qtrcc (tràn có cửa) (m3/s)</p>
                        <input name="z3" className="value" value={qSpin} />
                    </div>
                </div>
                <div className="sflood">
                    <p>Diện tích ngập tính toán</p>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Xã Cửa Dương (ha)</p>
                        <input name="qx" className="value" value={flood[0] || 0} />
                    </div>
                    <div className="item">
                        <p className="label">Phường Dương Đông (ha)</p>
                        <input name="t" className="value" value={flood[2] || 0} />
                    </div>
                </div>
                <div className="content">
                    <div className="item">
                        <p className="label">Xã Dương Tơ (ha)</p>
                        <input name="zx" className="value" value={flood[1] || 0} />
                    </div>
                    <div className="item">
                        <p className="label">Tổng (ha)</p>
                        <input name="zx" className="value" value={flood?.reduce(getTotalFlood, 0) || 0} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hypothesis_DD;