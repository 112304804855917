import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import CustomLoading from '../../../Components/Loading/CustomLoading';
import CustomPagination from '../../../Components/Pagination/Pagination';
import { useState, Children, useEffect } from 'react';
import { calculateQS } from '../../../Components/Chart/Component/chart_data';
import { CalculateFree } from '../../../Components/Interpolation/spin';
import { CalculateFlow } from '../../../Components/Interpolation/drain';
const dateTime = (ts) => format(ts * 10e2, "dd/MM/yyyy - HH:mm") 

const QxaCong = (props) => {
    const {isLoading, data} = props
    const headers = ["Thời gian", "Độ mở tràn có cửa 1 (m)", "Độ mở tràn có cửa 2 (m)", "Độ mở tràn có cửa 3 (m)", "Q xả tràn có cửa",  "Độ mở cửa cống (m)", "Q xả cống", "Q xả tràn tự do", "Tổng Q xả"]
    const [page, setPage] = useState(+0);
    const [rowsPerPage, setRowsPerPage] = useState(+15);
    const [records, setRecords] = useState([])

    useEffect(() => {
        if(data.length === 0) return
        const dataDR = data?.filter(d => d.metrics.includes("DR2"))
        const dataTran = data?.filter(d => !d.metrics.includes("DR2"))
        dataDR[0].records.map(r => {
            dataTran[0].records.forEach((w,i) => {
                if(dateTime(w.t) === dateTime(r.t)) r.DRTRAN = w.DR1
            })
        })
        setRecords(dataDR[0].records)
    }, [data])

    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
    };
    return(
        <>
            <Grid container sx={{ pt: "0.5%", height: "42px" }} />
            <TableContainer className="table-listdata" >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {!isLoading ? headers.length !== +0 && Children.toArray(headers.map((h, i) => {
                                return i === +0 ?
                                    <>
                                        <TableCell align='left' style={{ width: 100 }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: 'uppercase',
                                                    fontSize: 13,
                                                    color: "#606060",
                                                }}
                                            >
                                                {h}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    : <>
                                        <TableCell align='right' style={{ width: Math.ceil(100 / headers.length) }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    textTransform: 'uppercase',
                                                    fontSize: 13,
                                                    color: "#606060",
                                                }}
                                            >
                                                {h}
                                            </Typography>
                                        </TableCell>
                                    </>
                            })): <CustomLoading numbers={6} />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading ? Children.toArray(records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(dt => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                >
                                    <TableCell align='left' component="th" scope="row" style={{ width: "18%" }}>
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444",
                                            }}
                                        >
                                            {format(dt?.t * 10e2, "dd/MM/yyyy - HH:mm")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {(dt.DR1 / 1000).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {(dt.DR2 / 1000).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {(dt.DR3 / 1000).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {calculateQS(dt, dt.WAU)}
                                        </Typography>
                                    </TableCell>
                                    
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {(dt.DRTRAN / 1000).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='right' component="th" scope="row">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444",
                                            }}
                                        >
                                            {CalculateFlow((dt.DRTRAN / 1000).toFixed(2) , dt.WAU)} 
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {CalculateFree(dt.WAU)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: "#444444"
                                            }}
                                        >
                                            {CalculateFree(dt.WAU) + CalculateFlow(Math.round(dt.DRTRAN / 1000) , dt.WAU) + calculateQS(dt, dt.WAU)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })) : <CustomLoading numbers={6} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container sx={{ p: "1%", ml: "1%" }}>
                <CustomPagination
                    data={records}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    parentCallBack={handleChangePage}
                />
            </Grid>
        </>
    )
}
export default QxaCong