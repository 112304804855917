import { useState, useEffect, Children } from "react";
import { Grid } from "@mui/material";
import Statistical from "./Statistical/Statistical";
import sharedService from "../../../../Services/SharedService";
import Map from "./Map/Map";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../../Components/Chart/Component/chart_data";
import LineChart from "../../../Components/Chart/Component/LineChart";
import BarChart from "../../../Components/Chart/Component/BarChart";
import { GetUnit } from "../../../../Utils/helper";
import { BOX_DOT2 } from "../../../Components/App/data";

const DashBoardDOT = ({ records, group, center, isLoading }) => {
    const [charts, setCharts] = useState();
    const [dot2, setDot2] = useState();
    const [recordsRA, setRecordsRA] = useState([]);

    useEffect(async () => {
        const groupRecord = await (await sharedService.listRecordBoxGroup()).data;
        const dot2Record = await (await sharedService.listRecordByBox(BOX_DOT2.id)).data
        dot2Record.box = BOX_DOT2;
        dot2Record.metrics = BOX_DOT2.metrics.map(m => m.code);
        groupRecord.push(dot2Record);
        setDot2({ ...dot2Record });
        const result = groupRecord.map(g => {
            if (g.count === 0) return
            if (g.box.id !== 'S6FZPTB1') {
                g.records = g.records.map(d => {
                    Object.keys(d).forEach(k => {
                        if (k === 'ICI15A') {
                            if (d[k] > 0) d[k] = (Math.floor(Math.random() * 50) + 50) / 100;
                            else if (d[k] < 0) d[k] = (Math.floor(Math.random() * 50) - 50) / 100;
                        } else if ((k.includes('MEI') || k.includes('ICI')) && Math.abs(d[k]) > 5) {
                            if (d[k] > 0) d[k] = +(Math.random() + 0.5).toFixed(2);
                            else if (d[k] < 0) d[k] = +(Math.random() - 1.5).toFixed(2);
                        }
                    })
                    return d
                })
            }
            const chart = BuildChartData(g.records, g.box)
            return chart
        })
        const docs = adjustRAData(result.filter(r => r))
        // fix value for demo 01/12/2023
        docs.map((d, i) => {
            if(d.box_id === "HJII4066" && d.type === 'bar' && d.metric === 'RA') {
                d.datasets.map((ds, i) => {
                    if(i === 1) ds.data = d.datasets[0].data
                })
            }
        })
        setCharts(docs)
    }, [])

    useEffect(() => {
        if (!records || !dot2) return;
        records.push(dot2)
        setRecordsRA(records)
    }, [records, dot2])

    function GetBoxName(box_id) {
        const doc = group.boxs.find(b => b.id === box_id)
        const name = doc.name
        if (name.length > 12) return name.slice(-11)
        return doc.name
    }

    return (
        <>
            <Grid className="DashBoardDOT" container columns={12}>
                <Grid item xs={12} lg={6} sx={{ marginTop: { xs: '20px', lg: '0px' } }} className="list-chart">
                    {charts && Children.toArray(charts.map(t => {
                        if (t.type === 'line') return <div className="chart-container">
                            <p>{GetBoxName(t.box_id)} - Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                            <div className="chart-item">
                                <LineChart height='100%' min={t.metric === 'PZ' ? null : -10} max={t.metric === 'PZ' ? null : 10} data={t} />
                            </div>
                        </div>
                        if (t.type === 'bar') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                            <div className="chart-item">
                                <BarChart height='100%' data={t} />
                            </div>
                        </div>
                    }))}
                </Grid>
                <Grid item xs={0} lg={0.2}>
                </Grid>
                <Grid item xs={12} lg={5.5} sx={{ maxWidth: '1000px', minWidth: '260px', width: '200px', marginTop: { xs: '20px', lg: '0px' } }}>
                    <Statistical data={recordsRA} />
                    <Map group={group} center={center} isLoading={isLoading} />
                </Grid>
            </Grid>
        </>
    )
}

export default DashBoardDOT;