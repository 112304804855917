import { TileLayer, LayersControl } from "react-leaflet";

const LayerMap = (props) => {
  return (
    <LayersControl position="topright">
      <LayersControl.BaseLayer checked={true} name="Google Hybrid">
        <TileLayer
          url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
        />
        <LayersControl.BaseLayer checked={props.layer === 'default' ? true : false} name="Map mặc định">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked={props.layer === 'black-white' ? true : false} name="Map đen trắng">
        <TileLayer
          url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked={props.layer === 'topo' ? true : false} name="Open Topo">
        <TileLayer
          url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked={props.layer === 'world-topo' ? true : false} name="World Topo">
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked={props.layer === 'world-street' ? true : false} name="World Street">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked={props.layer === 'world-imagery' ? true : false} name="World Imagery">
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      </LayersControl.BaseLayer>
    </LayersControl>
  )
};

export default LayerMap;

