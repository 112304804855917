import { Children, useState } from 'react';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomPagination from '../../../Components/Pagination/Pagination';
import FunctionButtons from "../../../Components/FunctionButtons/FunctionButtons";
import Typography from '@mui/material/Typography';
import CustomLoading from '../../../Components/Loading/CustomLoading';
import CustomChart from '../../../Components/Chart/Chart';
import { RoundValue } from '../../../../Utils/number';
import { Compute } from '../../../../Utils/interpolation';
import { BOXGROUP_ID } from '../../../../Utils/const';
import { borderRadius } from '@mui/system';
import { CalculateVolume } from './../../../Components/Interpolation/volume';

const Report = (props) => {
    const headers = ["Thời gian", "Tổng lượng mưa (mm)", "Mực nước trung bình (m)", "Thể tích hồ chứa (triệu m3)"]

    const { data, isLoading, group_id } = props;
    const [page, setPage] = useState(+0);
    const [rowsPerPage] = useState(+15);

    let datasets = [];
    let labels = [];
    let dataWAU = [];

    if (data.length !== +0) {
        // filter data by metric name RA
        labels = data.filter(d => d.metric === "RA").map(dt => dt.date);
        datasets = data.filter(d => d.metric === "RA").map(dt => dt?.total);
        dataWAU = data.filter(d => d.metric === "WAU").map(dt => {
            const RA = data.filter(d => d.metric === "RA").find(d => d.date === dt?.date)?.total;
            const avg = RoundValue(dt?.total / dt?.count);
            return {
                date: dt?.date,
                total: RA,
                avg,
                volumn: CalculateVolume(avg, group_id) || 0
            }
        })
    }

    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
    };

    return (
        <>
            <Grid container sx={{marginTop: { xs: '20px', md: '0px'}}}>
                <Grid item xs={12} lg={6} sx={{borderRadius: '5px', borderTop: '1.5px solid', borderBottom: '3px solid', borderRight: '1.5px solid', color: '#0000001f'}}>
                    <div className="reports-chart">
                        <Paper>
                            <Grid container sx={{ pt: "2%", pb: "2%" }}>
                                <Grid item xs={12} md={7} sx={{ pl: "4%" }}>
                                    <Typography
                                        color="#4285F4"
                                        sx={{
                                            fontWeight: 700
                                        }}
                                    >
                                        Biểu đồ thống kê lượng mưa
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid sx={{ pl: "3%", pr: "3%" }} className="reports-chart">
                                <CustomChart labels={labels} data={datasets} />
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6} sx={{borderRadius: '5px', borderTop: '1.5px solid', borderBottom: '3px solid', borderLeft: '1.5px solid', color: '#0000001f', height: {xs: '97vh', md: '92vh'}}}>
                    <div className="reports-table">
                        <Paper >
                            <Grid container sx={{ pt: "2%", pb: "2%" }}>
                                <Grid item xs={12} md={7} sx={{ pl: "4%" }}>
                                    <Typography
                                        color="#4285F4"
                                        sx={{
                                            fontWeight: 700
                                        }}
                                    >
                                        Bảng số liệu tổng hợp
                                    </Typography>
                                </Grid>
                                {/* <Grid item className="end func-button">
                                    <FunctionButtons url="" />
                                </Grid>  */}
                            </Grid>
                            <Grid container>
                                <TableContainer sx={{ pl: "2%", pr: "2%" }} className="table-reportdata">
                                    <Table stickyHeader>
                                        <TableHead className='text-bold upper'>
                                            <TableRow>
                                                {Children.toArray(headers.map(h => {
                                                    return (
                                                        <TableCell>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "bold",
                                                                    textTransform: 'uppercase',
                                                                    fontSize: 12,
                                                                    color: "#606060",
                                                                    textAlign: 'right'
                                                                }}
                                                            >
                                                                {h}
                                                            </Typography>
                                                        </TableCell>
                                                    )
                                                }))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!isLoading ?
                                                Children.toArray(data && dataWAU.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(d => {
                                                    return (<TableRow hover role="checkbox">
                                                        {Children.toArray(Object.values(d).filter(v => v !== "WAU").map((v, i) => {
                                                            return (
                                                                i === +0 ?
                                                                    <TableCell align="left" sx={{ width: "15%" }}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 14,
                                                                                fontWeight: 600,
                                                                                color: "#444444",
                                                                                textAlign: 'right'
                                                                            }}
                                                                        >
                                                                            {v}
                                                                        </Typography>
                                                                    </TableCell> :
                                                                    <TableCell align="left">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 14,
                                                                                fontWeight: 600,
                                                                                color: "#444444",
                                                                                textAlign: 'right'
                                                                            }}
                                                                        >
                                                                            {v}
                                                                        </Typography>
                                                                    </TableCell>
                                                            )
                                                        }))}
                                                    </TableRow>)
                                                })) : <CustomLoading numbers={4} />}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid container sx={{ pt: "2%", pl: "1%", pr: "2%", pb: "3%" }}>
                                {data && <CustomPagination
                                    data={dataWAU}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    parentCallBack={handleChangePage}
                                />}
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Report;