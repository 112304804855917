import { Http } from "../../../../Services/http";
import { BASE_CAMERA_URL, BOXGROUP_ID } from "../../../../Utils/const";

const API_ENDPOINT = {
  GET_BOX_GROUP: "zone/box_group/get?id=",
  GET_DATA_CAMERA: `${BASE_CAMERA_URL}/play?link=`
}

class DashboardSevice {
  constructor() {
    if (DashboardSevice._instance) {
      return DashboardSevice._instance;
    }
    DashboardSevice._instance = this;
  }

  getBoxGroup(id = BOXGROUP_ID) {
    return Http.get(API_ENDPOINT.GET_BOX_GROUP + id);
  }

  getDataCamera(camera_url) {
    return API_ENDPOINT.GET_DATA_CAMERA + camera_url;
  }
}

const dashboardSevice = new DashboardSevice();

export default dashboardSevice;
