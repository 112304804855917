import React from "react";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  TimeScale
} from 'chart.js';
import { format } from "date-fns";
import zoomPlugin from 'chartjs-plugin-zoom';
import { BOXGROUP_ID, ID_GROUPS } from "../../../../Utils/const";

const time_second = (d) => format(d, "yyyy-MM-dd HH:mm");

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  TimeScale,
  zoomPlugin
);
// LineChart
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    if(!this.props.data) return;
    this.myChart.data.labels = this.props.data.labels.map(l => time_second(l));
    this.myChart.data.datasets = this.props.data.datasets;
    this.myChart.update();
  }

  componentDidMount() {
    let min = 0;
    let max = 0;
    if(!this.props.min || this.props.min === 0) min = null;
    else min = this.props.min

    if(!this.props.max || this.props.max === 0) max = null;
    else max = this.props.max

    if(ID_GROUPS.DD === BOXGROUP_ID && this.props?.data?.metric === 'WA') {
      min = 0;
      max = 40;
    }

    const labels = this.props.data.labels.map(l => time_second(l))
    function adjustRadiusBasedOnData(ctx) {
      if(BOXGROUP_ID === 'IXC8QY8W') {
        const x = ctx.parsed.x;
        if(labels[x].slice(3) === '00:00') return 3
        return 0
      }
      if(BOXGROUP_ID === ID_GROUPS.DOT) {
        // const x = ctx.parsed.x;
        // if(labels[x].slice(14) === '00') return 2
        return 1
      }
      return 3
    }
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'line',
      options: {
        // animation,
        // interaction: {
        //   intersect: false
        // },
        // plugins: {
        //   legend: false
        // },
        maintainAspectRatio: false,
        type: 'line',
        responsive: true,
        plugins: {
          tooltip : {
            titleFont: {
              weight: 'bold',
              size: '13px'
            },
            bodyFont: {
              weight: 'bold'
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              font: {
                weight: 'bold',
                size: '11px',
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              },
              boxWidth: 30
            },
          },
          // zoom: {
          //   // limits: {
          //   //   x: {min: -200, max: 200, minRange: 50},
          //   //   y: {min: -200, max: 200, minRange: 50}
          //   // },
          //   pan: {
          //     enabled: true,
          //     mode: 'xy',
          //   },
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     pinch: {
          //       enabled: true
          //     },
          //     mode: 'xy',
          //     onZoomComplete({chart}) {
          //       chart.update('none');
          //     }
          //   }
          // }
        },
        scales: {
          y: {
            max: BOXGROUP_ID === ID_GROUPS.KG ? this.props.max : max,
            min,
            ticks: {
              font: {
                size: '11px !important',
                weight: null,
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              },
            }
          },
          x: {
            ticks: {
              callback: function (val) {
                const value = this.getLabelForValue(val)
                const hour = value.slice(11, 13)
                // if(BOXGROUP_ID === 'IXC8QY8W') {
                //   const minute = value.slice(3,5)
                //   if(minute === '00') return hour + 'h'
                // }else 
                return hour + 'h'
              },
              autoSkip: true,
              maxTicksLimit: 24,
              min: 0,
              font: {
                size: '11px !important',
                weight: null,
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              }
            }
          }
        },
        elements: {
          point: {
            radius: adjustRadiusBasedOnData
          }
        }
      },
      data: {
        labels: this.props.data.labels.map(l => time_second(l)),
        datasets: this.props.data.datasets
      }
    });
  }

  render() {
    return <div style={{ width: '100%', height: this.props.height || '30vh' }}>
      <canvas style={{ width: '100%', height: '100%' }} ref={this.canvasRef} id="myCanvas-Line"/>
    </div>
  }
}
export default LineChart